export interface SetBinaryQuestionsCountAction {
  type: "SET_BINARY_QUESTIONS_COUNT";
  count: number;
}

const initialState = null as number;

export function binaryQuestionsCountReducer(
  state = initialState,
  action: SetBinaryQuestionsCountAction
): number {
  if (action.type === "SET_BINARY_QUESTIONS_COUNT") {
    return action.count;
  }

  return state;
}
