import sortBy from "lodash.sortby";
import { sliceSelector, ParentState } from "../dependencies/sliceSelector";

export const mostRecentPredictionOfUserFunctionSelector = ({
  binaryQuestionId,
}: {
  binaryQuestionId: string;
}) => (parentState: ParentState) => ({ userId }: { userId: string }) => {
  const state = sliceSelector(parentState);

  const predictionsOfUser = state.binaryPredictions.filter(
    (bp) => bp.binaryQuestionId === binaryQuestionId && bp.creator.id === userId
  );

  const sortedPredictionsOfUser = sortBy(
    predictionsOfUser,
    (p) => -new Date(p.createdAt)
  );

  return sortedPredictionsOfUser[0]?.prediction;
};
