import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, ChangeEvent } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { updateUserInDB } from "../actions/updateUserInDB";
import { updateUser } from "../actions/updateUser";

import { RootState } from "../reducers/rootReducer";

export const ProfilePage = () => {
  const [hasMounted, setHasMounted] = useState(false);

  const [hasCopied, setHasCopied] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  const [displayNameInput, setDisplayNameInput] = useState(
    user && user.displayName
  );

  const {
    getAccessTokenSilently,
    isLoading: isLoadingAuth,
    user: authUser,
  } = useAuth0();

  const dispatch = useDispatch();

  const [displayNameChangeStatus, setDisplayNameChangeStatus] = useState(null);

  useEffect(() => {
    setDisplayNameInput(user && user.displayName);
  }, [user && user.sub]);

  if (!isLoadingAuth && !user && !authUser) {
    return (
      <div
        style={{
          minHeight: "100%",
          width: "1000px",
          margin: "0 auto 30px",
          padding: "40px",
          textAlign: "left",
        }}
      >
        <h4>Please login to view your profile.</h4>
      </div>
    );
  }

  const handleDisplayNameInputChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDisplayNameInput(e.target.value);
  };

  const setDisplayName = async () => {
    setDisplayNameChangeStatus("PENDING");

    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      updateUserInDB({
        accessToken,
        updates: { displayName: displayNameInput },
        onDone: () => {
          dispatch(updateUser("displayName", displayNameInput));
          setDisplayNameChangeStatus("SUCCESS");
          setTimeout(() => setDisplayNameChangeStatus(null), 2000);
        },
        onError: () => {
          setDisplayNameChangeStatus("ERROR");
          setTimeout(() => setDisplayNameChangeStatus(null), 2000);
        },
      })
    );
  };

  return (
    <div
      style={{
        minHeight: "100%",
        width: "1000px",
        margin: "0 auto 30px",
        padding: "40px",
        textAlign: "left",
      }}
    >
      {displayNameInput !== null ? (
        <>
          <div style={{ alignItems: "center" }}>
            <div
              style={{
                fontSize: "24px",
                marginRight: "10px",
                marginBottom: "15px",
              }}
            >
              Display Name
            </div>
            <InputGroup style={{ width: "300px" }}>
              <FormControl
                value={displayNameInput}
                onChange={handleDisplayNameInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && /\S/.test(displayNameInput)) {
                    setDisplayName();
                  }
                }}
              />
              <InputGroup.Append>
                <Button
                  disabled={
                    displayNameChangeStatus !== null ||
                    !/\S/.test(displayNameInput)
                  }
                  onClick={setDisplayName}
                  variant="primary"
                >
                  {displayNameChangeStatus === "PENDING"
                    ? "Saving..."
                    : displayNameChangeStatus === "ERROR"
                    ? "Error"
                    : displayNameChangeStatus === "SUCCESS"
                    ? "Success!"
                    : "Save"}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          {user && user.email && (
            <div style={{ alignItems: "center" }}>
              <div
                style={{
                  fontSize: "24px",
                  marginRight: "10px",
                  marginBottom: "15px",
                  marginTop: "50px",
                }}
              >
                Email
              </div>
              <span style={{ marginTop: "10px", fontSize: "16px" }}>
                {user.email}
              </span>
            </div>
          )}
          {user && user.metaculusUsername && (
            <div style={{ alignItems: "center" }}>
              <div
                style={{
                  fontSize: "24px",
                  marginRight: "10px",
                  marginBottom: "15px",
                  marginTop: "50px",
                }}
              >
                Metaculus Username
              </div>
              <span style={{ marginTop: "10px", fontSize: "16px" }}>
                {user.metaculusUsername}
              </span>
            </div>
          )}
          {user && user.apiKey && (
            <div style={{ marginTop: "50px" }}>
              <div style={{ fontSize: "24px", marginBottom: "15px" }}>
                Elicit API Key
              </div>

              <p style={{ color: "#666" }}>
                Provide this to other apps to let them make changes to your
                Elicit account on your behalf. Gives full access to your
                account.
              </p>
              <hr />
              <div
                style={{
                  alignItems: "center",
                  color: "#333",
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "20px",
                }}
              >
                <span style={{ marginRight: "10px", color: "gray" }}>
                  {user.apiKey}
                </span>
                <CopyToClipboard
                  text={user.apiKey}
                  onCopy={() => {
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 1000);
                  }}
                >
                  <Button disabled={hasCopied}>
                    {hasCopied ? "Copied!" : "Copy"}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          )}
        </>
      ) : (
        <h4>Loading your Elicit user information...</h4>
      )}
    </div>
  );
};
