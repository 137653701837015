import { useEffect } from "react";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

export function DefaultQueryParams() {
  const [limitQueryParam, setLimitQueryParam] = useQueryParam(
    "limit",
    NumberParam
  );
  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );
  const [sortByQueryParam, setSortByQueryParam] = useQueryParam(
    "binaryQuestions.sortBy",
    StringParam
  );

  const [predictorsQueryParam, setPredictorsQueryParam] = useQueryParam(
    "predictors",
    StringParam
  );

  useEffect(() => {
    if (limitQueryParam === undefined) {
      setLimitQueryParam(20);
    }

    if (offsetQueryParam === undefined) {
      setOffsetQueryParam(0);
    }

    if (sortByQueryParam === undefined) {
      setSortByQueryParam("popularity");
    }

    if (predictorsQueryParam === undefined) {
      setPredictorsQueryParam("community");
    }
  }, []);

  return null;
}
