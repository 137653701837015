interface HistogramBin {
  density: number;
  x: number;
}

interface OutOfBoundsInfo {
  lowOpen?: boolean;
  pBelow?: number;
  lowerBound?: number;
  highOpen?: boolean;
  pAbove?: number;
  upperBound?: number;
  scale?: any;
}

export interface Distribution {
  id: string;
  histogram: HistogramBin[];
  outOfBounds: OutOfBoundsInfo;
  name: string;
  displayName?: string;
}

export interface AddDistributionAction {
  type: "ADD_DISTRIBUTION";
  distribution: Distribution;
}

export interface RemoveDistributionAction {
  type: "REMOVE_DISTRIBUTION";
  distributionId: string;
}

export interface UpdateDistributionAction {
  type: "UPDATE_DISTRIBUTION";
  id: string;
  distribution: Distribution;
}

export interface UpdateDisplayNameAction {
  type: "UPDATE_DISPLAY_NAME";
  name: string;
  displayName: string;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type DistributionsAction =
  | AddDistributionAction
  | RemoveDistributionAction
  | UpdateDistributionAction
  | UpdateDisplayNameAction
  | ResetStateAction;

const initialState = [] as Distribution[];

export function distributionsReducer(
  state = initialState,
  action: DistributionsAction
): Distribution[] {
  if (action.type === "ADD_DISTRIBUTION") {
    return [...state, action.distribution];
  }

  if (action.type === "REMOVE_DISTRIBUTION") {
    return state.filter((d) => d.id !== action.distributionId);
  }

  if (action.type === "UPDATE_DISTRIBUTION") {
    return state.map((d) => {
      if (d.id !== action.id) {
        return d;
      }
      return {
        ...d,
        histogram: action.distribution.histogram,
        outOfBounds: action.distribution.outOfBounds,
      };
    });
  }

  if (action.type === "UPDATE_DISPLAY_NAME") {
    return state.map((d) => {
      if (d.name !== action.name) {
        return d;
      }

      return {
        ...d,
        displayName: action.displayName,
      };
    });
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
