export function getDistColorForPlot(name, i) {
  return getDistColor(name, i)?.plot;
}

export function getDistColorForLabel(name, i) {
  return getDistColor(name, i)?.label;
}

export const USER_PLOT_OPACITY = 0.5;
export const PLOT_OPACITY = 0.4;

function getDistColor(name, i) {
  switch (name) {
    case "user":
      return { plot: "#007bff", label: "#6ca0fb" };
    case "community":
      return { plot: "#bbbbbb", label: "#bbbbbb" };
    case "metaculusSubmission":
      return { plot: "#42ba0c", label: "#42ba0c" };
  }

  switch (i) {
    case 0:
      return { plot: "#BC7FBC", label: "#BC7FBC" };
    case 1:
      return { plot: "#FB8072", label: "#FB8072" };
    case 2:
      return { plot: "#B3DE69", label: "#B3DE69" };
    case 3:
      return { plot: "#FDB462", label: "#FDB462" };
    case 4:
      return { plot: "#8DD3C7", label: "#8DD3C7" };
    case 5:
      return { plot: "#8FBA85", label: "#83B079" };
    case 6:
      return { plot: "#FFED6F", label: "#DEC210" };
    case 7:
      return { plot: "#EDB2CF", label: "#E097BC" };
    case 8:
      return { plot: "#98A0E4", label: "#6A73C8" };
    default:
      return { plot: "rgb(187, 187, 187)", label: "rgb(102, 102, 102)" };
  }
}
