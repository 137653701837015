import { sliceSelector, ParentState } from "../dependencies/sliceSelector";

export const communityPredictionSelector = (binaryQuestionId: string) => (
  parentState: ParentState
) => {
  const state = sliceSelector(parentState);

  const predictions = state.binaryPredictions.filter(
    (p) => p.binaryQuestionId === binaryQuestionId
  );

  predictions.sort(
    (a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
  );

  const uniquePredictions = predictions.filter(
    (p, i, all) => !all.slice(0, i).some((p2) => p2.creator.id === p.creator.id)
  );

  return Number(
    (
      uniquePredictions.reduce((acc, val) => {
        return acc + val.prediction;
      }, 0) / uniquePredictions.length
    ).toPrecision(2)
  );
};
