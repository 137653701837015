import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchUserInfo } from "../actions/fetchUserInfo";
import { login } from "../actions/login";
import { updateUserInDB } from "../actions/updateUserInDB";
import { updateUserMetaculusApiKey } from "../actions/updateUserMetaculusApiKey";
import { updateUser } from "../actions/updateUser";

import { RootState } from "../reducers/rootReducer";

export const DetectAndLoadUserIntoRedux = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      const updateUserCredentials = () => {
        const apiDomain = localStorage.getItem("apiDomain");
        const metaculusUserId = localStorage.getItem("metaculusUserId");
        const metaculusUsername = localStorage.getItem("metaculusUsername");
        const metaculusUserKey = localStorage.getItem("metaculusUserKey");

        const updateCredentialsFn = async () => {
          const accessToken = await getAccessTokenSilently();
          dispatch(
            updateUserInDB({
              accessToken,
              updates: {
                metaculusAPIKeys: { [apiDomain]: metaculusUserKey },
                metaculusUsername,
                metaculusId: metaculusUserId,
              },
              onDone: () => {},
              onError: () => {},
            })
          );
        };

        updateCredentialsFn();
        dispatch(updateUser("metaculusUsername", metaculusUsername));
        dispatch(updateUserMetaculusApiKey(apiDomain, metaculusUserKey));
      };

      const fetchAndLoginFn = async () => {
        const accessToken = await getAccessTokenSilently();
        dispatch(
          fetchUserInfo({
            accessToken,
            onSuccess: (data) => {
              dispatch(
                login({
                  apiKey: data.apiKey,
                  displayName: data.displayName,
                  email: user.email,
                  familyName: user.family_name,
                  givenName: user.given_name,
                  metaculusAPIKeys: data.metaculusAPIKeys,
                  metaculusUsername: data.metaculusUsername,
                  sub: user.sub,
                })
              );
              // if user logged in after submitting to Metaculus while logged out, save their Metaculus credentials
              if (localStorage.getItem("metaculusUserId")) {
                // Don't replace a user that already exists
                if (
                  !data.metaculusId ||
                  data.metaculusId === localStorage.getItem("metaculusUserId")
                ) {
                  updateUserCredentials();
                }
                localStorage.removeItem("apiDomain");
                localStorage.removeItem("metaculusUserId");
                localStorage.removeItem("metaculusUsername");
                localStorage.removeItem("metaculusUserKey");
              }
            },
          })
        );
      };
      fetchAndLoginFn();
    }
  }, [user]);

  return null;
};
