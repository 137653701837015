import * as Sentry from "@sentry/browser";

import { setLoadingSearchPageQuestionsStatus } from "./setLoadingSearchPageQuestionsStatus";
import { replaceSearchPageQuestions } from "./replaceSearchPageQuestions";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function fetchSearchPageQuestions(): AppThunk {
  return async function (dispatch) {
    dispatch(setLoadingSearchPageQuestionsStatus("PENDING"));

    let data;
    try {
      const response = await makeRequestToBackend({
        path: "/search_page_questions",
        method: "GET",
      });

      data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setLoadingSearchPageQuestionsStatus("ERROR"));
      return;
    }

    dispatch(replaceSearchPageQuestions(data));

    dispatch(setLoadingSearchPageQuestionsStatus("SUCCESS"));
  };
}
