import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { EditIcon } from "../components/EditIcon";

import { updateQuestionTitle } from "../actions/updateQuestionTitle";

import { isInInlineMode } from "../helpers/isInInlineMode";

import { RootState } from "../reducers/rootReducer";

const Styles = styled.div`
  .heading-link {
    transition: color 0.2s;
    margin: 10px auto 60px auto;
    display: block;
  }

  .heading-link:hover {
    color: #444 !important;
    text-decoration: none;
  }
`;

const ShowOnHoverContainer = styled.div`
  .show-on-hover {
    opacity: 0;
  }
  :hover .show-on-hover {
    opacity: 1;
  }
`;

export function QuestionTitle() {
  const inlineMode = isInInlineMode(useLocation().search);

  const dispatch = useDispatch();

  const question = useSelector((state: RootState) => state.question);
  const questionTitle = question && question.title;
  const metadata = question && question.metadata;

  const [isEditingQuestionTextInput, setIsEditingQuestionTextInput] = useState(
    false
  );

  const [questionTitleInput, setQuestionTitleInput] = useState(questionTitle);

  const blurTimeoutRef = useRef(null);

  const hasExternalSource = question.metadata.source;

  if (hasExternalSource) {
    const sourceUrl = metadata && metadata.sourceUrl;
    return (
      <Styles>
        {questionTitle && (
          <h3>
            <a
              className="heading-link"
              href={sourceUrl}
              style={{
                color: "#5ca0fb",
                fontSize: inlineMode ? "20px" : "28px",
                marginBottom: inlineMode && "0.5rem",
              }}
              target="_blank"
            >
              {questionTitle}
            </a>
          </h3>
        )}
      </Styles>
    );
  }

  // If question doesn't have an external source, we know it's a custom question
  // that we can link to the question page for.
  const sourceUrl =
    metadata.questionId &&
    `${window.location.origin}/questions/${metadata.questionId}`;
  return isEditingQuestionTextInput ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        type="text"
        onChange={(e) => setQuestionTitleInput(e.target.value)}
        onBlur={() => {
          blurTimeoutRef.current = setTimeout(() => {
            dispatch(updateQuestionTitle(questionTitleInput));
            setIsEditingQuestionTextInput(false);
          }, 50);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(updateQuestionTitle(questionTitleInput));
            setIsEditingQuestionTextInput(false);
          }
        }}
        value={questionTitleInput}
        style={{ fontSize: "24px", padding: "1px", width: "800px" }}
      />
      <span
        onClick={() => {
          clearTimeout(blurTimeoutRef.current);
          setIsEditingQuestionTextInput(false);
          setQuestionTitleInput(questionTitle);
        }}
        style={{ cursor: "pointer", fontSize: "11px", paddingLeft: "5px" }}
      >
        cancel
      </span>
    </div>
  ) : (
    <ShowOnHoverContainer>
      <h3
        style={{
          display: "inline-block",
          fontSize: inlineMode ? "20px" : "28px",
        }}
      >
        <a href={sourceUrl} target="_blank">
          {questionTitle}
        </a>
        {!inlineMode && (
          <span className="show-on-hover">
            <EditIcon
              handleClickEditIcon={() => setIsEditingQuestionTextInput(true)}
            />
          </span>
        )}
      </h3>
    </ShowOnHoverContainer>
  );
}
