import {
  AddDistributionAction as DistributionsAddDistributionAction,
  Distribution,
} from "../reducers/distributionsReducer";
import { AddDistributionAction as ForecastsAddDistributionAction } from "../reducers/forecastsReducer";

type AddDistributionAction = DistributionsAddDistributionAction &
  ForecastsAddDistributionAction;

export const addDistribution = (
  distribution: Distribution
): AddDistributionAction => ({
  type: "ADD_DISTRIBUTION",
  distribution,
  forecastId: null,
});
