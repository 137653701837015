import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";

import { MetaculusPermissionDeniedPage } from "./MetaculusPermissionDeniedPage";
import { SubmissionInProgressPage } from "./SubmissionInProgressPage";

import { addSnapshotRecord } from "../../actions/addSnapshotRecord";
import { createSnapshot } from "../../actions/createSnapshot";
import { submitForecast } from "../../actions/submitForecast";
import { updateUserMetaculusApiKey } from "../../actions/updateUserMetaculusApiKey";
import { updateUser } from "../../actions/updateUser";
import { updateUserInDB } from "../../actions/updateUserInDB";

import { RootState } from "../../reducers/rootReducer";

import { displayedBeliefsSelector } from "../../selectors/displayedBeliefsSelector";

import { METACULUS_DELEGATE_ID } from "../../constants";

export function AfterClosenessPage({ submitted, setSubmitted }) {
  const dispatch = useDispatch();

  const { user, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0();

  const question = useSelector((state: RootState) => state.question);
  const metadata = question && question.metadata;
  const sourceUrl = metadata && metadata.sourceUrl;
  const apiDomain = sourceUrl.split(".")[0].split("//")[1];

  const askForPermissionOnMetaculus = async () => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;
    dispatch(
      createSnapshot({
        accessToken,
        onDone: (key) => {
          const fullUrl = "https://".concat(
            apiDomain,
            ".metaculus.com/accounts/delegate-api-access/?delegate-id=",
            METACULUS_DELEGATE_ID,
            "&redirect=",
            window.location.origin,
            "/builder/",
            key
          );
          window.location.replace(fullUrl);
        },
      })
    );
  };

  const distribution = useSelector((state: RootState) =>
    state.distributions.find((d) => d.name === "user")
  );

  const beliefs = useSelector(
    displayedBeliefsSelector({
      interval: true,
      nonInterval: true,
    })
  );

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const submitToMetaculus = async (userApiKey, username) => {
    submitForecast({
      beliefs,
      metadata,
      distribution,
      username,
      userApiKey,
      onPending: () => {
        setSubmissionStatus("PENDING");
      },
      onSuccess: async () => {
        setSubmissionStatus("SUCCESS");
        setSubmitted(true);
        const accessToken = user ? await getAccessTokenSilently() : undefined;
        dispatch(
          createSnapshot({
            accessToken,
            onDone: (key) => {
              const timestamp = Date.now();
              dispatch(addSnapshotRecord(key, timestamp));
              const url = `${window.location.origin}/builder/${key}`;
              window.history.pushState({}, "", url);
            },
          })
        );
      },
      onError: (e) => {
        if (e?.status === 403) {
          // credentials didn't work; the user has revoked access
          setSubmissionStatus("CREDENTIALS_ERROR");
        } else {
          setSubmissionStatus("ERROR");
        }
      },
    });
  };

  const userState = useSelector((state: RootState) => state.user);
  const userApiKey =
    userState &&
    userState.metaculusAPIKeys &&
    userState.metaculusAPIKeys[apiDomain];

  const [
    permissionDeniedOnMetaculus,
    setPermissionDeniedOnMetaculus,
  ] = useState(false);

  const query = new URLSearchParams(createBrowserHistory().location.search);

  useEffect(() => {
    // submission is pending or completed without a credential error
    // or waiting on auth to load
    if (
      submissionStatus === "PENDING" ||
      submissionStatus === "ERROR" ||
      submissionStatus === "SUCCESS" ||
      isAuthLoading ||
      permissionDeniedOnMetaculus
    ) {
      return;
    }
    // coming from Metaculus, accepted
    else if (query.get("success") === "1") {
      // make sure there user has been loaded into state
      if (user && !userState) {
        return;
      }

      const metaculusUserId = query.get("userid");
      const metaculusUsername = query.get("username");
      const metaculusUserKey = query.get("userkey");

      window.history.pushState({}, "", window.location.pathname);

      submitToMetaculus(metaculusUserKey, metaculusUsername);

      // save credentials; if logged in, push to DB and add to state
      if (user) {
        const updateCredentialsFn = async () => {
          const accessToken = await getAccessTokenSilently();
          dispatch(
            updateUserInDB({
              accessToken,
              updates: {
                metaculusAPIKeys: { [apiDomain]: metaculusUserKey },
                metaculusUsername,
                metaculusId: metaculusUserId,
              },
              onDone: () => {},
              onError: () => {},
            })
          );
          dispatch(updateUser("metaculusUsername", metaculusUsername));
          dispatch(updateUserMetaculusApiKey(apiDomain, metaculusUserKey));
        };

        updateCredentialsFn();
      }
      // else, add to local storage to be retrieved if user logs in
      else {
        localStorage.setItem("apiDomain", apiDomain);
        localStorage.setItem("metaculusUserId", metaculusUserId);
        localStorage.setItem("metaculusUsername", metaculusUsername);
        localStorage.setItem("metaculusUserKey", metaculusUserKey);
      }
    }
    // coming from Metaculus, rejected
    else if (query.get("success") === "0") {
      window.history.pushState({}, "", window.location.pathname);
      setPermissionDeniedOnMetaculus(true);
    }
    // has permission already
    else if (userApiKey && submissionStatus !== "CREDENTIALS_ERROR") {
      submitToMetaculus(userApiKey, userState.metaculusUsername);
    }
    // redirect to Metaculus for credentials
    else {
      askForPermissionOnMetaculus();
    }
  });

  return (
    <>
      {permissionDeniedOnMetaculus ? (
        <MetaculusPermissionDeniedPage />
      ) : (
        <SubmissionInProgressPage submissionStatus={submissionStatus} />
      )}
    </>
  );
}
