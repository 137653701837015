import React from "react";
import { useSelector } from "react-redux";
import { Belief } from "../../reducers/beliefsReducer";
import { formatPoint, formatPercent } from "./utils";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";
import { RootState } from "../../reducers/rootReducer";
import { TemplatedBeliefDisplay } from "./TemplatedBeliefDisplay";
import { getForecastNameForImpliedBeliefsSelector } from "../../selectors/getForecastNameForImpliedBeliefsSelector";

export const GenericImpliedBeliefDisplay = ({ belief }: { belief: Belief }) => {
  const isDateQuestion = useSelector(isDateQuestionSelector);

  const selectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );

  const name = useSelector(
    getForecastNameForImpliedBeliefsSelector(selectedForecastId)
  );

  // allow templating by forecast name
  const values = {
    ...belief.values,
    name,
  };

  return (
    <TemplatedBeliefDisplay
      label={belief.label}
      values={values}
      isDateQuestion={isDateQuestion}
      beliefId={belief.id}
      subLabels={belief.subLabels}
    />
  );
};
