import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { createBrowserHistory } from "history";

import { useQueryParam, StringParam } from "use-query-params";

import { addBinaryPrediction } from "../actions/addBinaryPrediction";
import { addBinaryQuestion } from "../actions/addBinaryQuestion";
import { createBinaryQuestion } from "../actions/createBinaryQuestion";

import { formatDateString } from "../dependencies/formatDateString";
import { localInputStringToDate } from "../dependencies/stringToDate";

const Styles = styled.div`
  margin-bottom: 20px;

  .MuiInputBase-input {
    font-size: 16px;
  }

  input {
    border: none;
    border-bottom: 1px dashed #999;
  }

  input:focus {
    border: auto;
  }
`;

export function NewBinaryQuestion() {
  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const [createQuestionStatus, setCreateQuestionStatus] = useState(null);

  const resolvesByInputEl = useRef(null);
  const titleInputEl = useRef(null);

  const [fillTitleQueryParam, setFillTitleQueryParam] = useQueryParam(
    "fillTitle",
    StringParam
  );
  const [titleInput, setTitleInput] = useState("");
  useEffect(() => {
    if (fillTitleQueryParam) {
      setTitleInput(fillTitleQueryParam);
      setFillTitleQueryParam(undefined);
    }
  }, [fillTitleQueryParam]);

  const [resolvesByInput, setResolvesByInput] = useState(
    moment(localInputStringToDate("in 1 year")).format("YYYY-MM-DD")
  );
  const [isPrivateQuestion, setIsPrivateQuestion] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const formatResolvesBy = () =>
    setResolvesByInput(formatDateString(resolvesByInput));

  const [predictionInput, setPredictionInput] = useState("");

  useEffect(() => {
    if (isExpanded) {
      resolvesByInputEl.current.focus();
      resolvesByInputEl.current.setSelectionRange(
        0,
        resolvesByInputEl.current.value.length
      );
    }
  }, [isExpanded]);

  const [focusOnTitleField, setFocusOnTitleField] = useState(false);

  useEffect(() => {
    if (focusOnTitleField) {
      titleInputEl.current.focus();
      setFocusOnTitleField(false);
    }
  }, [focusOnTitleField]);

  const handleCreateQuestion = async () => {
    setCreateQuestionStatus("PENDING");

    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      createBinaryQuestion({
        accessToken,
        fields: {
          title: titleInput,
          resolvesBy: Number(
            moment(localInputStringToDate(resolvesByInput)).format("x")
          ),
          privacyStatus: isPrivateQuestion ? "PRIVATE" : "PUBLIC",
          notes: "",
          prediction:
            predictionInput !== "" && !isNaN(Number(predictionInput))
              ? {
                  prediction: Number(predictionInput),
                }
              : undefined,
        },
        onSuccess: (binaryQuestion) => {
          setCreateQuestionStatus("SUCCESS");
          setTimeout(() => setCreateQuestionStatus(null), 100);
          dispatch(
            addBinaryQuestion({
              ...binaryQuestion,
              predictions: undefined,
            })
          );

          binaryQuestion.binaryPredictions.forEach((prediction) =>
            dispatch(addBinaryPrediction(prediction))
          );

          setIsExpanded(false);
          setTitleInput("");
          setResolvesByInput(formatDateString("in 1 year"));
          setIsPrivateQuestion(false);
          setPredictionInput("");
          setFocusOnTitleField(true);
        },
      })
    );
  };

  return (
    <Styles style={{ position: "relative" }}>
      <TextField
        inputRef={titleInputEl}
        onChange={(e) => setTitleInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setIsExpanded(true);
          }
        }}
        placeholder="Will I finish my project on time?"
        helperText="Enter a binary question you want to forecast"
        value={titleInput}
        fullWidth
      />
      {isExpanded && (
        <div style={{ marginTop: "30px" }}>
          <TextField
            inputRef={resolvesByInputEl}
            onBlur={formatResolvesBy}
            onChange={(e) => setResolvesByInput(e.target.value)}
            label="Resolves By"
            value={resolvesByInput}
          />
          <div style={{ marginTop: "30px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <strong style={{ display: "block" }}>Your prediction</strong>
                <TextField
                  id="standard-basic"
                  value={predictionInput}
                  onChange={(e) => setPredictionInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleCreateQuestion();
                    }
                  }}
                  style={{ position: "relative", top: "-3px", width: "50px" }}
                  inputProps={{ style: { textAlign: "right" } }}
                />
                %
              </div>
              <Button
                disabled={
                  createQuestionStatus === "PENDING" ||
                  createQuestionStatus === "SUCCESS"
                }
                color="primary"
                style={{ margin: "20px" }}
                onClick={handleCreateQuestion}
              >
                Create
              </Button>
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPrivateQuestion}
                color="primary"
                onChange={(e) => setIsPrivateQuestion(e.target.checked)}
              />
            }
            label="Private"
            style={{ position: "absolute", top: "90px", left: "180px" }}
          />
        </div>
      )}
    </Styles>
  );
}
