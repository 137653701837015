import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bootstrap";

import { LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP } from "../constants";

export const LogoutBtn = () => {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() => {
        localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP);
        logout({
          returnTo: window.location.origin,
        });
      }}
      variant="outline-dark"
      size="sm"
    >
      Log Out
    </Button>
  );
};
