import { v4 as uuidv4 } from "uuid";

import { Belief } from "../reducers/beliefsReducer";
import { Distribution } from "../reducers/distributionsReducer";
import { AppThunk } from "../reducers/rootReducer";

import { addBeliefs } from "./addBeliefs";
import { addDistribution } from "./addDistribution";
import { addForecast } from "./addForecast";

export function createForecast({
  id,
  name,
  type,
  beliefs,
  distribution,
}: {
  id: string;
  name: string;
  type: string;
  beliefs: Belief[];
  distribution: Distribution;
}): AppThunk {
  return async function (dispatch) {
    // process beliefs
    let intervalBeliefIds = [] as string[];
    let nonIntervalBeliefIds = [] as string[];
    if (beliefs) {
      const processedBeliefs = beliefs.map((b) => {
        const id = b.id || uuidv4();
        if (b.type === "interval") {
          intervalBeliefIds.push(id);
          return {
            type: "interval",
            isActive: b.isActive,
            id: id,
            label: b.label,
            notes: b.notes || null,
            values: {
              min: b.values.min ? Number(b.values.min.toFixed(2)) : null,
              max: b.values.max ? Number(b.values.max.toFixed(2)) : null,
              p: b.values.p ? Number(b.values.p.toFixed(2)) : null,
            },
          };
        } else {
          nonIntervalBeliefIds.push(id);
          return { ...b, id };
        }
      });

      // add beliefs
      dispatch(addBeliefs(processedBeliefs));
    }

    // add distribution
    if (distribution) {
      dispatch(addDistribution(distribution));
    }

    // add forecast
    dispatch(
      addForecast({
        id: id,
        name: name,
        type: type,
        distributionId: distribution && distribution.id,
        intervalBeliefIds,
        nonIntervalBeliefIds,
        impliedBeliefIds: [],
      })
    );
  };
}
