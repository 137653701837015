type BeliefId = string;

export type BeliefsTable = {
  hoveringOverBelief: BeliefId | null;
  focusLowerOutOfBoundsBelief: {} | null;
  focusUpperOutOfBoundsBelief: {} | null;
};

export interface AddHoverForBeliefAction {
  type: "ADD_HOVER_FOR_BELIEF";
  beliefId: BeliefId;
}

export interface RemoveHoverForBeliefAction {
  type: "REMOVE_HOVER_FOR_BELIEF";
  beliefId: BeliefId;
}

interface DeleteBeliefAction {
  type: "DELETE_BELIEF";
  beliefId: BeliefId;
}

interface FocusOnOutOfBoundsIntervalBeliefAction {
  type: "FOCUS_OUT_OF_BOUNDS_INTERVAL_BELIEF";
  boundsType: "OVER" | "UNDER";
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type BeliefsTableAction =
  | AddHoverForBeliefAction
  | RemoveHoverForBeliefAction
  | DeleteBeliefAction
  | FocusOnOutOfBoundsIntervalBeliefAction
  | ResetStateAction;

const initialState = {
  hoveringOverBelief: null,
  focusLowerOutOfBoundsBelief: null,
  focusUpperOutOfBoundsBelief: null,
} as BeliefsTable;

export function beliefsTableReducer(
  state = initialState,
  action: BeliefsTableAction
) {
  if (action.type === "ADD_HOVER_FOR_BELIEF") {
    return { ...state, hoveringOverBelief: action.beliefId };
  }

  if (action.type === "REMOVE_HOVER_FOR_BELIEF") {
    if (state.hoveringOverBelief === action.beliefId) {
      return { ...state, hoveringOverBelief: null };
    }

    return state;
  }

  if (action.type === "DELETE_BELIEF") {
    if (action.beliefId === state.hoveringOverBelief) {
      return { ...state, hoveringOverBelief: null };
    }

    return state;
  }

  // ZJM: We want to know when we should focus on the prob field of
  // the lower/upper bounds beliefs. React components detect
  // change in the Redux state, and change can be indicated
  // by assigning {} to something because in JS {} !== {}.
  // So a useEffect hook listening for change will detect this.
  if (action.type === "FOCUS_OUT_OF_BOUNDS_INTERVAL_BELIEF") {
    if (action.boundsType === "UNDER") {
      return { ...state, focusLowerOutOfBoundsBelief: {} };
    }

    if (action.boundsType === "OVER") {
      return { ...state, focusUpperOutOfBoundsBelief: {} };
    }

    return { ...state };
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
