import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function updateBinaryPrediction({
  accessToken,
  binaryPredictionId,
  fields,
  onSuccess,
}: {
  accessToken: string;
  binaryPredictionId: string;
  fields: any;
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function (dispatch) {
    let data;
    try {
      const response = await makeRequestToBackend({
        path: `/binary-predictions/${binaryPredictionId}`,
        method: "PATCH",
        body: fields,
        accessToken,
      });

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }

    onSuccess(data);
  };
}
