import React from "react";

export const EditIcon = ({ handleClickEditIcon }) => (
  <span
    onClick={handleClickEditIcon}
    style={{
      backgroundImage: `linear-gradient(transparent,transparent),url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Ctitle%3Eedit%3C/title%3E%3Cpath d=%22M16.77 8l1.94-2a1 1 0 000-1.41l-3.34-3.3a1 1 0 00-1.41 0L12 3.23zM1 14.25V19h4.75l9.96-9.96-4.75-4.75z%22/%3E%3C/svg%3E")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      display: "inline-block",
      cursor: "pointer",
      height: "10px",
      marginLeft: "5px",
      width: "10px",
    }}
  />
);
