import { Modal, Spinner } from "react-bootstrap";
import React from "react";

export function MetaculusPermissionDeniedPage() {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Metaculus Permission Denied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sorry, we can't submit to Metaculus for you unless you grant access to
        your account.
      </Modal.Body>
    </>
  );
}
