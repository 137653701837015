import { DisplayUpdatedResolutionAction } from "../reducers/binaryQuestionsReducer";

export function displayUpdatedResolution({
  binaryQuestionId,
  resolution,
}: {
  binaryQuestionId: string;
  resolution: "YES" | "NO";
}): DisplayUpdatedResolutionAction {
  return {
    type: "DISPLAY_UPDATED_RESOLUTION",
    binaryQuestionId,
    resolution,
  };
}
