import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

const Styles = styled.div`
  .MuiInputBase-input {
    font-size: 16px;
  }
`;

export function Searching() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [searchQueryParam, setSearchQueryParam] = useQueryParam(
    "binaryQuestions.search",
    StringParam
  );

  const [searchQueryInput, setSearchQueryInput] = useState("");

  useEffect(() => {
    if (searchQueryInput !== (searchQueryParam || ""))
      setSearchQueryInput(searchQueryParam || "");
  }, [searchQueryParam]);

  const dispatch = useDispatch();

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const fetchBinaryQuestions = async function () {
    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    } else if (!user) {
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken: undefined,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    }
  };

  const handleSearchQueryInputChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSearchQueryInput(e.target.value);
  };

  const setSearchQuery = async () => {
    setSearchQueryParam(searchQueryInput);
    fetchBinaryQuestions();
  };

  const inputEl = useRef(null);

  return (
    <Styles style={{ width: "200px" }}>
      <TextField
        inputRef={inputEl}
        value={searchQueryInput}
        onBlur={() => {
          setSearchQuery();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchQuery();
            inputEl.current.blur();
          }
        }}
        onChange={handleSearchQueryInputChange}
        placeholder="Search title or tag"
        fullWidth
      />
    </Styles>
  );
}
