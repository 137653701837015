import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  binaryPredictionsReducer,
  BinaryPrediction,
} from "./binaryPredictionsReducer";

import { binaryQuestionsCountReducer } from "./binaryQuestionsCountReducer";

import {
  binaryQuestionsReducer,
  BinaryQuestion,
} from "./binaryQuestionsReducer";

export interface RootState {
  binaryPredictions: BinaryPrediction[];
  binaryQuestionsCount: number;
  binaryQuestions: BinaryQuestion[];
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default combineReducers<RootState>({
  binaryPredictions: binaryPredictionsReducer,
  binaryQuestionsCount: binaryQuestionsCountReducer,
  binaryQuestions: binaryQuestionsReducer,
});
