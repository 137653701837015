import produce from "immer";

export interface FitDescription {
  loss: number;
  pInInterval?: number;
  pBelow?: number;
  pAbove?: number;
}

interface BeliefFit {
  [distributionId: string]: FitDescription;
}

export interface BeliefFits {
  [beliefId: string]: BeliefFit;
}

export interface AddBeliefFitsAction {
  type: "ADD_BELIEF_FITS";
  distributionId: string;
  beliefFits: { [beliefId: string]: FitDescription };
}

interface ResetAction {
  type: "RESET_STATE";
}

type BeliefFitsAction = AddBeliefFitsAction | ResetAction;

const initialState = {} as BeliefFits;

export function beliefFitsReducer(
  state = initialState,
  action: BeliefFitsAction
): BeliefFits {
  if (action.type === "ADD_BELIEF_FITS") {
    const newState = produce(state, (draftState) => {
      for (const [beliefId, fitDescription] of Object.entries(
        action.beliefFits
      )) {
        if (beliefId in draftState) {
          draftState[beliefId][action.distributionId] = fitDescription;
        } else {
          draftState[beliefId] = {};
          draftState[beliefId][action.distributionId] = fitDescription;
        }
      }
    });

    return newState;
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
