import sample from "lodash.sample";

export const getSearchFormPlaceholder = () => {
  const placeholderQuestions = [
    "When will AGI be developed?",
    "When will the first human reach 200 years of age?",
    "When will AI write a NYT best-selling book?",
    "When will humans live on Mars?",
    "When will a COVID vaccine become available?",
    "When will sales of VR devices surpass smartphones?",
    "How many people in the US will be vegan in 2030?",
    "When will the Turing Test be passed?",
    "How many Twitter followers will I have in 2025?",
    "When will we solve aging?",
    "What will my company’s revenue be in 2025?",
    "What percentage of cars will be self-driving in 2030?",
    "What will world GDP be in 2050?",
    "When will we solve climate change?",
    "How many gene-edited babies will be born in 2030?",
    "How many AI safety researchers will there be in 2030?",
    "When will the higher education bubble burst?",
    "When will we have a world government?",
    "What will my life satisfaction rating be in 10 years?",
  ];

  return sample(placeholderQuestions);
};
