// The other plot-showing state is handled locally in DistPlotContainer.tsx
// We handle the submission-showing state here so that, in FetchDistributionsToDisplayOnChange,
// we can ask the backend for the submission distribution only when needed.

// This is important because it takes the backend a long time to compute the submission distribution,
// so we only want to fetch it when we have to.

// showCount is used to fetch the distribution only when we go from not showing it to showing it,
// not the reverse. Ideally, we'd ask the backend for the metaculus submission only when we're showing it
// and it has changed; see #100

export interface ShowSubmissionState {
  show: boolean;
  showCount: number;
}

export interface SetShowSubmissionAction {
  type: "SET_SHOW_SUBMISSION";
  show: boolean;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type ShowSubmissionAction = SetShowSubmissionAction | ResetStateAction;

// note: this initial state is duplicated at ../../node-backend/src/app/snapshots-router/create-snapshot-from-distribution.ts
export const initialState = { show: false, showCount: 0 };

export function showSubmissionReducer(
  state: ShowSubmissionState = initialState,
  action: ShowSubmissionAction
) {
  if (action.type === "SET_SHOW_SUBMISSION") {
    return {
      show: action.show,
      showCount: action.show ? state.showCount + 1 : state.showCount,
    };
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
