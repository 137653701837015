import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DistPlotContainer } from "./distPlot/DistPlotContainer";
import { DistPlot } from "./distPlot/DistPlot";
import { FetchDistributionsToDisplayOnChange } from "./FetchDistributionsToDisplayOnChange";
import { ForecastSelectorDropdown } from "./ForecastSelectorDropdown";
import { QuestionTitle } from "./QuestionTitle";
import { ImpliedBeliefs } from "./impliedBeliefs/ImpliedBeliefs";

import { GlobalForecastNotes } from "./GlobalForecastNotes";
import { NewBeliefTable } from "./beliefTable/NewBeliefTable";
import { OptionsColumns } from "./OptionsColumns";

import { loadCustomQuestion } from "../actions/loadCustomQuestion";
import { createQuestion } from "../actions/createQuestion";
import { fetchQuestion } from "../actions/fetchQuestion";
import { setTabScreen } from "../actions/setTabScreen";
import { updateIsOpen } from "../actions/updateIsOpen";
import { selectForecast } from "../actions/selectForecast";

import { isInInlineMode } from "../helpers/isInInlineMode";

import { RootState } from "../reducers/rootReducer";
import { TabScreen } from "../reducers/statusReducer";

import { isSelectedForecastDistributionOnlySelector } from "../selectors/isSelectedForecastDistributionOnlySelector";
import { isSelectedForecastEditableSelector } from "../selectors/isSelectedForecastEditableSelector";
import { getSelectableForecastsSelector } from "../selectors/getSelectableForecastsSelector";
import { selectedForecastSelector } from "../selectors/selectedForecastSelector";

export const DistBuilderPage = withRouter(({ history }) => {
  const { id } = useParams();

  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const question = useSelector((state: RootState) => state.question);

  const isSelectedForecastDistributionOnly = useSelector(
    isSelectedForecastDistributionOnlySelector
  );

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const enabledTabs: TabScreen[] = (function() {
    if (!isSelectedForecastDistributionOnly) {
      return ["BUILD", "INTERPRET", "NOTES"] as TabScreen[];
    }
    // If we get here, the forecast is of type fixed_distribution
    return ["NOTES", "INTERPRET"] as TabScreen[];
  })();

  const tabScreenKey = useSelector(
    (state: RootState) => state.status.tabScreen
  );

  // The default tab is shown when a user switches between forecasts and the tab they were
  // on doesn't exist in the new forecast.
  const defaultTab = enabledTabs && enabledTabs[0];

  useEffect(() => {
    if (!enabledTabs.includes(tabScreenKey) && defaultTab) {
      dispatch(setTabScreen(defaultTab));
    }
  }, [enabledTabs]);

  const isLoadingBeliefs = useSelector(
    (state: RootState) => state.status.loadingBeliefsStatus === "PENDING"
  );

  const selectableForecasts = useSelector(getSelectableForecastsSelector);
  const selectedForecast = useSelector(selectedForecastSelector);
  const shouldShowForecastSelector = useSelector((state: RootState) => {
    return selectableForecasts.length > 1;
  });

  const didErrorLoadingBeliefs = useSelector(
    (state: RootState) => state.status.loadingBeliefsStatus === "ERROR"
  );

  const shouldUpdateIsOpen = useSelector(
    (state: RootState) => state.status.hasUpdatedIsOpen === false
  );

  const hasLoadingBeliefsMsgToDisplay =
    isLoadingBeliefs || didErrorLoadingBeliefs;

  const query = new URLSearchParams(useLocation().search);

  const inlineMode = isInInlineMode(useLocation().search);

  useEffect(() => {
    loadQuestion();

    async function loadQuestion() {
      if (id && !question) {
        const accessToken = user ? await getAccessTokenSilently() : undefined;

        dispatch(
          fetchQuestion({
            accessToken,
            questionId: id,
            onSuccess: (question) => {
              const {
                questionName,
                min,
                max,
                lowOpen,
                highOpen,
                isDateQuestion,
                isLogQuestion,
              } = question;
              dispatch(
                loadCustomQuestion({
                  title: questionName,
                  low: Number(min),
                  high: Number(max),
                  scale: isDateQuestion
                    ? "TimeScale"
                    : isLogQuestion
                    ? "LogScale"
                    : null,
                  lowOpen,
                  highOpen,
                  questionId: id,
                })
              );
            },
          })
        );
      } else if (query.get("q_id") && !question) {
        // If no question already exists in state
        // Create one
        dispatch(
          createQuestion({
            newQuestionInput: query.get("q_id"),
          })
        );
      } else if (!question) {
        // If no q_id
        // and no already loaded question (e.g., snapshot)
        // and no indication of custom question
        // redirect user back to search page
        if (!query.get("low") || !query.get("high")) {
          history.push({
            pathname: "/",
          });
        }
        const title = query.get("title");
        const low = parseFloat(query.get("low"));
        const lowOpen = JSON.parse(query.get("lowOpen"));
        const high = parseFloat(query.get("high"));
        const highOpen = JSON.parse(query.get("highOpen"));

        const scale = query.get("scale");

        dispatch(
          loadCustomQuestion({
            title,
            low,
            high,
            scale,
            lowOpen,
            highOpen,
            questionId: null,
          })
        );
      } else if (shouldUpdateIsOpen) {
        dispatch(updateIsOpen(question.metadata.questionId));
      }
    }
  }, [query.get("q_id"), question]);

  const bothBoundsClosed = useSelector(
    (state: RootState) =>
      state.question?.metadata.isLowerBoundClosed &&
      state.question?.metadata.isUpperBoundClosed
  );

  if (!question) {
    return (
      <div
        style={{
          fontSize: "24px",
          marginTop: "30px",
          textAlign: "center",
          minHeight: "100%",
        }}
      >
        Loading{" "}
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  }

  const width = inlineMode
    ? bothBoundsClosed
      ? "1000px"
      : "1300px"
    : "1400px";

  const inlineURL = new URL(window.location.href);
  inlineURL.searchParams.set("inline_mode", "true");
  inlineURL.searchParams.set("inline_mode_version", "1");

  return (
    <div
      style={{
        minHeight: "100%",
        width,
        margin: inlineMode ? "0 auto" : "0 auto 30px",
        padding: inlineMode ? "0px" : "40px",
        textAlign: "center",
      }}
    >
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content={`https://image.thum.io/get/viewportWidth/1400/png/width/1400/crop/640/${inlineURL}`}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1400" />
        <meta property="og:image:height" content="640" />
      </Helmet>
      <FetchDistributionsToDisplayOnChange />
      <QuestionTitle />
      <DistPlotContainer PlotComponent={DistPlot} />

      {hasLoadingBeliefsMsgToDisplay ? (
        <div
          style={{
            color: didErrorLoadingBeliefs ? "#f66" : "#666",
            fontSize: "28px",
            textAlign: "center",
          }}
        >
          {isLoadingBeliefs && (
            <>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          {didErrorLoadingBeliefs
            ? "Error fetching beliefs"
            : "Loading beliefs.."}
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <div style={{ position: "relative", width: "840px" }}>
              {shouldShowForecastSelector && (
                <>
                  <div
                    style={{
                      textAlign: "right",
                      position: "absolute",
                      top: "40px",
                      left: "4px",
                    }}
                  >
                    <ForecastSelectorDropdown
                      selectedForecast={selectedForecast}
                      selectableForecasts={selectableForecasts}
                      setSelectedForecast={(f) =>
                        dispatch(selectForecast(f.id))
                      }
                    />
                  </div>
                </>
              )}
              <Tabs
                activeKey={tabScreenKey}
                className={
                  shouldShowForecastSelector ? "justify-content-center" : ""
                }
                onSelect={(key) => dispatch(setTabScreen(key))}
                id="controlled-tab-example"
                style={{
                  margin: "35px auto 0 auto",
                  width: "840px",
                }}
              >
                {enabledTabs.includes("BUILD") && (
                  <Tab
                    eventKey="BUILD"
                    title={
                      <div style={{ marginBottom: 0, fontSize: "16px" }}>
                        Build
                      </div>
                    }
                  >
                    <div style={{ marginTop: "30px" }}>
                      <NewBeliefTable />
                    </div>
                  </Tab>
                )}

                {enabledTabs.includes("INTERPRET") && (
                  <Tab
                    eventKey="INTERPRET"
                    title={
                      <div style={{ marginBottom: 0, fontSize: "16px" }}>
                        Interpret
                      </div>
                    }
                  >
                    <div style={{ marginTop: "30px" }}>
                      <ImpliedBeliefs />
                    </div>
                  </Tab>
                )}

                {enabledTabs.includes("NOTES") && (
                  <Tab
                    eventKey="NOTES"
                    title={
                      <div style={{ marginBottom: 0, fontSize: "16px" }}>
                        Notes
                      </div>
                    }
                  >
                    <div style={{ marginTop: "15px" }}>
                      <GlobalForecastNotes />
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
          {!inlineMode && <OptionsColumns />}
        </>
      )}
    </div>
  );
});
