import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../../constants";

import { isInInlineMode } from "../../helpers/isInInlineMode";

import { QuestionState } from "../../reducers/questionReducer";
import { RootState } from "../../reducers/rootReducer";

import { areAllIntervalBeliefsOfSelectedForecastValidSelector } from "../../selectors/areAllIntervalBeliefsOfSelectedForecastValidSelector";
import { isBeliefOutOfBoundsSelector } from "../../selectors/isBeliefOutOfBoundsSelector";
import { isBeliefValidSelector } from "../../selectors/isBeliefValidSelector";

import { OutOfBoundsDisplay } from "./OutOfBoundsDisplay";
import { Legend } from "../Legend";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";

export function DistPlotContainer(props) {
  const inlineMode = isInInlineMode(useLocation().search);

  const distributions = useSelector((state: RootState) => state.distributions);

  const question: QuestionState = useSelector(
    (state: RootState) => state.question
  );

  const isDateQuestion = useSelector(isDateQuestionSelector);

  const isLoadingDistributionsToDisplay = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "PENDING"
  );

  const didErrorLoadingDistributionsToDisplay = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "ERROR"
  );

  const metadata = question?.metadata;

  const idOfHighlightedDistribution = useSelector(
    (state: RootState) => state.status.idOfHighlightedDistribution
  );

  const processedDistributions = distributions
    .map((distribution, i, distributions) => {
      const name = distribution.name;
      const data = distribution.histogram.map(({ density, x }) => ({
        x: isDateQuestion ? new Date(x * SERVER_TO_MS_TIMESTAMP_MULTIPLIER) : x,
        y: density,
      }));
      const isHighlighted = distribution.id === idOfHighlightedDistribution;
      const isFaded =
        !isHighlighted &&
        distributions.some((d) => d.id === idOfHighlightedDistribution);

      return { name, data, isHighlighted, isFaded };
    })
    .sort((a, b) => {
      if (a.isHighlighted) {
        return 2;
      }

      if (a.name === "user") {
        return 1;
      }

      return -1;
    });

  const hoveringOverBeliefId = useSelector(
    (state: RootState) => state.beliefsTable.hoveringOverBelief
  );

  const isHoveringOverBeliefValid = useSelector(
    isBeliefValidSelector(hoveringOverBeliefId)
  );

  const isHoverBeliefOutOfBounds = useSelector(
    isBeliefOutOfBoundsSelector(hoveringOverBeliefId)
  );

  const relevantBelief = useSelector((state: RootState) => {
    if (
      !hoveringOverBeliefId ||
      !isHoveringOverBeliefValid ||
      isHoverBeliefOutOfBounds
    ) {
      return null;
    }

    return state.beliefs.find((b) => b.id === hoveringOverBeliefId);
  });

  const areAllBeliefsValid = useSelector(
    areAllIntervalBeliefsOfSelectedForecastValidSelector
  );

  const isThereOutOfBoundsInfo = useSelector((state: RootState) =>
    state.distributions?.some((d) => d?.outOfBounds)
  );

  const hasLoadingMsgToDisplay =
    isLoadingDistributionsToDisplay ||
    didErrorLoadingDistributionsToDisplay ||
    !areAllBeliefsValid;

  return (
    <div style={{ position: "relative" }}>
      {isThereOutOfBoundsInfo && (
        <>
          <OutOfBoundsDisplay metadata={metadata} overOrUnder={"UNDER"} />
          <OutOfBoundsDisplay metadata={metadata} overOrUnder={"OVER"} />
        </>
      )}
      <div
        style={{
          alignItems: "center",
          display: hasLoadingMsgToDisplay ? "flex" : "none",
          justifyContent: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "380px",
        }}
      >
        {didErrorLoadingDistributionsToDisplay ? (
          <div
            style={{
              color: "#f66",
              fontSize: "28px",
              textAlign: "center",
            }}
          >
            Error computing distributions
          </div>
        ) : isLoadingDistributionsToDisplay ? (
          <div
            style={{
              color: "#666",
              fontSize: "28px",
              textAlign: "center",
            }}
          >
            <Spinner
              as="span"
              animation="grow"
              role="status"
              aria-hidden="true"
            />{" "}
            Computing distributions...
          </div>
        ) : !areAllBeliefsValid ? (
          <div
            style={{
              color: "#f66",
              fontSize: "28px",
              textAlign: "center",
            }}
          >
            Invalid bin preventing update
          </div>
        ) : null}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <props.PlotComponent
          {...props}
          distributions={processedDistributions}
          width={960}
          height={500}
          margin={{
            top: 20,
            right: 60,
            bottom: inlineMode ? 10 : 50,
            left: 60,
          }}
          metadata={metadata}
          relevantBelief={relevantBelief}
          areAllBeliefsValid={areAllBeliefsValid}
        />
      </div>
    </div>
  );
}
