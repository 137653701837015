import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import React, { useEffect, useState } from "react";
import { Element, scroller } from "react-scroll";
import { withRouter } from "react-router-dom";

const bigHeadingStyles = {
  color: "#444",
  fontSize: "28px",
  margin: "70px 0 30px",
  textAlign: "center" as "center",
};

const accordionHeadingStyles = {
  color: "#888",
  fontSize: "22px",
};

const scrollTo = (name) => {
  scroller.scrollTo(name, {
    smooth: true,
    delay: 250,
    duration: 250,
  });
};

export const FaqPage = withRouter((props) => {
  const [expanded, setExpanded] = useState({});

  const expandIfNeeded = (key) => {
    if (!expanded[key]) {
      setExpanded({ ...expanded, [key]: true });
    }
  };

  const hash = props.location.hash;

  // If someone loads hash link,
  // open relevant accordion
  // and scroll there.
  useEffect(() => {
    if (hash === "#compare") {
      expandIfNeeded("12");
      scrollTo("compare");
    } else if (hash === "#metaculus") {
      expandIfNeeded("8");
      scrollTo("metaculus");
    } else if (hash === "#community") {
      expandIfNeeded("5");
      scrollTo("community");
    }
  }, [hash]);

  const handleChange = (arg) => () =>
    setExpanded({ ...expanded, [arg]: !expanded[arg] });

  return (
    <div
      className="faq-container"
      style={{
        width: "800px",
        // Bottom margin is so scrolling to sections
        // lower down on the page have enough room
        margin: "80px auto 480px",
        textAlign: "left",
      }}
    >
      <h3 style={bigHeadingStyles}>What is Elicit?</h3>
      <div style={{ marginBottom: "30px", marginTop: "30px" }}>
        Elicit is a tool built by <a href="http://ought.org/">Ought</a> that
        takes beliefs you have about future events and their probabilities, and
        turns them into continuous distributions that you can share with others.
        Elicit currently supports{" "}
        <a href="https://www.metaculus.com/questions/">Metaculus</a> questions
        and custom questions.
      </div>
      <Accordion expanded={expanded["1"] || false} onChange={handleChange("1")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={accordionHeadingStyles}>Why did we build Elicit?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            Ought is building tools to{" "}
            <a href="http://ought.org/mission">
              delegate complex reasoning to machines
            </a>
            . We're starting with tools that make reasoning about the future
            easier and more accurate. To do this, we make the reasoning that
            goes into forecasts more explicit so that we can automate and
            delegate it over time. The first step for Elicit is automatically
            turning your beliefs into a distribution. Eventually, we hope to
            create a tool that can answer ambiguous, complex questions by
            breaking down the process into smaller, more easily solvable
            components.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["2"] || false} onChange={handleChange("2")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div style={accordionHeadingStyles}>What can I use Elicit for?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <strong style={{ color: "#888" }}>Elicit + Custom Questions</strong>
            <ul>
              <li>
                Create custom personal questions like:{" "}
                <a href="/builder/FxGNhAkXv">
                  When will my daughter’s passport arrive
                </a>
                ?
              </li>
              <li>
                Forecast work outcomes like:{" "}
                <a href="/builder/i6ygQH-oV">
                  If we start a grant initiative, when will we terminate it
                </a>
                ?
              </li>
              <li>
                See how your predictions differ from others’ by{" "}
                <a
                  href="#compare"
                  onClick={() => {
                    if (hash === "#compare") {
                      expandIfNeeded("12");
                      scrollTo("compare");
                    }
                  }}
                >
                  importing distributions
                </a>
              </li>
            </ul>
            <strong style={{ color: "#888" }}>Elicit + Metaculus</strong>
            <ul>
              <li id="foo">
                <a
                  href="#metaculus"
                  onClick={() => {
                    if (hash === "#metaculus") {
                      expandIfNeeded("8");
                      scrollTo("metaculus");
                    }
                  }}
                >
                  Easily submit your distributions to Metaculus
                </a>
              </li>
              <li>
                <a
                  href="#community"
                  onClick={() => {
                    if (hash === "#community") {
                      expandIfNeeded("5");
                      scrollTo("community");
                    }
                  }}
                >
                  Incorporate the Metaculus community distribution into your
                  prediction
                </a>
              </li>
              <li>
                Discover the implications of your beliefs and how you differ
                from the community using the ‘Interpret’ tab
              </li>
            </ul>
            <strong style={{ color: "#888" }}>Power tips</strong>
            <ul>
              <li>
                <strong>Enter percentile beliefs</strong> by leaving the Min box
                blank, entering the X value of your percentile belief in the Max
                box, and entering your percentile in the probability box.{" "}
                <a href="/builder/1upc7cqvN">Here’s</a> an example.
              </li>
              <li>
                <strong>Enter probabilities that don’t sum to 100%</strong>.
                Elicit will do the math to fit your beliefs as closely as
                possible while ensuring the area under the curve is always 1.
              </li>
              <li>
                <strong>See the probability for any interval</strong> by
                creating a new bin, entering the interval you want to see,
                leaving the probability box blank, and hovering over it.
              </li>
              <li>
                <strong>Avoid losing your snapshots by logging in</strong>. If
                you’re logged in, Elicit stores all your snapshots in ‘Snapshot
                History.’
              </li>
              <li>
                <strong> Assign probability outside the range </strong> on the
                graph by clicking the ‘Edit’ buttons to the left or right of the
                graph. You can only do this for questions that have open bounds.
              </li>
              <li>
                <strong>
                  Specify large numbers using 1M, 1,000,000, or 1e9
                </strong>
                . The same applies to 1K and 1B.
              </li>
              <li>
                <strong>Auto-create a new row</strong> by pressing ‘enter’ twice
                in the bin or probability fields.
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <h3 style={bigHeadingStyles}>How does Elicit work?</h3>
      <div style={{ marginBottom: "30px" }}>
        Elicit takes the probabilities you enter and finds a probability
        distribution that matches them as closely as possible, subject to other
        constraints (remain uncertain, stay close to the community, be smooth,
        add to 100%).
      </div>
      <Accordion expanded={expanded["3"] || false} onChange={handleChange("3")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <div style={accordionHeadingStyles}>
            How do you turn the bins I enter into a probability density?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              We break the probability density over your question range into 200
              points that we can adjust the probability of. For each bin you
              enter, we adjust the 200 points to make the density match your
              specified bin probabilities as closely as possible. Each bin is a
              condition that we calculate a loss function for by comparing the
              difference between your bin probability and the area under the
              200-point probability density curve. We sum the loss functions
              across all conditions to generate a total loss function. Using
              gradient descent, we minimize this total loss function so that the
              200-point density matches all your bins as closely as possible.
            </p>
            <p>
              That’s why you can enter in beliefs that are inconsistent or don’t
              sum to 100%. The resulting probability density will always have an
              area of 1, but you don’t have to do the math. Elicit will find the
              area-1 curve that best matches the bin probabilities you specify.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["4"] || false} onChange={handleChange("4")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <div style={accordionHeadingStyles}>How does smoothing work?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              When you check <em>smoothing</em>, you’re adding another condition
              that we optimize for. The smoothing condition tells us: Make each
              of the 200 points close to their neighboring points, with a higher
              penalty the further away they are. We add this smoothing condition
              into the total loss function, and try to fit the 200 points so
              that they both match your bins and satisfy the smoothing
              condition.
            </p>
            <p>
              When you increase the smoothing parameter, you tell Elicit to
              prioritize the smoothing condition more relative to your bins. The
              parameter can be an arbitrary value between 0 and infinity.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["5"] || false} onChange={handleChange("5")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <Element name="community">
            <div style={accordionHeadingStyles}>
              How does community prior work?
            </div>
          </Element>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              Checking <em>community prior</em> adds another condition that
              Elicit tries to satisfy. When community prior is unchecked, we
              include a <em>maximum entropy condition</em>. This condition tries
              to make the probability density as uncertain as possible, which is
              why the default is a uniform distribution. When community prior is
              checked, we change this to a <em>cross entropy condition</em>{" "}
              which penalizes the density for diverging from the community.
            </p>
            <p>
              If you increase the community prior parameter, Elicit weights
              making your distribution look like the community’s more relative
              to trying to match your bins.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["6"] || false} onChange={handleChange("6")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <div style={accordionHeadingStyles}>
            Remind me how probability densities work?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              Elicit displays your beliefs as a probability density function
              (PDF). A PDF represents the likelihood of all outcomes for a
              continuous variable; that is, a variable whose outcome can take on
              any value within the range. For example, how tall people are or
              when humans will go to Mars are both continuous variables. For
              purely continuous probability densities, you can’t assign a
              probability to a single value, only to intervals. The probability
              of an interval is the area under the probability density curve.
            </p>
            <p>
              Check out{" "}
              <a href="https://towardsdatascience.com/probability-concepts-explained-probability-distributions-introduction-part-3-4a5db81858dc">
                this great post
              </a>{" "}
              for more information.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["7"] || false} onChange={handleChange("7")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={accordionHeadingStyles}>
            Can I express percentile beliefs?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            Yes you can! Leave the Min box blank, enter the X value of your
            percentile belief in the Max box, and enter your percentile in the
            probability box. <a href="/builder/1upc7cqvN">Here’s</a> an example.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded["8"] || false} onChange={handleChange("8")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel8-content"
          id="panel8-header"
        >
          <Element name="metaculus">
            <div style={accordionHeadingStyles}>
              What happens when I submit to Metaculus?
            </div>
          </Element>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            Metaculus requires distributions to be submitted as mixtures of
            logistic distributions. When you submit to Metaculus, we create 1 to
            5 logistic distributions that best match the Elicit distribution you
            created. Because we have to use logistic distributions, sometimes
            your Metaculus distribution won’t exactly match your Elicit
            distribution. If we can’t match it perfectly, we’ll let you know and
            you can check what your submission will look like.
          </div>
        </AccordionDetails>
      </Accordion>
      <h3 style={bigHeadingStyles}>Elicit features</h3>
      <Accordion expanded={expanded["9"] || false} onChange={handleChange("9")}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel9-content"
          id="panel9s-header"
        >
          <div style={accordionHeadingStyles}>What’s a snapshot?</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              When you take a snapshot, we create a static URL that shows your
              current distribution. You, or people you share your snapshot with,
              can change the bins and probabilities on the snapshot, but each
              time you refresh it will reset back to its initial distribution.
              Create a new snapshot if you want to save your edits.
            </p>
            <p>
              If you’re logged in, we’ll store each snapshot you take in your
              snapshot history. If you’re not logged in, the snapshots will
              disappear if you refresh your page so make sure to save the URLs
              somewhere.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded["10"] || false}
        onChange={handleChange("10")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel10-content"
          id="panel10-header"
        >
          <div style={accordionHeadingStyles}>
            How do I make my own question?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            You can enter any continuous question that has a numerical answer
            into the text box on the Elicit homepage. Enter a min and a max
            value that you think almost all of the probability is between, then
            click 'Create' to make your question.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded["11"] || false}
        onChange={handleChange("11")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel11-content"
          id="panel11-header"
        >
          <div style={accordionHeadingStyles}>
            Can I predict binary questions using Elicit?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>We don’t currently support binary predictions.</div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded["12"] || false}
        onChange={handleChange("12")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel12-content"
          id="panel12-header"
        >
          <Element name="compare">
            <div style={accordionHeadingStyles}>
              How do I compare my prediction to someone else’s prediction?
            </div>
          </Element>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              To overlay two distributions, click 'Import forecast' on the
              Elicit question page and paste in a snapshot URL you want to
              compare. Go to the Interpret tab to see how the snapshots differ.
            </p>
            <p>
              Save a new snapshot with both distributions overlaid to share with
              others.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded["13"] || false}
        onChange={handleChange("13")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel13-content"
          id="panel13-header"
        >
          <div style={accordionHeadingStyles}>
            How do I assign probability outside of the range of the graph?
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              Click the ‘Edit’ button next to the graph to allocate probability
              outside of the range. You can only have bins that are either
              entirely inside the range, or entirely outside.
            </p>
            <p>
              For example: If the max of the graph range is 2100 and you want to
              assign 50% to an interval from 2080 to above 2100, you should:
            </p>
            <ul>
              <li>Assign ~20% probability to a bin from 2080 to 2100 it</li>
              <li>
                Click the ‘Edit’ button to the right of the graph to create a
                bin from 2100 to Max, and assign ~30% probability to it
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <h3 style={bigHeadingStyles}>Contact Us</h3>
      If you have feedback on Elicit, ideas for what we should build next, or
      just want to chat, we’d love to hear from you! You can email us at{" "}
      <a href="mailto:elicit@ought.org">elicit@ought.org</a>. You can also join
      our Elicit slack by clicking{" "}
      <a href="https://join.slack.com/t/elicit-ought/shared_invite/zt-ilceloj8-Hg~H~6N1EoKzGpIbyzaYeA">
        here
      </a>
      .
    </div>
  );
});
