import { RootState } from "../reducers/rootReducer";

import { isBeliefValidSelector } from "./isBeliefValidSelector";
import { selectedForecastSelector } from "./selectedForecastSelector";

export function areAllIntervalBeliefsOfSelectedForecastValidSelector(
  state: RootState
) {
  const selectedForecast = selectedForecastSelector(state);

  if (!selectedForecast) {
    return true;
  }

  const intervalBeliefs = state.beliefs.filter((b) =>
    selectedForecast.intervalBeliefIds.includes(b.id)
  );

  const isAnyIntervalBeliefInvalid = intervalBeliefs.some(
    (b) => !isBeliefValidSelector(b.id)(state)
  );

  return !isAnyIntervalBeliefInvalid;
}
