import { v4 as uuidv4 } from "uuid";

import { UpdateForecastNotesAction } from "../reducers/forecastsReducer";

export const updateForecastNotes = (
  forecastId: string,
  notes: string
): UpdateForecastNotesAction => ({
  type: "UPDATE_FORECAST_NOTES",
  forecastId,
  notes,
});
