const TOLERATED_DIFFERENCE_FROM_NORMALIZED_P = 0.1;

export function updateSnapshotToV3(state, version: number) {
  return {
    ...state,
    beliefs: state.beliefs.map((b) => {
      // Ensure old community/smoothing beliefs
      // have a values field with 1 strength
      if (b.type === "community" || b.type === "smoothing") {
        return {
          ...b,
          values: b.values || {
            strength: 1,
          },
        };
      }

      if (b.type !== "interval") {
        return b;
      }

      // Test whether before .values by looking at .min
      // If it is, move min/max/p to values
      if (b.min !== undefined) {
        return {
          ...b,
          values: {
            min: b.min,
            max: b.max,
            p: b.p,
          },
          min: undefined,
          max: undefined,
          p: undefined,
        };
      }

      return b;
    }),
    beliefOrder:
      state.beliefOrder ||
      state.beliefs.filter((b) => b.type === "interval").map((b) => b.id),
    currentDistributions: getNormalizedDensityDistributions(state, version),
    status: {
      ...state.status,
      // Backwards-compatibility issue:
      // Older snapshots didn't have this field.
      // They just used local state.
      // So we'll make sure community and user are displayed.
      namesOfVisibleDistributions: state?.status
        ?.namesOfVisibleDistributions || ["community", "user"],
      // Backwards-compatibility issue:
      // Older snapshots didn't have this field.
      // So we'll set it to true if the snapshot was taken
      // while the submission plot was displayed, and set
      // it to false if the snapshot was taken while the
      // submission plot was hidden.
      shouldShowSubmissionOnLegend: state.showSubmission.show,
      version: 3,
    },
    forecast: {
      ...state.forecast,
      metadata: {
        ...state.forecast.metadata,

        // Backwards-compatibility issue:
        // Older snapshots all had closed bounds and lacked this field
        // So if this field is missing, assume that the bound is closed
        hasLowerBound:
          state.forecast.metadata.hasLowerBound === undefined
            ? true
            : state.forecast.metadata.hasLowerBound,
        hasUpperBound:
          state.forecast.metadata.hasUpperBound === undefined
            ? true
            : state.forecast.metadata.hasUpperBound,
      },
    },
  };
}

function areDistributionsAlreadyNormalized(distributions) {
  // Some v1 snapshots have distributions that are already normalized.
  //
  // I'm pretty sure that at least one cause of this is that until recently,
  // we didn't update the snapshot version on resave.
  //
  // In any case, we don't want to renormalize distributions that are already normalized.
  //
  // Unfortunately we have no great way to tell if they are already normalized,
  // so just check if the histogram has about the right amount of probability mass.
  //
  // If it does, assume the distribution is already normalized.
  const userDist = distributions.filter(
    (distribution) => distribution.name === "user"
  )[0];
  const pBelow = userDist.metadata?.out_of_bounds?.p_below || 0;
  const pAbove = userDist.metadata?.out_of_bounds?.p_above || 0;
  const pShouldBeInHistogram = 1 - pBelow - pAbove;
  const pIsInHistogram =
    userDist.histogram.reduce((pSoFar, point) => pSoFar + point.density, 0) /
    userDist.histogram.length;
  if (
    Math.abs(pIsInHistogram - pShouldBeInHistogram) <
    TOLERATED_DIFFERENCE_FROM_NORMALIZED_P
  ) {
    return true;
  }
  return false;
}

function getNormalizedDensityDistributions(state, version) {
  // state.distributionsToDisplay
  // name changed to state.currentDistributions
  const originalDistributions = state.distributionsToDisplay
    ? state.distributionsToDisplay
    : state.currentDistributions;

  // distributions after V1 have normalized rather than true-scale density
  if (version > 1 || areDistributionsAlreadyNormalized(originalDistributions)) {
    return originalDistributions;
  } else {
    return originalDistributions.map((dist) => {
      return {
        ...dist,
        histogram: dist.histogram.map((point) => {
          return {
            ...point,
            density: point.density * state.forecast.metadata.graphScale.width,
          };
        }),
      };
    });
  }
}
