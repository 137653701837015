import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";
import { replaceSnapshotIds } from "../helpers/snapshots/replaceSnapshotIds";

import { AppThunk } from "../reducers/rootReducer";

export function createSnapshot({
  accessToken,
  onDone,
}: {
  accessToken?: string;
  onDone?: (key: string) => void;
}): AppThunk {
  return async function (dispatch, getState) {
    try {
      const stateWithReplacedIds = replaceSnapshotIds(getState());

      const stateWithNoUserInfo = {
        ...stateWithReplacedIds,
        // When Redux's combineReducers encounters
        // an undefined slice, it falls back to the default
        // specified in the reducer. So using undefined here
        // is a way of delegating the default initial state
        // to the reducer for that slice.
        binaryQuestions: undefined,
        user: null,
      };

      const dehydratedState = JSON.stringify(stateWithNoUserInfo);

      const unprocessedState = getState();

      // Only send questionId for custom questions
      const questionId = !unprocessedState.question.metadata.source
        ? unprocessedState.question.metadata.questionId
        : null;

      const response = await makeRequestToBackend({
        path: `/snapshots`,
        method: "POST",
        accessToken,
        body: {
          input: {
            state: dehydratedState,
            version: unprocessedState.status.version,
            questionId,
          },
        },
      });

      const responseJson = await response.json();

      const { key } = responseJson;

      if (process.env.NODE_ENV === "production") {
        try {
          (window as any).analytics?.track("Snapshot saved", {
            snapshotKey: key,
            origin: window.location.origin,
            snapshotTitle: unprocessedState.question.title,
            userDisplayName: unprocessedState.user?.displayName,
            userEmail: unprocessedState.user?.email,
          });
        } catch (err) {
          // Ensure logging error doesn't crash app
          // But track any error that comes up in the logging process
          Sentry.captureException(err);
        }
      }

      onDone && onDone(key);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
