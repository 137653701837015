import React from "react";

import { BinsTooltip } from "../tooltips/BinsTooltip";
import { NotesTooltip } from "../tooltips/NotesTooltip";
import { ProbabilityTooltip } from "../tooltips/ProbabilityTooltip";

// Stylistically coupled to surroundings
// because the margins below are used to center
// the headings over the relevant cells.
export const TableHeadings = () => (
  <div
    style={{
      paddingBottom: "20px",
      borderBottom: "2px solid #efefef",
      textAlign: "left",
    }}
  >
    <BinsTooltip>
      <div
        style={{
          display: "inline-block",
          marginLeft: "170px",
        }}
      >
        Bins
      </div>
    </BinsTooltip>

    <ProbabilityTooltip>
      <div
        style={{
          display: "inline-block",
          marginLeft: "155px",
        }}
      >
        Probability
      </div>
    </ProbabilityTooltip>

    <NotesTooltip>
      <div
        style={{
          display: "inline-block",
          marginLeft: "170px",
        }}
      >
        Notes
      </div>
    </NotesTooltip>
  </div>
);
