export interface BinaryQuestion {
  id: string;
  title: string;
  resolvesBy: string;
  resolution: string | null;
  privacyStatus: "PRIVATE" | "PUBLIC";
  notes: string | null;
  tags: { name: string }[];
  source?: { name: string; url: string };
  sourceUrl: string | null;
}

export interface ReplaceBinaryQuestionsAction {
  type: "REPLACE_BINARY_QUESTIONS";
  binaryQuestions: any;
}

export interface AddBinaryQuestionAction {
  type: "ADD_BINARY_QUESTION";
  binaryQuestion: any;
}

export interface DisplayUpdatedResolutionAction {
  type: "DISPLAY_UPDATED_RESOLUTION";
  binaryQuestionId: string;
  resolution: "YES" | "NO";
}

export interface AddTagToQuestionAction {
  type: "ADD_TAG_TO_QUESTION";
  questionId: string;
  tag: { name: string };
}

export interface RemoveTagFromQuestionAction {
  type: "REMOVE_TAG_FROM_QUESTION";
  questionId: string;
  tag: { name: string };
}

const initialState = [] as BinaryQuestion[];

type BinaryQuestionAction =
  | ReplaceBinaryQuestionsAction
  | AddBinaryQuestionAction
  | DisplayUpdatedResolutionAction
  | AddTagToQuestionAction
  | RemoveTagFromQuestionAction;

export function binaryQuestionsReducer(
  state = initialState,
  action: BinaryQuestionAction
): BinaryQuestion[] {
  if (action.type === "REPLACE_BINARY_QUESTIONS") {
    return action.binaryQuestions;
  }

  if (action.type === "ADD_BINARY_QUESTION") {
    return [
      { ...action.binaryQuestion, wasJustAdded: true },
      ...state.map((q) => ({ ...q, wasJustAdded: false })),
    ];
  }

  if (action.type === "DISPLAY_UPDATED_RESOLUTION") {
    return state.map((q) => {
      if (q.id === action.binaryQuestionId) {
        return { ...q, resolution: action.resolution };
      }

      return q;
    });
  }

  if (action.type === "ADD_TAG_TO_QUESTION") {
    return state.map((q) => {
      if (q.id === action.questionId) {
        return { ...q, tags: [...q.tags, action.tag] };
      }

      return q;
    });
  }

  if (action.type === "REMOVE_TAG_FROM_QUESTION") {
    return state.map((q) => {
      if (q.id === action.questionId) {
        return { ...q, tags: q.tags.filter((t) => t.name !== action.tag.name) };
      }

      return q;
    });
  }

  return state;
}
