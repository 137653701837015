import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "react-bootstrap";
import React from "react";
import { useSelector } from "react-redux";

import { LoginButton } from "../LoginBtn";

import { hasBeenLongEnoughToShowPleaseLogin } from "../../helpers/hasBeenLongEnoughToShowPleaseLogin";

import { RootState } from "../../reducers/rootReducer";

export function SubmittedPage() {
  const qUrl = useSelector(
    (state: RootState) => state.question.metadata.sourceUrl
  );

  const { user } = useAuth0();

  const hasBeenLongEnough = hasBeenLongEnoughToShowPleaseLogin();

  const shouldShowPleaseLoginMsg = !user && hasBeenLongEnough;

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Prediction submitted to Metaculus!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "inline-block",
            marginBottom: !shouldShowPleaseLoginMsg && "20px",
            textAlign: "left",
          }}
        >
          <div>
            <a
              style={{ fontSize: "20px" }}
              href={qUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View question on Metaculus
            </a>
          </div>
        </div>
      </Modal.Body>
      {shouldShowPleaseLoginMsg && (
        <Modal.Footer>
          <div>
            If you log in or create an account, we’ll save a snapshot of this
            submission to your account so that you can easily find it later, and
            we'll automatically save your Metaculus account so you don't have to
            grant access to Ought again.
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <LoginButton size="md" />
            </div>
          </div>
        </Modal.Footer>
      )}
    </>
  );
}
