import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { QueryParamProvider } from "use-query-params";

import { RootState } from "../reducers/rootReducer";

import { AuthProvider } from "./AuthProvider";
import { AuthRedirect } from "./AuthRedirect";
import { BinaryQuestions } from "../features/binaryQuestions/components/BinaryQuestions";
import { DetectAndLoadUserIntoRedux } from "./DetectAndLoadUserIntoRedux";
import { DistBuilderPage } from "./DistBuilderPage";
import { Header } from "./Header";
import { FaqPage } from "./FaqPage";
import { Footer } from "./Footer";
import { PredictItNotificationsContainer } from "./PredictItNotificationsContainer";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage";
import { ProfilePage } from "./ProfilePage";
import { Homepage } from "./homepage/Homepage";
import { SnapshotsViewer } from "./SnapshotsViewer";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";

export function App() {
  const title = useSelector((state: RootState) => state.question?.title);

  const didFailToLoadFirebaseSnapshot = useSelector(
    (state: RootState) => state.status.didFailToLoadFirebaseSnapshot
  );

  if (didFailToLoadFirebaseSnapshot) {
    return (
      <div style={{ margin: "80px auto", maxWidth: "800px" }}>
        Connection timed out before the snapshot could be loaded. Please refresh
        to try again.
      </div>
    );
  }

  const pageTitle = title || "Elicit Forecast";

  return (
    <Router>
      <AuthProvider>
        <QueryParamProvider ReactRouterRoute={Route}>
          <DetectAndLoadUserIntoRedux />
          <Helmet>
            <title>{pageTitle}</title>
            <meta property="og:title" content={pageTitle} />
            <meta
              property="og:description"
              content="Elicit converts beliefs into probabilities"
            />
            <meta name="twitter:site" content="@oughtinc" />
          </Helmet>
          <Header />
          <Route path="/" exact={true}>
            <Homepage />
          </Route>
          <Route path="/auth">
            <AuthRedirect />
          </Route>
          <Route path="/binary">
            <div style={{ margin: "auto auto" }}>
              <BinaryQuestions />
            </div>
          </Route>
          <Route path="/builder">
            <DistBuilderPage />
          </Route>
          <Route path="/faq">
            <FaqPage />
          </Route>
          <Route path="/login" />
          <Route path="/predictit" exact={true}>
            <PredictItNotificationsContainer />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/questions/:id">
            <DistBuilderPage />
          </Route>
          <Route path="/snapshots">
            <SnapshotsViewer />
          </Route>
          <Route path="/terms">
            <TermsAndConditionsPage />
          </Route>
          <Footer />
        </QueryParamProvider>
      </AuthProvider>
    </Router>
  );
}
