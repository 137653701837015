import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

import { updateBinaryPrediction } from "../actions/updateBinaryPrediction";
import { updateBinaryPredictionNotesOnFrontend } from "../actions/updateBinaryPredictionNotesOnFrontend";

import { shortenProbability } from "../helpers/shortenProbability";

const Styles = styled.div`
  .MuiInputBase-input {
    font-size: 16px;
  }

  .show-on-hover-container .show-on-hover {
    visibility: hidden;
  }

  .show-on-hover-container:hover .show-on-hover {
    visibility: visible;
  }
`;

export function BinaryPrediction({ prediction: binaryPrediction }) {
  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const notesInputEl = useRef(null);

  const {
    id,
    prediction,
    creator,
    createdAt,
    notes,
    wasJustAdded,
    privacyStatus,
    isYourPrediction,
  } = binaryPrediction;

  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notesInput, setNotesInput] = useState(notes);

  const handleUpdateNotes = async () => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    setIsEditingNotes(false);
    dispatch(
      updateBinaryPrediction({
        accessToken,
        binaryPredictionId: id,
        fields: { notes: notesInput },
        onSuccess: () => {},
      })
    );
    dispatch(
      updateBinaryPredictionNotesOnFrontend({
        binaryPredictionId: id,
        notes: notesInput,
      })
    );
  };

  useEffect(() => {
    if (isEditingNotes) {
      notesInputEl.current.focus();
      notesInputEl.current.setSelectionRange(
        notesInputEl.current.value.length,
        notesInputEl.current.value.length
      );
    }
  }, [isEditingNotes]);

  return (
    <Styles
      style={{
        display: "grid",
        gridTemplateColumns: "120px 150px 150px auto",
        gridColumnGap: "20px",
      }}
    >
      <span
        style={{
          marginRight: "20px",
          width: "100px",
        }}
      >
        {shortenProbability(prediction)}%
      </span>
      <span>
        {creator.displayName} {privacyStatus === "PRIVATE" && ` 🔒 `}
      </span>
      <span>{moment(createdAt).format("D MMM YYYY")}</span>
      <span
        className="show-on-hover-container"
        style={{
          /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          minWidth: "600px",
        }}
      >
        {!isYourPrediction && (
          <div style={{ paddingBottom: "8px" }}>{notesInput || " "}</div>
        )}
        {isYourPrediction && (
          <TextareaAutosize
            ref={notesInputEl}
            value={notesInput}
            onKeyDown={async (e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.stopPropagation();
                notesInputEl.current.blur();
              }
            }}
            onBlur={handleUpdateNotes}
            onChange={(e) => setNotesInput(e.target.value)}
            style={{ width: "70%" }}
            placeholder="Add notes"
          />
        )}
      </span>
    </Styles>
  );
}
