import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { RootState } from "../reducers/rootReducer";

const SpanWithLinkStyling = styled.span`
  color: #007bff;
  cursor: pointer;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const SNAPSHOT_RECORDS_TO_SHOW_WHEN_COLLAPSED = 3;

export function PersistentSnapshotRecords() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [
    areAllSnapshotRecordsVisible,
    setAreAllSnapshotRecordsVisible,
  ] = useState(false);

  const snapshotRecordsInRedux = useSelector(
    (state: RootState) => state.sessionSnapshotRecords
  );

  const questionId = useSelector(
    (state: RootState) => state.question.metadata.questionId
  );

  const [snapshotRecords, setSnapshotRecords] = useState([] as any);

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    // When a page loads after a login redirect
    // any snapshots in the previous session
    // are saved under the logged-in user's account.
    // If this component requests the user's snapshots
    // before the backend finishes this associating,
    // then the snapshots taken before logging in
    // won't show up. This 3s timeout is a simple way
    // to avoid this race condition. Because the associating
    // is fast, and Node is async, 3s should always be
    // more than enough.
    if (!hasMounted) {
      setHasMounted(true);
      setTimeout(fn, 3000);
    }

    async function fn() {
      if (user) {
        const accessToken = user ? await getAccessTokenSilently() : undefined;

        const response = await makeRequestToBackend({
          path: `/questions/${questionId}/snapshots?userId=me&snapshot.fields=createdAt`,
          method: "GET",
          accessToken,
        });

        const data = await response.json();

        setSnapshotRecords(
          data.map((d) => ({ createdAt: d.createdAt, key: d.id }))
        );
      }
    }

    fn();
  }, [snapshotRecordsInRedux, user, hasMounted]);

  return (
    <>
      {(areAllSnapshotRecordsVisible
        ? snapshotRecords
        : snapshotRecords.slice(0, SNAPSHOT_RECORDS_TO_SHOW_WHEN_COLLAPSED)
      ).map(({ createdAt, key }) => {
        const url = `${window.location.origin}/builder/${key}`;

        return (
          <div
            key={key}
            style={{
              marginBottom: "5px",
              flexBasis: "100%",
              height: "100%",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <a href={url} target="_blank">
                Snapshot on {moment(createdAt).format("ll, h:mm:ss A")}
              </a>
            </span>
          </div>
        );
      })}

      {snapshotRecords.length > SNAPSHOT_RECORDS_TO_SHOW_WHEN_COLLAPSED &&
        (!areAllSnapshotRecordsVisible ? (
          <SpanWithLinkStyling
            onClick={() => setAreAllSnapshotRecordsVisible(true)}
          >
            More ▾
          </SpanWithLinkStyling>
        ) : (
          <SpanWithLinkStyling
            onClick={() => setAreAllSnapshotRecordsVisible(false)}
          >
            Less ▴
          </SpanWithLinkStyling>
        ))}
    </>
  );
}
