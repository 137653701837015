import { convertToInput } from "./convertToInput";
import { convertToValue } from "./convertToValue";

// Standardizes input by converting the input string
// into a value, then converting the value into a string.
// So after this, potentially different strings associated
// with the same value will convert into the same string.
export const formatInput = ({ id, input, isDateQuestion }) => {
  const formattedInput = String(
    convertToInput(
      id,
      convertToValue(id, input, isDateQuestion),
      isDateQuestion
    )
  );

  return formattedInput;
};
