import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../makeRequestToBackend";

export async function retrieveSnapshot({
  key,
  onDone,
  onError,
}: {
  key: string;
  onDone?: (responseJson: any) => void;
  onError?: (errMsg: any) => any;
}) {
  try {
    const response = await makeRequestToBackend({
      path: `/snapshots/${key}`,
      method: "GET",
    });

    if (response.status === 404) {
      return onError && onError("Snapshot not found");
    }

    const responseJson = await response.json();

    const { version, state, createdAt, user } = responseJson;

    onDone && onDone({ version, state, createdAt, user });
  } catch (e) {
    Sentry.captureException(e);
    onError && onError("Error retrieving snapshot");
  }
}
