import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function fetchUserInfo({
  accessToken,
  onSuccess,
}: {
  accessToken: string;
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function(dispatch) {
    try {
      const response = await makeRequestToBackend({
        path:
          "/users/me?user.fields=apiKey,displayName,metaculusAPIKeys,metaculusUsername,metaculusId",
        method: "GET",
        accessToken,
      });

      const data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }

      onSuccess(data);
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  };
}
