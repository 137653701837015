import isNil from "lodash.isnil";

import { RootState } from "../reducers/rootReducer";

export const isHoveringOverOutOfBoundsBelief = (
  overOrUnder: "OVER" | "UNDER"
) => (state: RootState) => {
  const beliefId = state.beliefsTable.hoveringOverBelief;

  if (isNil(beliefId)) {
    return false;
  }

  const belief = state.beliefs.find((b) => b.id === beliefId);

  if (!belief) {
    return false;
  }

  if (
    (overOrUnder === "OVER" && belief.values.max === null) ||
    (overOrUnder === "UNDER" && belief.values.min === null)
  ) {
    return true;
  }

  return false;
};
