import React, {
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { addHoverForBelief } from "../../actions/addHoverForBelief";
import { removeHoverForBelief } from "../../actions/removeHoverForBelief";

import { setShouldShowStartArrow } from "../../actions/setShouldShowStartArrow";
import { updateBelief } from "../../actions/updateBelief";
import { updateBeliefValue } from "../../actions/updateBeliefValue";

import { RootState } from "../../reducers/rootReducer";

import { formatInput } from "./helpers/formatInput";
import { convertToInput } from "./helpers/convertToInput";
import { convertToValue } from "./helpers/convertToValue";

import { isValidValueInIntervalBelief } from "../../helpers/isValidValueInIntervalBelief";

import { isFirstLowerOutOfBoundsBeliefSelector } from "../../selectors/isFirstLowerOutOfBoundsBeliefSelector";
import { isFirstUpperOutOfBoundsBeliefSelector } from "../../selectors/isFirstUpperOutOfBoundsBeliefSelector";
import { isSelectedForecastEditableSelector } from "../../selectors/isSelectedForecastEditableSelector";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";

export const EditableCell = (props: any) => {
  const { belief, id, isDragged, setFocusedOnCell, hoveredOverRow } = props;

  const inputEl = useRef<HTMLInputElement | null>(null);

  const beliefId = belief.id;
  const beliefField = id;

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const isDateQuestion = useSelector(isDateQuestionSelector);

  const value = belief && belief.values[id];

  const [input, setInput] = useState(convertToInput(id, value, isDateQuestion));

  const shouldShowStartArrow = useSelector(
    (state: RootState) => state.status.shouldShowStartArrow
  );

  const hasAddedRow = useSelector(
    (state: RootState) => state.status.hasAddedRow
  );

  const isFirstLowerOutOfBoundsBelief = useSelector(
    isFirstLowerOutOfBoundsBeliefSelector(beliefId)
  );

  const isFirstUpperOutOfBoundsBelief = useSelector(
    isFirstUpperOutOfBoundsBeliefSelector(beliefId)
  );

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (
      !hasMounted &&
      hasAddedRow &&
      id === "p" &&
      !isDragged &&
      (isFirstLowerOutOfBoundsBelief || isFirstUpperOutOfBoundsBelief)
    ) {
      inputEl.current && inputEl.current.focus();
    } else if (
      !hasMounted &&
      hasAddedRow &&
      id === "min" &&
      !shouldShowStartArrow &&
      !isDragged
    ) {
      inputEl.current && inputEl.current.focus();
    }

    setHasMounted(true);
  }, [hasMounted]);

  // This is a value that changes when the app should
  // direct focus to the lower out of bounds belief
  // The actual value of this is irrelevant. What's relevant
  // is the change, which is detected by the below hooks.
  const focusLowerOutOfBoundsBelief = useSelector(
    (state: RootState) => state.beliefsTable.focusLowerOutOfBoundsBelief
  );

  // This is a value that changes when the app should
  // direct focus to the upper out of bounds belief
  // The actual value of this is irrelevant. What's relevant
  // is the change, which is detected by the below hooks.
  const focusUpperOutOfBoundsBelief = useSelector(
    (state: RootState) => state.beliefsTable.focusUpperOutOfBoundsBelief
  );

  useEffect(() => {
    if (
      hasMounted &&
      focusLowerOutOfBoundsBelief &&
      isFirstLowerOutOfBoundsBelief &&
      id === "p"
    ) {
      inputEl.current && inputEl.current.focus();
    }
  }, [focusLowerOutOfBoundsBelief]);

  useEffect(() => {
    if (
      hasMounted &&
      focusUpperOutOfBoundsBelief &&
      isFirstUpperOutOfBoundsBelief &&
      id === "p"
    ) {
      inputEl.current && inputEl.current.focus();
    }
  }, [focusUpperOutOfBoundsBelief]);

  const dispatch = useDispatch();

  const graphMax = useSelector(
    (state: RootState) => state.question.metadata.graphScale?.high
  );
  const graphMin = useSelector(
    (state: RootState) => state.question.metadata.graphScale?.low
  );
  const isUpperBoundClosed = useSelector(
    (state: RootState) => state.question.metadata.isUpperBoundClosed
  );
  const isLowerBoundClosed = useSelector(
    (state: RootState) => state.question.metadata.isLowerBoundClosed
  );

  if (
    graphMax === undefined ||
    graphMin === undefined ||
    isUpperBoundClosed === null ||
    isLowerBoundClosed === null
  ) {
    return null;
  }

  const [
    isValidValueResult,
    isValidValueErrorMsg,
  ] = isValidValueInIntervalBelief(id, value, {
    max: belief.values.max,
    min: belief.values.min,
    graphMax,
    graphMin,
    isUpperBoundClosed,
    isLowerBoundClosed,
    isDateQuestion,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleUpdateBelief = () => {
    const newValue = convertToValue(id, input, isDateQuestion);

    if (newValue !== value) {
      if (id === "notes") {
        dispatch(updateBelief(beliefId, "notes", newValue));
      } else {
        dispatch(updateBeliefValue(beliefId, beliefField, newValue));
        if (id === "p") {
          dispatch(updateBelief(beliefId, "isActive", newValue !== null));
        }
      }
    }
  };

  const onBlur = () => {
    handleUpdateBelief();

    const formattedInput = formatInput({ id, input, isDateQuestion });
    setInput(formattedInput);

    setFocusedOnCell(null);

    if (hoveredOverRow) {
      dispatch(addHoverForBelief(hoveredOverRow));
    } else {
      dispatch(removeHoverForBelief(belief.id));
    }
  };

  const onFocus = () => {
    if (input === "Min" || input === "Max" || input === "%") {
      setInput("");
    }

    setFocusedOnCell(beliefId);

    dispatch(addHoverForBelief(beliefId));

    dispatch(setShouldShowStartArrow(false));
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      inputEl.current?.blur();
    }
  };

  return (
    <span style={{ position: "relative" }}>
      <input
        ref={inputEl}
        disabled={!isSelectedForecastEditable}
        style={{
          backgroundColor: isDragged && "transparent",
          border: !isValidValueResult
            ? "1px solid red"
            : shouldShowStartArrow || isDragged
            ? "1px dotted #ccc"
            : undefined,
          color:
            input === "Min" || input === "Max" || input === "%"
              ? "#888"
              : undefined,
          padding: "5px 0",
          fontSize: "1rem",
          height: "36px",
          textAlign: "center",
          width: "117px",
        }}
        value={!isDragged ? input : convertToInput(id, value, isDateQuestion)}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />

      <span
        style={{
          color: "red",
          fontSize: "11px",
          fontWeight: 600,
          lineHeight: "11px",
          position: "absolute",
          top: "30px",
          right: 0,
        }}
      >
        {!isValidValueResult && isValidValueErrorMsg}
      </span>
    </span>
  );
};
