import { sliceSelector, ParentState } from "../dependencies/sliceSelector";

export const yourMostRecentPredictionSelector = (binaryQuestionId: string) => (
  parentState: ParentState
) => {
  const state = sliceSelector(parentState);

  const yourPredictions = state.binaryPredictions.filter(
    (bp) => bp.binaryQuestionId === binaryQuestionId && bp.isYourPrediction
  );

  yourPredictions.sort(
    (a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
  );

  return yourPredictions[0];
};
