import { AppThunk } from "../reducers/rootReducer";

export const navigateToCustomQuestion = ({
  customQuestionId,
  history,
}): AppThunk => () => {
  history.push({
    pathname: `/questions/${customQuestionId}`,
  });
};
