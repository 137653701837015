import { v4 as uuidv4 } from "uuid";

import { UpdateImpliedBeliefsAction as BeliefsUpdateImpliedBeliefsAction } from "../reducers/beliefsReducer";
import { UpdateImpliedBeliefsAction as ForecastsUpdateImpliedBeliefsAction } from "../reducers/forecastsReducer";

export const updateImpliedBeliefs = (
  forecastId: string,
  impliedBeliefs: any[], // TODO clean up; they are from server and I think without id
  oldImpliedBeliefIds: string[]
): BeliefsUpdateImpliedBeliefsAction & ForecastsUpdateImpliedBeliefsAction => ({
  type: "UPDATE_IMPLIED_BELIEFS",
  forecastId: forecastId,
  impliedBeliefs: impliedBeliefs.map((belief) => ({
    id: belief.id || uuidv4(),
    ...belief,
  })),
  oldImpliedBeliefIds: oldImpliedBeliefIds,
});
