export function distributionFromFramedHistogram({ framedHistogram, id }) {
  return {
    id: id,
    name: framedHistogram.name,
    histogram: framedHistogram.histogram,
    outOfBounds: {
      ...framedHistogram.frame,
      lowerBound: framedHistogram.frame.scale.low,
      upperBound: framedHistogram.frame.scale.high,
    },
  };
}
