import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import sortBy from "lodash.sortby";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useQueryParam, ArrayParam, NumberParam } from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

const Styles = styled.div`
  .MuiAutocomplete-input {
    font-size: 16px;
  }
`;

const ShowXOnHover = styled.span`
  .show-on-hover {
    opacity: 0.25;
  }

  &:hover .show-on-hover {
    opacity: 1;
  }
`;

export function FilteringByTag() {
  const [tagOptions, setTagOptions] = useState([]);

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    async function fn() {
      const accessToken = user ? await getAccessTokenSilently() : undefined;
      const response = await makeRequestToBackend({
        path: "/tags",
        accessToken,
      });
      const tags = await response.json();

      const sortedTags = sortBy(tags, (t) => t.name?.toLowerCase());

      setTagOptions(sortedTags);
    }
    fn();
  }, [user]);

  const dispatch = useDispatch();

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const fetchBinaryQuestions = async function () {
    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    } else if (!user) {
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken: undefined,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    }
  };

  const autocompleteEl = useRef(null);

  const [tagsQueryParam, setTagsQueryParam] = useQueryParam(
    "binaryQuestions.tags[]",
    ArrayParam
  );

  return (
    <Styles>
      <Autocomplete
        ref={autocompleteEl}
        id="tag-filtering-autocomplete"
        value={null}
        onChange={(e, obj: any) => {
          if (tagsQueryParam) {
            setTagsQueryParam([...tagsQueryParam, obj.name]);
          } else {
            setTagsQueryParam([obj.name]);
          }
          const inputEl =
            autocompleteEl.current.children[0].children[1].children[0];
          inputEl.style.color = "transparent";
          setTimeout(() => {
            inputEl.blur();
            inputEl.style.color = "rgba(0, 0, 0, 0.87)";
          }, 100);
          fetchBinaryQuestions();
        }}
        clearOnBlur={true}
        options={tagOptions.filter(
          (t) => !tagsQueryParam || !tagsQueryParam.includes(t.name)
        )}
        getOptionLabel={(obj) => obj.name}
        renderInput={(params) => (
          <TextField {...params} label="Filter by tag" margin="normal" />
        )}
        style={{
          marginLeft: "10px",
          marginTop: "-20px",
          fontSize: "12px",
          width: "150px",
        }}
      />
      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
        {tagsQueryParam && <span>Filtering by </span>}
        {tagsQueryParam &&
          tagsQueryParam.map((tName, i) => {
            return (
              <ShowXOnHover
                key={tName}
                style={{
                  borderRadius: "2px",
                  border: "solid 1px #3d90fb",
                  backgroundColor: "rgba(61, 144, 251, 0.12)",
                  color: "#3d90fb",
                  padding: "2px 5px",
                  margin: "0 5px",
                  marginLeft: i === 0 && 0,
                  whiteSpace: "nowrap",
                }}
              >
                {tName}
                <span
                  className="show-on-hover"
                  onClick={async () => {
                    const accessToken = user
                      ? await getAccessTokenSilently()
                      : undefined;

                    setTagsQueryParam(
                      tagsQueryParam.filter((tName2) => tName !== tName2)
                    );

                    dispatch(
                      fetchAndReplaceBinaryQuestionsAndResetOffset({
                        accessToken,
                        offsetQueryParam,
                        setOffsetQueryParam,
                      })
                    );
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "smaller",
                    marginLeft: "5px",
                  }}
                >
                  ❌
                </span>
              </ShowXOnHover>
            );
          })}
      </div>
    </Styles>
  );
}
