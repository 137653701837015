import React from "react";
import { TemplatedLabelBeliefDisplay } from "./TemplatedLabelBeliefDisplay";

export const TemplatedBeliefDisplay = ({
  label,
  subLabels,
  values,
  isDateQuestion,
  beliefId,
}) => {
  return (
    <>
      <TemplatedLabelBeliefDisplay
        label={label}
        values={values}
        isDateQuestion={isDateQuestion}
        beliefId={beliefId}
      />
      {subLabels && subLabels.length > 0 && (
        <ul>
          {subLabels.map((subLabel) => (
            <li key={subLabel}>
              <TemplatedLabelBeliefDisplay
                label={subLabel}
                values={values}
                isDateQuestion={isDateQuestion}
                beliefId={beliefId}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
