import React from "react";

import { Tooltip } from "./Tooltip";

export function BinsTooltip(props) {
  return (
    <Tooltip
      content={
        "Build your distribution by defining intervals and the likelihood that the answer falls within that interval. You can leave the Min (or Max) blank and we'll interpret it as the smallest (or largest) possible value. Leaving the Min blank is a way to express percentile beliefs."
      }
      title={"Bins"}
    >
      {props.children}
    </Tooltip>
  );
}
