import { RootState } from "../reducers/rootReducer";

export const isBeliefOutOfBoundsSelector = (beliefId: string) => (
  state: RootState
) => {
  const belief = state.beliefs.find((b) => b.id === beliefId);

  if (!belief) {
    return null;
  }

  const { low, high } = state.question.metadata.graphScale;

  if (belief.values.min !== null && belief.values.min < low) {
    return true;
  }
  if (belief.values.max !== null && belief.values.max <= low) {
    return true;
  }

  if (belief.values.min !== null && belief.values.min >= high) {
    return true;
  }
  if (belief.values.max !== null && belief.values.max > high) {
    return true;
  }

  return false;
};
