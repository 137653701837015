import { SetShouldShowBinaryToCustomFlowAction } from "../reducers/statusReducer";

export function setShouldShowBinaryToCustomFlow(
  shouldShowBinaryToCustomFlow: boolean
): SetShouldShowBinaryToCustomFlowAction {
  return {
    type: "SET_SHOULD_SHOW_BINARY_TO_CUSTOM_FLOW",
    shouldShowBinaryToCustomFlow,
  };
}
