import { SetLoadingSearchPageQuestionsStatusAction } from "../reducers/statusReducer";

type Status = "PENDING" | "ERROR" | "SUCCESS";

export const setLoadingSearchPageQuestionsStatus = (
  loadingSearchPageQuestionsStatus: Status
): SetLoadingSearchPageQuestionsStatusAction => ({
  type: "SET_LOADING_SEARCH_PAGE_QUESTIONS_STATUS",
  loadingSearchPageQuestionsStatus,
});
