import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchSearchPageQuestions } from "../../actions/fetchSearchPageQuestions";

import { HomepageBinaryQuestions } from "./HomepageBinaryQuestions";
import { HomepageFeaturedSnapshots } from "./HomepageFeaturedSnapshots";
import { HomepageMetaculusQuestionsContainer } from "./HomepageMetaculusQuestionsContainer";
import { HomepagePredictItTab } from "./HomepagePredictItTab";

const TabLabel = ({ children }) => (
  <span
    style={{
      fontSize: "16px",
      width: "200px",
    }}
  >
    {children}
  </span>
);

export const HomepageTabs = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch search page questions here
  // So they are already loaded into the Redux state
  // even before HomepageMetaculusQuestionsContainer mounts
  useEffect(() => {
    dispatch(fetchSearchPageQuestions());
  }, []);

  return (
    <div
      style={{
        fontSize: "16px",
        marginTop: "100px",
        paddingTop: "35px",
        textAlign: "left",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "50px" }}
      >
        <Tab label={<TabLabel>Binary Questions</TabLabel>} />
        <Tab label={<TabLabel>Featured Snapshots</TabLabel>} />
        <Tab label={<TabLabel>Metaculus</TabLabel>} />
        <Tab label={<TabLabel>PredictIt Notifications</TabLabel>} />
      </Tabs>
      {value === 0 ? (
        <HomepageBinaryQuestions />
      ) : value === 1 ? (
        <HomepageFeaturedSnapshots />
      ) : value === 2 ? (
        <HomepageMetaculusQuestionsContainer />
      ) : (
        <HomepagePredictItTab />
      )}
    </div>
  );
};
