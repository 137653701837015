import React from "react";
import { useSelector } from "react-redux";

import { IntervalBelief } from "../../reducers/beliefsReducer";
import { formatPoint, formatPercent } from "./utils";
import { RootState } from "../../reducers/rootReducer";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";
import { getForecastNameForImpliedBeliefsSelector } from "../../selectors/getForecastNameForImpliedBeliefsSelector";

const getPointString = (
  max: number,
  isDateQuestion: boolean,
  questionLow: number,
  questionHigh: number
) => {
  if (max === questionLow) {
    return `below ${formatPoint(max, isDateQuestion)}`;
  }

  if (max === null) {
    return `above ${formatPoint(questionHigh, isDateQuestion)}`;
  }

  return formatPoint(max, isDateQuestion);
};

const PercentileBeliefDisplayInfo = ({
  belief,
  isDateQuestion,
  questionLow,
  questionHigh,
}: {
  belief: IntervalBelief;
  isDateQuestion: boolean;
  questionLow: number;
  questionHigh: number;
}) => {
  const values = belief.values;

  const percentile = formatPercent(values.p);

  if (values.min !== null) {
    throw new Error(`Percentile belief min was ${values.min}, should be null.`);
  }

  const pointString = getPointString(
    values.max,
    isDateQuestion,
    questionLow,
    questionHigh
  );

  return {
    id: belief.id,
    percentile,
    pointString,
  };
};

export const PercentileBeliefsDisplay = ({
  beliefs,
}: {
  beliefs: IntervalBelief[];
}) => {
  const isDateQuestion = useSelector(isDateQuestionSelector);

  const selectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );

  const questionLow = useSelector(
    (state: RootState) => state.question.metadata.graphScale.low
  );

  const questionHigh = useSelector(
    (state: RootState) => state.question.metadata.graphScale.high
  );

  const displayInfo = beliefs.map((belief) => {
    return PercentileBeliefDisplayInfo({
      belief,
      isDateQuestion,
      questionLow,
      questionHigh,
    });
  });

  return (
    <>
      <div style={{ marginBottom: "10px" }}>Percentiles:</div>
      <table>
        <tbody>
          <tr>
            {displayInfo.map((el) => (
              <td
                style={{
                  padding: "10px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                }}
                key={el.id}
              >
                {el.percentile}th
              </td>
            ))}
          </tr>
          <tr>
            {displayInfo.map((el) => (
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
                key={el.id}
              >
                <strong>{el.pointString}</strong>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
