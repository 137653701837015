import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { associateUserWithSessionSnapshots } from "../actions/associateUserWithSessionSnapshots";
import { replaceState } from "../actions/replaceState";

export const OnLoginRedirect = () => {
  // This logic must be its own component nested inside <AuthProvider>,
  // as opposed to being contained directly in <AuthProvider>,
  // because useAuth0() only works in <AuthProvider> children,
  // not in <AuthProvider> itself.
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const saveAnySessionSnapshots = async () => {
      // accessToken should never be undefined, because
      // this function is only called if user evaluates to truthy
      const accessToken = user ? await getAccessTokenSilently() : undefined;
      dispatch(associateUserWithSessionSnapshots(accessToken));
    };

    if (user) {
      saveAnySessionSnapshots();
    }
  }, [user]);

  return null;
};

export const AuthProvider = ({ children }: any) => {
  const [hasRedirectedAfterLogin, setHasRedirectedAfterLogin] = useState(false);

  const dispatch = useDispatch();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    // Reload state after redirect
    const savedState = localStorage.getItem("elicit-login-state");
    if (savedState) {
      dispatch(replaceState(JSON.parse(savedState)));
      setHasRedirectedAfterLogin(true);
      localStorage.removeItem("elicit-login-state");
    }

    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      audience={audience}
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/login`}
      onRedirectCallback={onRedirectCallback}
    >
      {hasRedirectedAfterLogin && <OnLoginRedirect />}
      {children}
    </Auth0Provider>
  );
};
