import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk, RootState } from "../reducers/rootReducer";

export function checkForecastForFit({
  onPending,
  onSuccess,
  onError,
}: {
  onPending: () => void;
  onSuccess: (data: any) => void;
  onError: (response?: any) => void;
}): AppThunk {
  return async function(dispatch, getState) {
    const state: RootState = getState();

    const userForecast = state.forecasts.find((f) => f.name === "user");
    if (!userForecast) {
      throw Error("No user forecast found");
    }

    const beliefs = [
      ...userForecast.intervalBeliefIds,
      ...userForecast.nonIntervalBeliefIds,
    ].map((bId) => state.beliefs.find((b) => b.id === bId));

    const metadata = state.question.metadata;
    const distribution = state.distributions.find((d) => d.name === "user");

    onPending();

    try {
      const response = await makeRequestToBackend({
        path: "/check_submission",
        method: "POST",
        body: {
          beliefs,
          metadata,
          distribution,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        Sentry.captureException(data.error);
        return onError(response);
      }

      onSuccess(data);
    } catch (e) {
      Sentry.captureException(e);
      return onError();
    }
  };
}
