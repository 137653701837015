export interface BinaryPrediction {
  id: string;
  binaryQuestionId: string;
  createdAt: string;
  prediction: number;
  isYourPrediction: boolean;
  creator: {
    id: string;
    displayName: string;
  };
  wasJustAdded?: boolean;
}

export interface ReplaceBinaryPredictionsAction {
  type: "REPLACE_BINARY_PREDICTIONS";
  binaryPredictions: any;
}

export interface AddBinaryPredictionAction {
  type: "ADD_BINARY_PREDICTION";
  binaryPrediction: any;
}

export interface UpdateBinaryPredictionNotesAction {
  type: "UPDATE_BINARY_PREDICTION_NOTES";
  binaryPredictionId: string;
  notes: string;
}

const initialState = [] as BinaryPrediction[];

type BinaryPredictionAction =
  | ReplaceBinaryPredictionsAction
  | AddBinaryPredictionAction
  | UpdateBinaryPredictionNotesAction;

export function binaryPredictionsReducer(
  state = initialState,
  action: BinaryPredictionAction
): BinaryPrediction[] {
  if (action.type === "REPLACE_BINARY_PREDICTIONS") {
    return action.binaryPredictions;
  }

  if (action.type === "ADD_BINARY_PREDICTION") {
    return [
      { ...action.binaryPrediction, wasJustAdded: true },
      ...state.map((p) => ({ ...p, wasJustAdded: false })),
    ];
  }

  if (action.type === "UPDATE_BINARY_PREDICTION_NOTES") {
    return state.map((prediction) => {
      if (prediction.id !== action.binaryPredictionId) {
        return prediction;
      }

      return { ...prediction, notes: action.notes };
    });
  }

  return state;
}
