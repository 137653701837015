import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function createBinaryQuestion({
  accessToken,
  fields,
  onSuccess,
}: {
  accessToken: string;
  fields: {
    title: string;
    resolvesBy: number;
    privacyStatus: "PUBLIC" | "PRIVATE";
    notes: string;
    prediction?: {
      prediction: number;
    };
  };
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function (dispatch) {
    let data;
    try {
      const response = await makeRequestToBackend({
        path: "/binary-questions",
        method: "POST",
        body: fields,
        accessToken,
      });

      data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }

    onSuccess(data);
  };
}
