import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function fetchBinaryQuestions({
  accessToken,
  onSuccess,
}: {
  accessToken: string;
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function(dispatch) {
    let data;
    try {
      const response = await makeRequestToBackend({
        path: `/binary-questions/${window.location.search}&expand=binaryPredictions`,
        method: "GET",
        accessToken,
      });

      data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }

    onSuccess(data);
  };
}
