import { RootState } from "../reducers/rootReducer";

import { isLowerOutOfBoundsBelief } from "./isLowerOutOfBoundsBelief";
import { displayedBeliefsSelector } from "./displayedBeliefsSelector";

export const isFirstLowerOutOfBoundsBeliefSelector = (beliefId: string) => (
  state: RootState
) => {
  const beliefs = displayedBeliefsSelector({
    interval: true,
    nonInterval: false,
  })(state);

  const lowerOutOfBoundsBeliefs = beliefs.filter((b) =>
    isLowerOutOfBoundsBelief(b.id)(state)
  );

  if (
    lowerOutOfBoundsBeliefs[0] &&
    lowerOutOfBoundsBeliefs[0].id === beliefId
  ) {
    return true;
  }

  return false;
};
