import { SetBinaryQuestionTextAction } from "../reducers/statusReducer";

export function setBinaryQuestionText(
  binaryQuestionText: string
): SetBinaryQuestionTextAction {
  return {
    type: "SET_BINARY_QUESTION_TEXT",
    binaryQuestionText,
  };
}
