import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import React, { useState } from "react";

const carouselImgs = [
  {
    src: "carousel-1",
    title: "When will we get AGI?",
    href:
      "/builder/DqKwcp0Jt?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
  {
    src: "carousel-2",
    title: "How many Electoral College votes will Dems win in 2020?",
    href:
      "/builder/2pi1y3ZzQ?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
  {
    src: "carousel-3",
    title: "How many months will I stay in my current job?",
    href:
      "/builder/DL9UPW0bf?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
  {
    src: "carousel-4",
    title: "When will humanity face existential catastrophe?",
    href:
      "/builder/9sU3GOuvG?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
  {
    src: "carousel-5",
    title:
      "How many days will it take Ought to build reference lines for Elicit distributions?",
    href:
      "/builder/zkPmHb_PI?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
  {
    src: "carousel-6",
    title: "What will TSLA be worth in the future (USD)?",
    href:
      "/builder/WzC-aedgD?utm_source=homepage&utm_medium=website&utm_campaign=featured_snap",
  },
];

const SLIDES_PER_PAGE = 2;

export const HomepageFeaturedSnapshots = () => {
  const [carouselValue, setCarouselValue] = useState(0);

  return (
    <Carousel
      arrows
      value={carouselValue}
      onChange={(i) => {
        if (!(i > carouselImgs.length - SLIDES_PER_PAGE)) {
          setCarouselValue(i);
        }
      }}
      slidesPerPage={SLIDES_PER_PAGE}
      slidesPerScroll={1}
      animationSpeed={250}
      offset={40}
      itemWidth={320}
    >
      {carouselImgs.map((data, i) => (
        <div
          key={i}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "100px",
          }}
        >
          <a href={data.href}>
            <img src={`images/${data.src}.png`} style={{ width: "320px" }} />
          </a>
          <div
            style={{
              height: "10px",
              overflowY: "visible",
              textAlign: "center",
            }}
          >
            <a href={data.href}>{data.title}</a>
          </div>
        </div>
      ))}
    </Carousel>
  );
};
