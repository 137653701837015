import { v4 as uuidv4 } from "uuid";

export function replaceSnapshotIds(state) {
  // copies ids to avoid duplication when imported
  const idMap = {};

  const distributions = state.distributions.map((d) => {
    idMap[d.id] = uuidv4();
    return {
      ...d,
      id: idMap[d.id],
    };
  });

  const beliefs = state.beliefs.map((b) => {
    idMap[b.id] = uuidv4();
    return {
      ...b,
      id: idMap[b.id],
      comparisonId: b.comparisonId && idMap[b.comparisonId],
    };
  });

  const beliefsTable = {
    ...state.beliefsTable,
    hoveringOverBelief:
      state.beliefsTable.hoveringOverBelief &&
      idMap[state.beliefsTable.hoveringOverBelief],
  };

  const beliefFits = {};
  for (const [beliefId, beliefFit] of Object.entries(state.beliefFits)) {
    const newId = idMap[beliefId];
    beliefFits[newId] = {};
    for (const [distributionId, fitDescription] of Object.entries(beliefFit)) {
      beliefFits[newId][idMap[distributionId]] = fitDescription;
    }
  }

  const forecasts = state.forecasts.map((f) => {
    idMap[f.id] = uuidv4();
    return {
      ...f,
      id: idMap[f.id],
      distributionId: idMap[f.distributionId],
      intervalBeliefIds: f.intervalBeliefIds.map((id) => idMap[id]),
      nonIntervalBeliefIds: f.nonIntervalBeliefIds.map((id) => idMap[id]),
      impliedBeliefIds: f.impliedBeliefIds.map((id) => idMap[id]),
    };
  });

  return {
    ...state,
    beliefs: beliefs,
    beliefsTable: beliefsTable,
    distributions: distributions,
    beliefFits: beliefFits,
    forecasts: forecasts,
    status: {
      ...state.status,
      selectedForecastId: idMap[state.status.selectedForecastId],
    },
  };
}
