import isNil from "lodash.isnil";
import { stringToDate } from "../stringToDate";
import { stringToNumber } from "../stringToNumber";

import { canStringBeConvertedIntoDate } from "../canStringBeConvertedIntoDate";
import { canStringBeConvertedIntoNumber } from "../canStringBeConvertedIntoNumber";

export interface ErrorDescription {
  lowErrMsg?: string;
  highErrMsg?: string;
}

const validateCustomQuestion = ({
  low,
  high,
  isDateQuestion,
  isLogQuestion,
}: {
  low: string;
  high: string;
  isDateQuestion: boolean;
  isLogQuestion: boolean;
}) => ({
  lowErrMsg: validateCustomQuestionBound({
    low,
    high,
    boundType: "low",
    isDateQuestion,
    isLogQuestion,
  }),
  highErrMsg: validateCustomQuestionBound({
    low,
    high,
    boundType: "high",
    isDateQuestion,
    isLogQuestion,
  }),
});

const validateCustomQuestionBound = (args: {
  low: string;
  high: string;
  boundType: "low" | "high";
  isDateQuestion: boolean;
  isLogQuestion: boolean;
}): string | null => {
  const { low, high, boundType, isDateQuestion, isLogQuestion } = args;
  const bound = args[boundType];

  if (isNil(bound) || bound === "") {
    return "must have a value";
  }

  if (!isDateQuestion) {
    if (!canStringBeConvertedIntoNumber(bound)) {
      return "must be a number";
    }

    if (boundType === "low" && isLogQuestion && stringToNumber(low) <= 0) {
      return "can't <= 0 if log";
    }
  }

  if (isDateQuestion) {
    if (!canStringBeConvertedIntoDate(bound)) {
      return "not a date";
    }
  }

  if (
    boundType === "high" &&
    (isDateQuestion
      ? stringToDate(high) <= stringToDate(low)
      : stringToNumber(high) <= stringToNumber(low))
  ) {
    return "<= min";
  }

  if (
    boundType === "low" &&
    (isDateQuestion
      ? stringToDate(low) >= stringToDate(high)
      : stringToNumber(low) >= stringToNumber(high))
  ) {
    return ">= max";
  }

  return null;
};

export const isCustomQuestionValid = ({
  low,
  high,
  isDateQuestion,
  isLogQuestion,
}): [boolean, ErrorDescription] => {
  const { lowErrMsg, highErrMsg } = validateCustomQuestion({
    low,
    high,
    isDateQuestion,
    isLogQuestion,
  });

  const isValid = !lowErrMsg && !highErrMsg;

  return [isValid, { lowErrMsg, highErrMsg }];
};
