import { SetShouldShowStartArrowAction } from "../reducers/statusReducer";

export function setShouldShowStartArrow(
  shouldShowStartArrow: boolean
): SetShouldShowStartArrowAction {
  return {
    type: "SET_SHOULD_SHOW_START_ARROW",
    shouldShowStartArrow,
  };
}
