export type User = {
  apiKey?: string;
  displayName: string;
  email: string;
  familyName?: string;
  givenName?: string;
  metaculusAPIKeys?: { [apiDomain: string]: string };
  metaculusUsername: string;
  sub: string;
};

export interface UserLoginAction {
  type: "USER_LOGIN";
  user: User;
}

interface UserLogoutAction {
  type: "USER_LOGOUT";
}

export interface UpdateUserAction {
  type: "UPDATE_USER";
  field: string;
  value: any;
}

export interface UpdateUserMetaculusApiKeyAction {
  type: "UPDATE_USER_METACULUS_API_KEY";
  apiDomain: string;
  key: string;
}

interface ResetState {
  type: "RESET_STATE";
}

type UserAction =
  | UserLoginAction
  | UserLogoutAction
  | UpdateUserAction
  | UpdateUserMetaculusApiKeyAction
  | ResetState;

export const initialState = null;

export function userReducer(
  state: User | null = initialState,
  action: UserAction
): User | null {
  if (action.type === "USER_LOGIN") {
    return action.user;
  }

  if (action.type === "USER_LOGOUT") {
    return null;
  }

  if (action.type === "UPDATE_USER") {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  if (action.type === "UPDATE_USER_METACULUS_API_KEY") {
    return {
      ...state,
      metaculusAPIKeys: {
        ...state.metaculusAPIKeys,
        [action.apiDomain]: action.key,
      },
    };
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
