import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getForecastLabelColorFunctionSelector } from "../selectors/getForecastLabelColorFunctionSelector";
import { getForecastNameForDisplayFunctionSelector } from "../selectors/getForecastNameForDisplayFunctionSelector";

export const ForecastSelectorDropdown = ({
  selectedForecast,
  selectableForecasts,
  setSelectedForecast,
}) => {
  const getForecastNameForDisplayFunction = useSelector(
    getForecastNameForDisplayFunctionSelector
  );

  const getForecastLabelColorFunction = useSelector(
    getForecastLabelColorFunctionSelector
  );

  return (
    <DropdownButton
      alignLeft
      id="forecast-selector-dropdown"
      style={{ display: "inline-block" }}
      title={
        selectedForecast && (
          <span
            style={{
              color: getForecastLabelColorFunction(selectedForecast.id),
              display: "inline-block",
              width: "160px",
              marginBottom: "-5px",
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
            }}
          >
            {getForecastNameForDisplayFunction(selectedForecast.id)}
          </span>
        )
      }
    >
      {selectableForecasts
        .filter((f) => f.id !== selectedForecast.id)
        .map((f) => (
          <Dropdown.Item key={f.id} onClick={() => setSelectedForecast(f)}>
            <span
              style={{
                color: getForecastLabelColorFunction(f.id),
              }}
            >
              {getForecastNameForDisplayFunction(f.id)}
            </span>
          </Dropdown.Item>
        ))}
    </DropdownButton>
  );
};
