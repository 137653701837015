import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Question {
  id: number;
  title: string;
}

const SpanWithLinkStyling = styled.span`
  color: #007bff;
  cursor: pointer;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const formatQuestion = (question: Question) => (
  <div style={{ marginTop: "10px" }} key={question.id}>
    <Link to={`/builder?q_id=${question.id}`}>{question.title}</Link>
  </div>
);

export const HomepageMetaculusQuestions = ({ isLoading, questions }) => {
  const [hasAllQuestionsVisible, setAllQuestionsVisible] = useState(false);

  const questionsToDisplay = hasAllQuestionsVisible
    ? questions
    : questions.slice(0, 10);

  return (
    <div>
      To work on a Metaculus question, enter a Metaculus question URL above or
      select from one of the below (soonest closing first):
      {isLoading && (
        <div style={{ color: "#888", marginTop: "10px" }}>
          Loading eligible Metaculus questions{" "}
          <Spinner
            as="span"
            animation="grow"
            role="status"
            size="sm"
            aria-hidden="true"
          />
        </div>
      )}
      {!isLoading && questionsToDisplay.map(formatQuestion)}
      {questions.length > questionsToDisplay.length && (
        <div style={{ marginTop: "10px" }}>
          <SpanWithLinkStyling onClick={() => setAllQuestionsVisible(true)}>
            More ▾
          </SpanWithLinkStyling>
        </div>
      )}
    </div>
  );
};
