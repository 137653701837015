export function formatDistNameForLegend(name) {
  if (name === "user") {
    return "Your forecast";
  }

  if (name === "community") {
    return "Metaculus community";
  }

  if (name === "metaculusSubmission") {
    return "Metaculus submission";
  }

  return name;
}
