import { RootState } from "../reducers/rootReducer";

export const getDistributionsWithSelectedFirstSelector = (state: RootState) => {
  const selectedForecastId = state.status.selectedForecastId;
  const selectedForecast = state.forecasts.find(
    (f) => f.id === selectedForecastId
  );
  const selectedDistribution = state.distributions.find(
    (d) => d.id === selectedForecast.distributionId
  );
  const otherDistributions = state.distributions.filter(
    (d) => d.id !== selectedForecast.distributionId
  );
  return [selectedDistribution].concat(otherDistributions);
};
