import * as Sentry from "@sentry/browser";
import { createForecast } from "./createForecast";
import { setLoadingBeliefsStatus } from "./setLoadingBeliefsStatus";
import { selectForecast } from "./selectForecast";
import { v4 as uuidv4 } from "uuid";

import { distributionFromFramedHistogram } from "../helpers/distributionFromFramedHistogram";
import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { QuestionMetadata } from "../reducers/questionReducer";
import { AppThunk } from "../reducers/rootReducer";

export function fetchStartingForecasts(metadata: QuestionMetadata): AppThunk {
  return async function (dispatch) {
    dispatch(setLoadingBeliefsStatus("PENDING"));

    let data;
    try {
      const response = await makeRequestToBackend({
        path: "/starting_forecasts",
        method: "POST",
        body: {
          metadata,
        },
      });

      data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      dispatch(setLoadingBeliefsStatus("ERROR"));
      return;
    }

    let foundEditableForecast = false;

    data.forEach((forecast: any) => {
      const distribution =
        forecast.framedHistogram &&
        distributionFromFramedHistogram({
          framedHistogram: forecast.framedHistogram,
          id: forecast.framedHistogram.id || uuidv4(),
        });

      const forecastId = forecast.id || uuidv4();

      dispatch(
        createForecast({
          id: forecastId,
          name: forecast.name,
          type: forecast.type,
          beliefs: forecast.beliefs,
          distribution: distribution,
        })
      );

      // selected forecast is first editable forecast
      if (forecast.type === "editable" && !foundEditableForecast) {
        foundEditableForecast = true;
        dispatch(selectForecast(forecastId));
      }
    });

    dispatch(setLoadingBeliefsStatus("SUCCESS"));
  };
}
