import { v4 as uuidv4 } from "uuid";

import { setTabScreen } from "../actions/setTabScreen";

import { AppThunk } from "../reducers/rootReducer";

import { isLowerOutOfBoundsBelief } from "../selectors/isLowerOutOfBoundsBelief";
import { isUpperOutOfBoundsBelief } from "../selectors/isUpperOutOfBoundsBelief";

export const addOutOfBoundsIntervalBelief = (
  boundsType: "OVER" | "UNDER"
): AppThunk => (dispatch, getState) => {
  const state = getState();

  if (!state.question.metadata) {
    return;
  }

  if (state.status.tabScreen !== "BUILD") {
    dispatch(setTabScreen("BUILD"));
  }

  const selectedForecastId = state.status.selectedForecastId;

  const selectedForecast = state.forecasts.find(
    (f) => f.id === selectedForecastId
  );

  const selectedForecastIntervalBeliefs = state.beliefs.filter((b) =>
    selectedForecast.intervalBeliefIds.includes(b.id)
  );

  const doesAlreadyExist = selectedForecastIntervalBeliefs.some(
    (b) =>
      (boundsType === "UNDER" && isLowerOutOfBoundsBelief(b.id)(state)) ||
      (boundsType === "OVER" && isUpperOutOfBoundsBelief(b.id)(state))
  );

  if (doesAlreadyExist) {
    dispatch({
      type: "FOCUS_OUT_OF_BOUNDS_INTERVAL_BELIEF",
      boundsType,
    });
    return;
  }

  dispatch({
    type: "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF",
    beliefId: uuidv4(),
    min:
      boundsType === "OVER" ? state.question.metadata.graphScale?.high : null,
    max:
      boundsType === "UNDER" ? state.question.metadata.graphScale?.low : null,
    boundsType,
  });
};
