import { UpdateBinaryPredictionNotesAction } from "../reducers/binaryPredictionsReducer";

export function updateBinaryPredictionNotesOnFrontend({
  binaryPredictionId,
  notes,
}: {
  binaryPredictionId: string;
  notes: string;
}): UpdateBinaryPredictionNotesAction {
  return {
    type: "UPDATE_BINARY_PREDICTION_NOTES",
    binaryPredictionId,
    notes,
  };
}
