import uniqBy from "lodash.uniqby";

import { sliceSelector, ParentState } from "../dependencies/sliceSelector";

export const predictorsOtherThanYouSelector = (parentState: ParentState) => {
  const state = sliceSelector(parentState);
  const predictions = state.binaryPredictions;
  const predictors = predictions
    .filter((p) => !p.isYourPrediction)
    .map((p) => p.creator);
  const users = uniqBy(predictors, (u) => u.id);

  return users;
};
