import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";

import { beliefsReducer, Belief } from "./beliefsReducer";
import { beliefsTableReducer, BeliefsTable } from "./beliefsTableReducer";
import { beliefFitsReducer, BeliefFits } from "./beliefFitsReducer";
import binaryQuestionsReducer, {
  RootState as BinaryQuestionsRootState,
} from "../features/binaryQuestions/reducers/rootReducer";
import { distributionsReducer, Distribution } from "./distributionsReducer";
import { forecastsReducer, Forecast } from "./forecastsReducer";
import { hydratedReducer } from "./hydratedReducer";
import { questionReducer, QuestionState } from "./questionReducer";
import {
  sessionSnapshotRecordsReducer,
  SnapshotRecord,
} from "./sessionSnapshotRecordsReducer";
import {
  searchPageQuestionsReducer,
  SearchPageQuestion,
} from "./searchPageQuestionsReducer";
import { statusReducer, StatusState } from "./statusReducer";
import {
  showSubmissionReducer,
  ShowSubmissionState,
} from "./showSubmissionReducer";
import { userReducer, User } from "./userReducer";

// TODO: add types for the rest of the reducers
export interface RootState {
  beliefs: Belief[];
  beliefsTable: BeliefsTable;
  beliefFits: BeliefFits;
  binaryQuestions: BinaryQuestionsRootState;
  distributions: Distribution[];
  forecasts: Forecast[];
  hydrated: boolean;
  question: QuestionState | null;
  sessionSnapshotRecords: SnapshotRecord[];
  searchPageQuestions: SearchPageQuestion[];
  status: StatusState;
  showSubmission: ShowSubmissionState;
  user: User | null;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const rootReducerWithoutReplace = combineReducers<RootState>({
  beliefs: beliefsReducer,
  beliefsTable: beliefsTableReducer,
  beliefFits: beliefFitsReducer,
  binaryQuestions: binaryQuestionsReducer,
  distributions: distributionsReducer,
  forecasts: forecastsReducer,
  hydrated: hydratedReducer,
  question: questionReducer,
  sessionSnapshotRecords: sessionSnapshotRecordsReducer,
  searchPageQuestions: searchPageQuestionsReducer,
  status: statusReducer,
  showSubmission: showSubmissionReducer,
  user: userReducer,
});

export const rootReducer = (state: RootState, action: any): RootState => {
  // Replaces all state except user info.
  // Currently only used to reload state
  // after successful login redirect.
  if (action.type === "REPLACE_STATE") {
    return { ...action.state, user: state.user };
  }

  return rootReducerWithoutReplace(state, action);
};
