import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function associateUserWithSnapshot({
  accessToken,
  key,
}: {
  accessToken: string;
  key: string;
}): AppThunk {
  return async function (dispatch, getState) {
    try {
      if (!accessToken) {
        // Must be logged in to use this API endpoint
        return;
      }

      await makeRequestToBackend({
        path: `/snapshots/${key}/users`,
        method: "POST",
        accessToken,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
