import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSnapshots } from "../actions/fetchSnapshots";

export const SnapshotsViewer = () => {
  const [snapshots, setSnapshots] = useState(null);

  const [hasFetchedSnapshots, setHasFetchedSnapshots] = useState(false);

  const { getAccessTokenSilently, isLoading: isLoadingAuth, user } = useAuth0();

  const dispatch = useDispatch();

  useEffect(() => {
    // We need a user to get access token
    // And we need token to fetch a user's snapshots
    if (!user) {
      return;
    }

    const fetchSnapshotsFn = async () => {
      const accessToken = user ? await getAccessTokenSilently() : undefined;

      dispatch(
        fetchSnapshots({
          accessToken,
          onSuccess: (snapshots) => {
            // Sort reverse chronological
            snapshots.sort((a, b) =>
              new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
            );
            setSnapshots(snapshots);
          },
        })
      );
    };

    // Only fetch snapshots once
    if (!hasFetchedSnapshots) {
      fetchSnapshotsFn();
      setHasFetchedSnapshots(true);
    }
  }, [hasFetchedSnapshots, user]);

  // If no server has responded with a (possibly empty)
  // array of snapshots
  if (snapshots !== null) {
    return (
      <div
        style={{
          minHeight: "100%",
          width: "800px",
          margin: "0 auto 30px",
          padding: "40px",
          textAlign: "left",
        }}
      >
        <h5 style={{ textAlign: "center" }}>Your Snapshots</h5>
        {snapshots.length > 0
          ? // If array isn't empty, render snapshot links
            snapshots.map((snapshot) => {
              return (
                <div key={snapshot.id} style={{ marginBottom: "20px" }}>
                  {moment(snapshot.createdAt).format("l - h:mma")} <br />
                  <a href={`${window.location.origin}/builder/${snapshot.id}`}>
                    {(snapshot.state.question &&
                      snapshot.state.question.title) ||
                      snapshot.state.forecast.title}
                  </a>
                </div>
              );
            })
          : // If array is empty, display message
            "No saved snapshots yet"}
      </div>
    );
  }

  // If auth has finished loading, but there is no user
  if (!isLoadingAuth && !user) {
    return (
      <div
        style={{
          minHeight: "100%",
          width: "1400px",
          margin: "0 auto 30px",
          padding: "40px",
          textAlign: "center",
        }}
      >
        <h5>Please login to view your snapshots</h5>
      </div>
    );
  }

  // If we're waiting for auth to load or
  // auth has loaded, there is a user, but
  // we haven't yet received a response from
  // the server.
  return (
    <div
      style={{
        minHeight: "100%",
        width: "800px",
        margin: "0 auto 30px",
        padding: "40px",
        textAlign: "left",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Your Snapshots</h5>
      {!snapshots && "Loading snapshots..."}
    </div>
  );
};
