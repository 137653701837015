import { useAuth0 } from "@auth0/auth0-react";
import isNil from "lodash.isnil";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { createCustomQuestion } from "../actions/createCustomQuestion";
import { navigateToCustomQuestion } from "../actions/navigateToCustomQuestion";

import {
  ErrorDescription,
  isCustomQuestionValid,
} from "../helpers/customQuestions/isCustomQuestionValid";

import { formatDateString } from "../helpers/formatDateString";
import { formatNumberString } from "../helpers/formatNumberString";

export const NewQuestionForm = withRouter((props: any) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  const shouldStartAsDate =
    props.questionInput?.slice(0, 4).toLowerCase() === "when";

  const [questionText, setQuestionText] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [isLogQuestion, setIsLogQuestion] = useState(false);
  const [isDateQuestion, setIsDateQuestion] = useState(shouldStartAsDate);

  const [lowOpen, setLowOpen] = useState(true);
  const [highOpen, setHighOpen] = useState(true);

  const [errorDescription, setErrorDescription] = useState(
    {} as ErrorDescription
  );

  const lowPlaceholder = isDateQuestion
    ? "2021-01-01"
    : isLogQuestion
    ? "1"
    : "0";

  const highPlaceholder = isDateQuestion
    ? "2100-01-01"
    : isLogQuestion
    ? "10,000"
    : "1,000";

  const [
    hasTriedToCreateCustomQuestion,
    setHasTriedToCreateCustomQuestion,
  ] = useState(false);

  const [
    isCustomQuestionCreationPending,
    setIsCustomQuestionCreationPending,
  ] = useState(false);

  const handleCreateCustomQuestion = async () => {
    setIsCustomQuestionCreationPending(true);

    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      createCustomQuestion({
        questionText: props.questionInput || questionText,
        low,
        lowOpen,
        high,
        highOpen,
        isLogQuestion,
        isDateQuestion,
        accessToken,
        onSuccess: (id) => {
          dispatch(
            navigateToCustomQuestion({
              customQuestionId: id,
              history: props.history,
            })
          );
        },
        onError: (errorDescription) => {
          setErrorDescription(errorDescription);
          setHasTriedToCreateCustomQuestion(true);
          setIsCustomQuestionCreationPending(false);
        },
      })
    );
  };

  useEffect(() => {
    // Only start validating if user has tried to
    // create a question
    if (!hasTriedToCreateCustomQuestion) {
      return;
    }

    const [isValid, errorDescription] = isCustomQuestionValid({
      low,
      high,
      isLogQuestion,
      isDateQuestion,
    });

    if (isValid) {
      // If valid clear all error messages
      setErrorDescription({});
    } else {
      // If invalid update error messages
      setErrorDescription(errorDescription);
    }
  }, [
    low,
    high,
    isLogQuestion,
    isDateQuestion,
    hasTriedToCreateCustomQuestion,
  ]);

  const formatLow = () => {
    if (!isDateQuestion) {
      setLow(formatNumberString(low));
    } else {
      setLow(formatDateString(low));
    }
  };

  const formatHigh = () => {
    if (!isDateQuestion) {
      setHigh(formatNumberString(high));
    } else {
      setHigh(formatDateString(high));
    }
  };

  useEffect(() => {
    formatLow();
    formatHigh();
  }, [isDateQuestion, isLogQuestion]);

  return (
    <div style={{ marginTop: "30px", height: "200px" }}>
      {isNil(props.questionInput) && (
        <TextField
          id="standard-basic"
          placeholder="question text"
          fullWidth
          onChange={(e) => {
            setQuestionText(e.target.value);
          }}
          value={questionText}
          style={{ marginBottom: "30px" }}
        />
      )}
      <div>
        {" "}
        The answer to this question is a
        <Form inline style={{ display: "inline-block", margin: "0 10px" }}>
          <Form.Control
            size="sm"
            as="select"
            onChange={(e) => {
              if (e.target.value === "date") {
                setIsDateQuestion(true);
              } else {
                setIsDateQuestion(false);
              }
            }}
            value={isDateQuestion ? "date" : "number"}
          >
            <option>number</option>
            <option>date</option>
          </Form.Control>
        </Form>
        {!isDateQuestion && (
          <span>
            on a
            <Form inline style={{ display: "inline-block", margin: "0 10px" }}>
              <Form.Control
                size="sm"
                as="select"
                onChange={(e) => {
                  if (e.target.value === "log") {
                    setIsLogQuestion(true);
                  } else {
                    setIsLogQuestion(false);
                  }
                }}
                value={isLogQuestion ? "log" : "linear"}
              >
                <option>linear</option>
                <option>log</option>
              </Form.Control>
            </Form>{" "}
            scale
          </span>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>It's almost certainly between:</div>
      <div style={{ marginTop: "20px" }}>
        <TextField
          id="standard-basic"
          error={!!errorDescription.lowErrMsg}
          onBlur={formatLow}
          placeholder={`e.g. ${lowPlaceholder}`}
          fullWidth
          onChange={(e) => {
            setLow(e.target.value);
          }}
          value={low}
          style={{ marginRight: "10px", width: "200px" }}
          helperText={errorDescription.lowErrMsg}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={lowOpen}
              onChange={(e) => {
                setLowOpen(!lowOpen);
              }}
              name="checkedL"
              color="primary"
            />
          }
          label={`It could be below ${low || lowPlaceholder}`}
          style={{
            marginLeft: "20px",
            position: "relative",
            top: "13px",
          }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextField
          id="standard-basic"
          error={!!errorDescription.highErrMsg}
          placeholder={`e.g. ${highPlaceholder}`}
          fullWidth
          onChange={(e) => {
            setHigh(e.target.value);
          }}
          onBlur={formatHigh}
          value={high}
          style={{ marginRight: "10px", width: "200px" }}
          helperText={errorDescription.highErrMsg}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={highOpen}
              onChange={(e) => {
                setHighOpen(!highOpen);
              }}
              name="checkedH"
              color="primary"
            />
          }
          label={`It could be above ${high || highPlaceholder}`}
          style={{
            marginLeft: "20px",
            position: "relative",
            top: "13px",
          }}
        />
      </div>
      <Button
        disabled={isCustomQuestionCreationPending}
        style={{ marginTop: "30px" }}
        onClick={handleCreateCustomQuestion}
      >
        Create
      </Button>
    </div>
  );
});
