import { DeleteBeliefAction } from "../reducers/beliefsReducer";

// This can be the same as delete belief for now.
//
// In the future we will likely need to distinguish
// between deleting a belief from a forecast, which
// should have backend consequences, and merely removing
// a belief from the frontend Redux state. One situation
// in which this latter thing would occur is when we
// remove a forecast from the frontend and want to
// remove the associated beliefs as well.
export const removeBelief = (id: string): DeleteBeliefAction => ({
  type: "DELETE_BELIEF",
  beliefId: id,
});
