import React from "react";

export function PrivacyPolicyPage() {
  return (
    <div style={{ margin: "0px auto 30px", padding: "40px", width: "1000px" }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: October 7th, 2020</p>
      <p>________________________________________</p>

      <h2>Introduction</h2>
      <p>
        Thank you for choosing to be part of our community at Ought Inc.
        (“company”, “we”, “us”, or “our”). We are committed to protecting your
        personal information and your right to privacy. If you have any
        questions or concerns about our policy, or our practices with regards to
        your personal information, please contact us at{" "}
        <a href="mailto:operations@ought.org" target="_blank">
          operations@ought.org
        </a>
        .
      </p>

      <p>
        When you visit our website{" "}
        <a href="/" target="_blank">
          forecast.elicit.org
        </a>{" "}
        (“Site”) and use our services, you trust us with your personal
        information. We take your privacy very seriously. In this privacy
        notice, we describe our privacy policy. We seek to explain to you in the
        clearest way possible what information we collect, how we use it and
        what rights you have in relation to it. We hope you take some time to
        read through it carefully, as it is important. If there are any terms in
        this privacy policy that you do not agree with, please discontinue use
        of our site and our services.
      </p>

      <p>
        This privacy policy applies to all information collected through our
        websites (such as{" "}
        <a href="/" target="_blank">
          forecast.elicit.org
        </a>
        ), and/or any related services, sales, marketing or events (we refer to
        them collectively in this privacy policy as the "Sites").
      </p>
      <p>
        Please read this privacy policy carefully as it will help you make
        informed decisions about sharing your personal information with us.
      </p>

      <h2>What Information Do We Collect? </h2>
      <p>
        <strong>Personal information you disclose to us </strong>
      </p>
      <p>
        In Short: We collect personal information that you provide to us such as
        email address and password.
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        registering on / using our site. The personal information that we
        collect depends on the context of your interactions with us and the
        Sites, the choices you make and the products and features you use. The
        personal information we COLLECT can include the following:
      </p>
      <p>Contact Data. We collect your email address.</p>

      <p>
        Credentials. We collect passwords for authentication and account access.
      </p>

      <p>
        Social Media Login Data. We provide you with the option to register
        using social media account details, like your Google account. If you
        choose to register in this way, we will collect the Information
        described in the section called "Social Logins" below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.{" "}
      </p>

      <p>
        <strong>Information automatically collected</strong>
      </p>
      <p>
        In Short: Some information – such as IP address and/or browser and
        device characteristics – is collected automatically when you visit our
        websites.{" "}
      </p>

      <p>
        We automatically collect certain information when you visit, use or
        navigate the Sites. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Site and other technical information. This information is primarily
        needed to maintain the security and operation of our Sites, and for our
        internal analytics and reporting purposes.{" "}
      </p>

      <h2>How Do We Use Your Information? </h2>
      <p>
        In Short: We process your information for purposes based on legitimate
        business interests, the fulfillment of our contract with you, compliance
        with our legal obligations, and/or your consent.
      </p>

      <p>
        We use personal information collected via our Sites for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests
        ("Business Purposes"), in order to enter into or perform a contract with
        you ("Contractual"), with your consent ("Consent"), and/or for
        compliance with our legal obligations ("Legal Reasons"). We indicate the
        specific processing grounds we rely on next to each purpose listed
        below. We use the information we collect or receive:{" "}
      </p>

      <p>
        • To facilitate engagement with the website and its content; most
        notably, creating, storing, and editing forecasts.
      </p>

      <p>
        • To facilitate account creation and logon process [with your Consent].
        If you choose to link your account with us to a third party account
        *(such as your Google account), we use the information you allowed us to
        collect from those third parties to facilitate account creation and
        logon process. See the section below headed "Social Logins" for further
        information.{" "}
      </p>

      <p>
        • Request Feedback [for our Business Purposes and/or with your Consent].
        We may use your information to request feedback and to contact you about
        your use of our Sites.{" "}
      </p>

      <p>
        • To protect our Sites [for Business Purposes and/or Legal Reasons]. We
        may use your information as part of our efforts to keep our Sites safe
        and secure (for example, for fraud monitoring and prevention).
      </p>

      <p>
        • To enforce our terms, conditions and policies [for Business Purposes,
        Legal Reasons and/or possibly Contractual].
      </p>

      <p>
        • To respond to legal requests and prevent harm [for Legal Reasons]. If
        we receive a subpoena or other legal request, we may need to inspect the
        data we hold to determine how to respond.
      </p>

      <p>
        • For other Business Purposes. We may use your information for other
        Business Purposes, such as data analysis, identifying usage trends, and
        to evaluate and improve our Sites and products.
      </p>

      <h2>Will Your Information Be Shared With Anyone? </h2>
      <p>
        In Short: We only share information to comply with laws, or to protect
        your rights.{" "}
      </p>

      <p>
        We only share and disclose your information in the following situations:
      </p>

      <p>
        • Compliance with Laws. We may disclose your information where we are
        legally required to do so in order to comply with applicable law,
        governmental requests, a judicial proceeding, court order, or legal
        process, such as in response to a court order or a subpoena (including
        in response to public authorities to meet national security or law
        enforcement requirements).
      </p>

      <p>
        • Vital Interests and Legal Rights. We may disclose your information
        where we believe it is necessary to investigate, prevent, or take action
        regarding potential violations of our policies, suspected fraud,
        situations involving potential threats to the safety of any person and
        illegal activities, or as evidence in litigation in which we are
        involved.{" "}
      </p>

      <p>
        • With your Consent. We may disclose your personal information for any
        other purpose with your consent.
      </p>

      <h2>How Do We Handle Your Social Logins?</h2>
      <p>
        In Short: If you choose to register or log in to our websites using a
        social media account, we may have access to certain information about
        you.
      </p>

      <p>
        Our Sites offers you the ability to register and login using your third
        party social media account details (like your Google login). Where you
        choose to do this, we will receive certain profile information about you
        from your social media provider. The profile Information we receive may
        vary depending on the social media provider concerned, but will often
        include your name, e-mail address, profile picture as well as other
        information you choose to make public.
      </p>

      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy policy or that are otherwise made clear to you
        on the Sites. Please note that we do not control, and are not
        responsible for, other uses of your personal information by your third
        party social media provider. We recommend that you review their privacy
        policy to understand how they collect, use and share your personal
        information, and how you can set your privacy preferences on their sites
        and apps.
      </p>

      <h2>Is Your Information Transferred Internationally? </h2>
      <p>
        In Short: We may transfer, store, and process your information in
        countries other than your own.
      </p>

      <p>
        Our servers are located in the United States. If you are accessing our
        Sites from outside the United States, please be aware that your
        information may be transferred to, stored, and processed by us in our
        facilities and by those third parties with whom we may share your
        personal information (see "Disclosure of Your Information" above) in
        other countries.{" "}
      </p>
      <p>
        If you are a resident in the European Economic Area, then these
        countries may not have data protection or other laws as comprehensive as
        those in your country. We will however take all necessary measures to
        protect your personal information in accordance with this privacy policy
        and applicable law.{" "}
      </p>

      <h2>What Is Our Stance On Third-Party Websites?</h2>
      <p>
        In Short: We are not responsible for the safety of any information that
        you share with third-party providers who are not affiliated with our
        websites.{" "}
      </p>

      <p>
        The Sites may contain links to third parties that are not affiliated
        with us and which may link to other websites, online services or mobile
        applications. We cannot guarantee the safety and privacy of data you
        provide to any third parties. Any data collected by third parties is not
        covered by this privacy policy. We are not responsible for the content
        or privacy and security practices and policies of any third parties,
        including other websites, services or applications that may be linked to
        or from the Sites. You should review the policies of such third parties
        and contact them directly to respond to your questions.
      </p>

      <h2>How Long Do We Keep Your Information? </h2>
      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy policy unless otherwise required
        by law.{" "}
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements).
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize it, or, if this
        is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>

      <h2>How Do We Keep Your Information Safe? </h2>
      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.{" "}
      </p>

      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, please also remember that we cannot guarantee that the
        internet itself is 100% secure. Although we will do our best to protect
        your personal information, transmission of personal information to and
        from our Sites is at your own risk. You should only access the services
        within a secure environment.{" "}
      </p>

      <h2>Do We Collect Information From Minors? </h2>
      <p>
        In Short: We do not knowingly collect data from or market to children
        under 18 years of age.{" "}
      </p>

      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Sites, you represent that you are at least 18
        or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Site. If we learn that personal
        information from users less than 18 years of age has been collected,
        without parental consent, we will deactivate the account and take
        reasonable measures to promptly delete such data from our records. If
        you become aware of any data we have collected from children under age
        18, please contact us at{" "}
        <a href="mailto:operations@ought.org" target="_blank">
          operations@ought.org
        </a>
        .{" "}
      </p>

      <h2>What Are Your Privacy Rights?</h2>

      <p>
        In Short: In some regions, such as the European Economic Area, you have
        rights that allow you greater access to and control over your personal
        information. You may review, change, or terminate your account at any
        time.{" "}
      </p>

      <p>
        In some regions (like the European Economic Area), you have certain
        rights under applicable data protection laws. These may include the
        right (i) to request access and obtain a copy of your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of your personal information; and (iv) if applicable, to
        data portability. In certain circumstances, you may also have the right
        to object to the processing of your personal information. To make such a
        request, please use the contact details provided at the end of this
        policy. We will consider and act upon any request in accordance with
        applicable data protection laws.{" "}
      </p>

      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal.
      </p>

      <p>
        If you are resident in the European Economic Area and you believe we are
        unlawfully processing your personal information, you also have the right
        to complain to your local data protection supervisory authority. You can
        find their contact details here:{" "}
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
      </p>

      <p>
        <strong>Account Information</strong>
      </p>
      <p>
        You may at any time review or change the information in your account or
        terminate your account by contacting us using the contact information
        provided below.
      </p>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
      </p>

      <h2>Do We Use Cookies And Other Tracking Technologies? </h2>
      <p>
        In Short: We may use cookies, web beacons, tracking pixels, and other
        tracking technologies when you visit our Sites to help customize the
        Site and improve your experience.{" "}
      </p>
      <p>
        <strong>Use of cookies</strong>
      </p>
      <p>
        A “cookie” is a string of information which assigns you a unique
        identifier that we store on your computer. Your browser then provides
        that unique identifier to use each time you submit a query to the Site.
        We use cookies on the Site to, among other things, keep track of
        services you have used, record registration information, record your
        user preferences, keep you logged into the Site, facilitate procedures,
        and track the pages you visit. Cookies help us understand how the Site
        is being used and improve your user experience.{" "}
      </p>

      <p>
        <strong>Types of cookies</strong>
      </p>
      <p>
        Types of cookies that may be used when you visit the Site include:
        analytics, personalization, security, and site management. For more
        information on cookie types, please see{" "}
        <a href="https://www.allaboutcookies.org/">this explainer</a>.
      </p>
      <p>
        <strong>Control of cookies</strong>
      </p>
      <p>
        Most browsers are set to accept cookies by default. However, you can
        remove or reject cookies in your browser’s settings. Please be aware
        that such action could affect the availability and functionality of the
        Site.{" "}
      </p>

      <p>
        For more information on how to control cookies, check your browser or
        device’s settings for how you can control or reject cookies, or see{" "}
        <a href="https://www.allaboutcookies.org/">this explainer</a>.
      </p>
      <p>
        <strong>Other tracking technologies</strong>
      </p>
      <p>
        In addition to cookies, we may use web beacons, pixel tags, and other
        tracking technologies on the Site to help customize the Site and improve
        your experience. A “web beacon” or “pixel tag” is a tiny object or image
        embedded in a web page or email. They are used to track the number of
        users who have visited particular pages and viewed emails, and acquire
        other statistical data. They collect only a limited set of data, such as
        a cookie number, time and date of page or email view, and a description
        of the page or email on which they reside. Web beacons and pixel tags
        cannot be declined.
      </p>

      <h2>Do California Residents Have Specific Privacy Rights? </h2>
      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.{" "}
      </p>

      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Sites, you have the right to request removal
        of unwanted data that you publicly post on the Sites. To request removal
        of such data, please contact us using the contact information provided
        below, and include the email address associated with your account and a
        statement that you reside in California. We will make sure the data is
        not publicly displayed on the Sites, but please be aware that the data
        may not be completely or comprehensively removed from our systems.
      </p>

      <h2>Do We Make Updates To This Policy?</h2>
      <p>
        In Short: Yes, we will update this policy as necessary to stay compliant
        with relevant laws.
      </p>

      <p>
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy policy frequently
        to be informed of how we are protecting your information.{" "}
      </p>

      <h2>How Can You Contact Us About This Policy?</h2>
      <p>
        If you have questions or comments about this policy, please email our
        Privacy Officer at{" "}
        <a href="mailto:operations@ought.org" target="_blank">
          operations@ought.org
        </a>{" "}
        or by post to:
      </p>

      <p>Ought Inc.</p>
      <p>Attn: Privacy Officer</p>
      <p>1714 Stockton St #201</p>
      <p>San Francisco, CA 94133</p>
      <p>United States</p>

      <p>
        If you are a resident in the European Economic Area, the "data
        controller" of your personal information is Ben Gering. You can contact
        them directly regarding the processing of your information by Ought, by
        email at{" "}
        <a href="mailto:bengering@ought.org" target="_blank">
          bengering@ought.org
        </a>
        .
      </p>
    </div>
  );
}
