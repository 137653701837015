import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function fetchCsv({
  accessToken,
  onSuccess,
}: {
  accessToken: string;
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function(dispatch) {
    let data;
    try {
      const params = new URLSearchParams(window.location.search);
      params.delete("limit");
      params.delete("offset");
      const response = await makeRequestToBackend({
        path: `/binary-questions/csv?${params.toString()}`,
        method: "GET",
        accessToken,
      });

      data = await response.text();

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }

    onSuccess(data);
  };
}
