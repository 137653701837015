import React from "react";

export const HomepagePredictItTab = () => {
  return (
    <div>
      Create custom notifications for PredictIt markets{" "}
      <a href="/predictit">here</a>.
    </div>
  );
};
