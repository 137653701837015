import * as Sentry from "@sentry/browser";

import { addQuestion } from "./addQuestion";
import { fetchStartingForecasts } from "./fetchStartingForecasts";
import { setBinaryQuestionText } from "./setBinaryQuestionText";
import { setIsEnteringInCustomQuestion } from "./setIsEnteringInCustomQuestion";
import { setShouldShowBinaryToCustomFlow } from "./setShouldShowBinaryToCustomFlow";
import { getIsOpen } from "./updateIsOpen";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

// From https://www.regextester.com/104035
const URL_WITH_OPTIONAL_PROTOCOL_REGEX = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

const getQuestionId = (newQuestionInput: string): string | null => {
  if (newQuestionInput.match(/^[\d]+$/)) {
    // If you just entered the question id
    return newQuestionInput;
  }

  if (newQuestionInput.match(URL_WITH_OPTIONAL_PROTOCOL_REGEX)) {
    // assume that you entered a Metaculus URL
    return (newQuestionInput.match(/\/questions\/([\d]+)/) as any)[1]; // TODOO clean u
  }

  return null;
};

const questionTypesToHumanReadable = {
  LogQuestion: "log question",
  LogDateQuestion: "log date question",
  LinearDateQuestion: "date question",
};

type ResponseJson = {
  error: string;
  question_type: "LogQuestion" | "LinearDateQuestion" | "LogDateQuestion";
};

const throwGetQuestionError = (responseJson: ResponseJson): null => {
  if (responseJson.error === "unsupported_question_type") {
    const questionTypeString =
      questionTypesToHumanReadable[responseJson.question_type];
    if (questionTypeString) {
      throw Error(
        `This is a ${questionTypeString}, which Elicit doesn't currently support.`
      );
    }

    throw Error("Elicit doesn't currently support this question type.");
  }

  if (responseJson.error === "question_not_found") {
    throw Error(
      "We couldn't find that question. We looked on the main Metaculus site and on pandemic.metaculus.com."
    );
  }

  throw Error(
    "Sorry, something went wrong when we tried to find that question."
  );
};

export function createQuestion({
  newQuestionInput,
  onPending,
  onDone,
  onError,
  onBinaryInput,
  setNoLongerPending,
}: {
  newQuestionInput: string;
  onPending?: () => void;
  onDone?: () => void;
  onError?: (msg: string) => void;
  onBinaryInput?: (input: string) => void;
  setNoLongerPending?: () => void;
}): AppThunk {
  return async function(dispatch) {
    const questionId = getQuestionId(newQuestionInput);

    if (questionId === null) {
      const isBinaryQuestion =
        newQuestionInput?.slice(0, 4).toLowerCase() === "will";

      if (isBinaryQuestion) {
        return onBinaryInput && onBinaryInput(newQuestionInput);
      }

      dispatch(setIsEnteringInCustomQuestion(true));
      return;
    }

    onPending && onPending();

    try {
      const response = await makeRequestToBackend({
        path: `/question/${questionId}`,
      });
      const responseJson = await response.json();
      if (!response.ok) {
        if (responseJson.questionType === "BinaryQuestion") {
          dispatch(setShouldShowBinaryToCustomFlow(true));
          dispatch(setBinaryQuestionText(responseJson.error.slice(75)));
          setNoLongerPending && setNoLongerPending();
          return;
        } else {
          throwGetQuestionError(responseJson);
        }
      }

      const isOpen = getIsOpen(responseJson);

      const metadata = {
        source: "Metaculus",
        sourceUrl: `https://${responseJson.apiDomain}.metaculus.com${responseJson.pageUrl}`,
        questionId,
        questionScale: responseJson.questionScale,
        graphScale: responseJson.graphScale,
        isLowerBoundClosed: responseJson.possibilities.low !== "tail",
        isUpperBoundClosed: responseJson.possibilities.high !== "tail",
        isOpen,
      };

      dispatch(addQuestion(responseJson.title, metadata));
      dispatch(fetchStartingForecasts(metadata));

      onDone && onDone();
    } catch (e) {
      Sentry.captureException(e);
      onError && onError(e.message);
    }
  };
}
