import { removeBelief } from "./removeBelief";
import { removeDistribution } from "./removeDistribution";
import { selectForecast } from "./selectForecast";

import { AppThunk } from "../reducers/rootReducer";

export const removeForecast = (forecastId: string): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();

  const forecastToRemove = state.forecasts.find((f) => f.id === forecastId);

  const distributionToRemove = state.distributions.find(
    (d) => d.id === forecastToRemove.distributionId
  );

  dispatch(removeDistribution(distributionToRemove.id));

  const getIdsOfBeliefsAttachedToForecast = (forecast) => [
    ...forecast.intervalBeliefIds,
    ...forecast.nonIntervalBeliefIds,
    ...forecast.impliedBeliefIds,
  ];

  const beliefsToRemove = state.beliefs.filter((b) => {
    if (getIdsOfBeliefsAttachedToForecast(forecastToRemove).includes(b.id)) {
      return true;
    }

    return false;
  });

  beliefsToRemove.forEach((b) => dispatch(removeBelief(b.id)));

  dispatch({ type: "REMOVE_FORECAST", forecastId });

  if (state.status.selectedForecastId === forecastId) {
    const userForecast = state.forecasts.find((f) => f.name === "user");
    dispatch(selectForecast(userForecast.id));
  }
};
