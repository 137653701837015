import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function disassociateTagWithQuestion({
  accessToken,
  binaryQuestionId,
  tagName,
  onSuccess,
}: {
  accessToken: string;
  binaryQuestionId: string;
  tagName: string;
  onSuccess: (data: any) => void;
}): AppThunk {
  return async function (dispatch) {
    let data;
    try {
      const response = await makeRequestToBackend({
        path: `/binary-questions/${binaryQuestionId}/tags`,
        method: "DELETE",
        body: {
          name: tagName,
        },
        accessToken,
      });

      if (!response.ok) {
        throw Error(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }

    onSuccess(data);
  };
}
