import { SetLoadingBeliefsStatusAction } from "../reducers/statusReducer";

type Status = "PENDING" | "ERROR" | "SUCCESS";

export const setLoadingBeliefsStatus = (
  loadingBeliefsStatus: Status
): SetLoadingBeliefsStatusAction => ({
  type: "SET_LOADING_BELIEFS_STATUS",
  loadingBeliefsStatus,
});
