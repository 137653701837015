import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { Belief } from "../reducers/beliefsReducer";
import { Distribution } from "../reducers/distributionsReducer";
import { QuestionMetadata } from "../reducers/questionReducer";

export async function submitForecast({
  beliefs,
  metadata,
  distribution,
  username,
  userApiKey,
  onPending,
  onSuccess,
  onError,
}: {
  beliefs: Belief[];
  metadata: QuestionMetadata;
  distribution: Distribution;
  username: string;
  userApiKey: string;
  onPending: () => void;
  onSuccess: () => void;
  onError: (resp?: any) => void;
}) {
  onPending();
  try {
    const response = await makeRequestToBackend({
      path: "/submit",
      method: "POST",
      body: {
        beliefs,
        metadata,
        distribution,
        username,
        userApiKey,
      },
    });

    const data = await response.json();

    if (!response.ok) {
      Sentry.captureException(data.error);
      return onError(response);
    }

    onSuccess();
  } catch (e) {
    Sentry.captureException(e);
    return onError();
  }
}
