import { SetCheckingClosenessToSubmissionStatusAction } from "../reducers/statusReducer";

type Status = "PENDING" | "ERROR" | "SUCCESS";

export function setCheckingClosenessToSubmissionStatus(
  checkingClosenessToSubmissionStatus: Status
): SetCheckingClosenessToSubmissionStatusAction {
  return {
    type: "SET_CHECKING_CLOSENESS_TO_SUBMISSION_STATUS",
    checkingClosenessToSubmissionStatus,
  };
}
