import { UpdateNamesOfVisibleDistributionsAction } from "../reducers/statusReducer";

export function updateNamesOfVisibleDistributions(
  name: string,
  isVisible: boolean
): UpdateNamesOfVisibleDistributionsAction {
  return {
    type: "UPDATE_NAMES_OF_VISIBLE_DISTRIBUTIONS",
    name,
    isVisible,
  };
}
