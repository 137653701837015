import moment from "moment";
import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../../constants";

// Turn timestamps into date strings
const formatTimestamp = (timestamp) =>
  timestamp === null
    ? null
    : moment
        .utc(timestamp * SERVER_TO_MS_TIMESTAMP_MULTIPLIER)
        .format("YYYY-MM-DD");

export const formatNumber = (x: number): string => {
  if (x >= 1 || x <= -1) {
    return Number(x.toFixed(1)).toLocaleString();
  } else {
    return x.toPrecision(2);
  }
};

export const formatPoint = (point: number, formatAsDate: boolean): string => {
  if (point === null) {
    return null;
  }

  if (formatAsDate) {
    return formatTimestamp(point);
  }

  return formatNumber(point);
};

export const formatPercent = (probability: number): string =>
  formatNumber(probability * 100);

export const formatDollarAmount = (x: number): string => {
  return x.toFixed(2);
};
