import React, { useState } from "react";

import { CollapsedBinaryQuestion } from "./CollapsedBinaryQuestion";
import { ExpandedBinaryQuestion } from "./ExpandedBinaryQuestion";

export function BinaryQuestion({ i, question, questionFocus, changeFocus }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      style={{
        borderBottom: "1px solid #eee",
        margin: "10px 0",
        padding: "10px 0",
      }}
    >
      <CollapsedBinaryQuestion
        i={i}
        isExpanded={isExpanded}
        handleExpand={() => setIsExpanded(true)}
        handleCollapse={() => setIsExpanded(false)}
        question={question}
        questionFocus={questionFocus}
        changeFocus={changeFocus}
      />
      <ExpandedBinaryQuestion isHidden={!isExpanded} question={question} />
    </div>
  );
}
