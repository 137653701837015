import sortBy from "lodash.sortby";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

export function SnapshotRecords() {
  const snapshotRecords = useSelector(
    (state: RootState) => state.sessionSnapshotRecords
  );

  // Most recent first
  const sortedSnapshotRecords = sortBy(snapshotRecords, (s) => -s.createdAt);

  return sortedSnapshotRecords.map(({ createdAt, key }) => {
    const url = `${window.location.origin}/builder/${key}`;

    return (
      <div
        key={key}
        style={{
          marginBottom: "5px",
          flexBasis: "100%",
          height: "100%",
        }}
      >
        <span style={{ marginRight: "5px" }}>
          <a href={url} target="_blank">
            Snapshot on {moment(createdAt).format("ll, h:mm:ss A")}
          </a>
        </span>
      </div>
    );
  });
}
