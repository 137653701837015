import React from "react";
import { Button, Modal } from "react-bootstrap";

import { LoginButton } from "./LoginBtn";

import { resetPleaseLoginTimer } from "../helpers/resetPleaseLoginTimer";

type PleaseLoginModalProps = {
  handleClose: () => void;
  show: boolean;
};

export function PleaseLoginModal(props: PleaseLoginModalProps) {
  const closeModal = () => {
    resetPleaseLoginTimer();
    props.handleClose();
  };

  return (
    <Modal show={props.show} onHide={closeModal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Log in to Elicit?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        If you log in or create an account, we’ll save your snapshot to your
        account so that you can easily find it later.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} variant="secondary">
          Continue without logging in
        </Button>
        <LoginButton size="md" />
      </Modal.Footer>
    </Modal>
  );
}
