import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import * as _ from "lodash";

import { fetchCalibration } from "../actions/fetchCalibration";

export function CalibrationChart() {
  const { user, getAccessTokenSilently, isLoading: isLoadingAuth } = useAuth0();

  const [calibrationData, setCalibrationData] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const numBins = 5;
  const bins = _.range(numBins).map((idx) => {
    return { low: 100 * (idx / numBins), high: 100 * ((idx + 1) / numBins) };
  });
  const labels = bins.map((bin) => {
    return `${bin.low}-${bin.high}%`;
  });

  useEffect(() => {
    (async function fn() {
      if (!isLoadingAuth) {
        const accessToken = user ? await getAccessTokenSilently() : undefined;
        dispatch(
          fetchCalibration({
            numBins,
            accessToken,
            onSuccess: (data) => {
              setCalibrationData(data);
            },
          })
        );
      }
    })();
  }, [isLoadingAuth, location]);

  const hasDataToShow =
    calibrationData &&
    calibrationData.perfectCalibrationData.reduce(
      (acc, val) => acc + Number(val),
      0
    ) > 0;

  return calibrationData && hasDataToShow ? (
    <div>
      <div style={{ textAlign: "center", fontWeight: 600, fontSize: "20px" }}>
        Calibration Chart
      </div>
      <Bar
        data={{
          labels,
          datasets: [
            {
              label: "Perfectly calibrated predictions",
              data: calibrationData.perfectCalibrationData,
              backgroundColor: "green",
            },
            {
              label: "Actual predictions",
              data: calibrationData.actualCalibrationData,
              backgroundColor: "blue",
            },
          ],
        }}
        options={{
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Percent resolved Yes",
                },
                ticks: {
                  callback: function(value, index, values) {
                    return value + "%";
                  },
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Predicted Probability",
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : null;
}
