import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function Tooltip({ content, title, children }) {
  return (
    <OverlayTrigger
      key={"top"}
      placement={"top"}
      trigger={["hover", "focus"]}
      overlay={
        <Popover
          id="popover-basic"
          style={{ maxWidth: "none", width: "350px" }}
        >
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
