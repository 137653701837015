import { RootState } from "../reducers/rootReducer";

import { selectedForecastSelector } from "./selectedForecastSelector";

export function displayedImpliedBeliefsSelector(state: RootState) {
  const selectedForecast = selectedForecastSelector(state);

  if (!selectedForecast) {
    return [];
  }

  const displayedImpliedBeliefs = state.beliefs.filter((b) =>
    selectedForecast.impliedBeliefIds.includes(b.id)
  );
  return displayedImpliedBeliefs;
}
