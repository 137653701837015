import { UserLoginAction } from "../reducers/userReducer";

export const login = ({
  apiKey,
  displayName,
  email,
  familyName,
  givenName,
  metaculusAPIKeys,
  metaculusUsername,
  sub,
}: {
  apiKey: string | null;
  displayName: string;
  email: string;
  familyName: string | null;
  givenName: string | null;
  metaculusAPIKeys: any;
  metaculusUsername: string;
  sub: string;
}): UserLoginAction => {
  return {
    type: "USER_LOGIN",
    user: {
      apiKey,
      displayName,
      email,
      familyName,
      givenName,
      metaculusAPIKeys,
      metaculusUsername,
      sub,
    },
  };
};
