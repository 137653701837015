export function formatDistNameForOutOfBoundsUI(name) {
  if (name === "user") {
    return "You";
  }

  if (name === "community") {
    return "Community";
  }

  if (name === "metaculusSubmission") {
    return "Submission";
  }

  return name;
}
