import React from "react";

import { Tooltip } from "./Tooltip";

export function ProbabilityTooltip(props) {
  return (
    <Tooltip
      content={"The probability that the value falls between min and max"}
      title={"Probability"}
    >
      {props.children}
    </Tooltip>
  );
}
