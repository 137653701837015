const initialState = [] as SearchPageQuestion[];

export type SearchPageQuestion = {
  close_time: string;
  id: number;
  title: string;
  votes: number;
};

export interface ReplaceSearchPageQuestionsAction {
  type: "REPLACE_SEARCH_PAGE_QUESTIONS";
  searchPageQuestions: SearchPageQuestion[];
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type SearchPageQuestionsAction =
  | ReplaceSearchPageQuestionsAction
  | ResetStateAction;

export function searchPageQuestionsReducer(
  state = initialState,
  action: SearchPageQuestionsAction
): SearchPageQuestion[] {
  if (action.type === "REPLACE_SEARCH_PAGE_QUESTIONS") {
    return action.searchPageQuestions;
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
