import numeral from "numeral";

import { stringToNumber } from "./stringToNumber";

export const formatNumberString = (str) => {
  if (isNaN(stringToNumber(str)) || stringToNumber(str) === null) {
    return str;
  }

  // We do this to turn a string like "1e5"
  // into 100000. We do this because numeral
  // will parse "1e5" as 15, but will parse
  // 100000 as 1e5.
  str = String(stringToNumber(str));

  // The following conditional
  // is in place because numeraljs
  // currently has trouble formatting
  // any number satisfying one of
  // these four conditions.

  if (
    stringToNumber(str) >= 1e21 ||
    stringToNumber(str) <= -1e21 ||
    (stringToNumber(str) > 0 && stringToNumber(str) <= 1e-6) ||
    (stringToNumber(str) < 0 && stringToNumber(str) >= -1e-6)
  ) {
    return str;
  }

  // 50 zeros below
  // Inputs are rounding at about 15 decimal places though.
  // TODO: investigate why.
  return numeral(str).format(
    ",.[00000000000000000000000000000000000000000000000000]"
  );
};
