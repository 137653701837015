import { SetShouldShowSubmissionOnLegendAction } from "../reducers/statusReducer";

export function setShouldShowSubmissionOnLegend(
  shouldShowSubmissionOnLegend: boolean
): SetShouldShowSubmissionOnLegendAction {
  return {
    type: "SET_SHOULD_SHOW_SUBMISSION_ON_LEGEND",
    shouldShowSubmissionOnLegend,
  };
}
