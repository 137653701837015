type Timestamp = number;

export interface SnapshotRecord {
  key: string;
  createdAt: Timestamp;
}

export interface AddSnapshotRecordAction {
  type: "ADD_SNAPSHOT_RECORD";
  key: string;
  createdAt: Timestamp;
}

type SessionSnapshotRecordsAction = AddSnapshotRecordAction;

const initialState = [] as SnapshotRecord[];

export function sessionSnapshotRecordsReducer(
  state = initialState,
  action: SessionSnapshotRecordsAction
) {
  if (action.type === "ADD_SNAPSHOT_RECORD") {
    return state.concat({
      key: action.key,
      createdAt: action.createdAt,
    });
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
