import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LegendDistLabel } from "./LegendDistLabel";
import { MaterialDropdown } from "./MaterialDropdown";

import { highlightPlot } from "../actions/highlightPlot";

import { getDistColorForLabel } from "../helpers/getDistColor";

import { RootState } from "../reducers/rootReducer";

import { getIndexOfDistFunctionSelector } from "../selectors/getIndexOfDistFunctionSelector";

import { LEGEND_Z_INDEX } from "../zIndexScale";

import { isInInlineMode } from "../helpers/isInInlineMode";

const DarkenOnHoverSpan = styled.div`
  .darken-on-hover {
    transition: color 0.2s;
  }

  .darken-on-hover:hover {
    color: #444 !important;
  }
`;

const Styles = styled.div`
  .legend-label {
    .close-x {
      opacity: 0;
    }

    &:hover {
      .close-x {
        opacity: 0.5;
      }
      .close-x:hover {
        opacity: 1;
      }
    }
  }
`;

export function Legend(props) {
  const dispatch = useDispatch();
  const inlineMode = isInInlineMode(useLocation().search);
  const bothBoundsClosed = useSelector(
    (state: RootState) =>
      state.question?.metadata.isLowerBoundClosed &&
      state.question?.metadata.isUpperBoundClosed
  );
  const [shouldOpen, setShouldOpen] = useState(false as boolean | {});
  const forecasts = useSelector((state: RootState) => state.forecasts);
  const distributions = useSelector((state: RootState) => state.distributions);

  const getIndexOfDist = useSelector(getIndexOfDistFunctionSelector);

  return (
    <Styles
      style={{
        backgroundColor: inlineMode && "rgba(253,251,250,0.5)",
        borderRadius: "3px",
        border: !inlineMode && "1px solid #eee",
        position: "absolute",
        textAlign: "left",
        top: inlineMode ? "30px" : "20px",
        right: `${props.rightPos - (inlineMode ? 50 : 355)}px`,
        width: "290px",
        zIndex: LEGEND_Z_INDEX,
      }}
    >
      <DarkenOnHoverSpan
        style={{
          alignItems: "start",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          maxHeight: "350px",
          overflowY: "auto",
          overflowX: "visible",
        }}
      >
        {forecasts
          .filter((f) => f.distributionId)
          .map((f) => {
            const d = distributions.find((d) => d.id === f.distributionId);

            return (
              <div
                style={{
                  color: getDistColorForLabel(d.name, getIndexOfDist(d.name)),
                }}
                onMouseEnter={() => dispatch(highlightPlot(d.id))}
                onMouseLeave={() => dispatch(highlightPlot(null))}
              >
                <LegendDistLabel
                  distribution={d}
                  forecastId={f.id}
                  key={f.id}
                  createdAt={f.createdAt}
                />
              </div>
            );
          })}
      </DarkenOnHoverSpan>
      <div style={{ margin: "5px" }}>
        <div style={{ textAlign: "left" }}>
          {forecasts.length > 0 && (
            <Button
              style={{
                outline: "none",
                position: "relative",
                top: "-2px",
                margin: "5px",
              }}
              size="small"
              color="default"
              variant="contained"
              onClick={(e) => {
                // The MaterialDropdown below is based on the non-React
                // Material design dropdown that uses vanilla HTML/JS.
                // It's opened imperatively, so we need to find a way to
                // trigger this imperative code via props. We can assign
                // {} to a prop to trigger a hook (since {} !== {}).
                // We only want to trigger an open on click IF the dropdown
                // is currently closed. Because we're not dealing with a React
                // component that stores whether it's open or closed in state,
                // we have to figure out whether it's open/closed by inspecting
                // the DOM. Namely, we see if the DOM element has class
                // mdc-menu-surface--open
                const el = document.querySelector(".mdc-menu");
                if (!el.classList.contains("mdc-menu-surface--open")) {
                  setShouldOpen({});
                }
              }}
            >
              Import forecast
            </Button>
          )}
        </div>
        <div style={{ border: "red" }} className="mdc-menu-surface--anchor">
          <MaterialDropdown shouldOpen={shouldOpen} />
        </div>
      </div>
    </Styles>
  );
}
