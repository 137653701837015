import { AppThunk } from "../reducers/rootReducer";

import { associateUserWithSnapshot } from "./associateUserWithSnapshot";

export function associateUserWithSessionSnapshots(
  accessToken: string
): AppThunk {
  return async function (dispatch, getState) {
    const state = getState();

    const sessionSnapshotKeys = state.sessionSnapshotRecords.map((s) => s.key);

    sessionSnapshotKeys.forEach((key) =>
      dispatch(associateUserWithSnapshot({ accessToken, key }))
    );
  };
}
