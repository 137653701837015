import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { RootState } from "../../reducers/rootReducer";

import { fetchImpliedBeliefs } from "../../actions/fetchImpliedBeliefs";
import { updateImpliedBeliefs } from "../../actions/updateImpliedBeliefs";

import { getDistributionsWithSelectedFirstSelector } from "../../selectors/getDistributionsWithSelectedFirstSelector";
import { getDisplayedImpliedBeliefsSortedByCategory } from "../../selectors/getDisplayedImpliedBeliefsSortedByCategory";
import { getForecastNameForImpliedBeliefsSelector } from "../../selectors/getForecastNameForImpliedBeliefsSelector";
import { getForecastLabelColorFunctionSelector } from "../../selectors/getForecastLabelColorFunctionSelector";

import { isInInlineMode } from "../../helpers/isInInlineMode";

import { ComparisonBeliefsDisplay } from "./ComparisonBeliefsDisplay";
import { PercentileBeliefsDisplay } from "./PercentileBeliefsDisplay";
import { GenericImpliedBeliefDisplay } from "./GenericImpliedBeliefDisplay";

export function ImpliedBeliefs() {
  const dispatch = useDispatch();

  const distributionsWithSelectedFirst = useSelector(
    getDistributionsWithSelectedFirstSelector
  );

  const forecasts = useSelector((state: RootState) => state.forecasts);
  const selectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );
  const selectedForecast = forecasts.find((f) => f.id === selectedForecastId);

  const name = useSelector(
    getForecastNameForImpliedBeliefsSelector(selectedForecastId)
  );

  const tabScreen = useSelector((state: RootState) => state.status.tabScreen);

  const getForecastLabelColorFunction = useSelector(
    getForecastLabelColorFunctionSelector
  );

  const loadingDistributionsStatus = useSelector(
    (state: RootState) => state.status.loadingDistributionsToDisplayStatus
  );

  const question = useSelector((state: RootState) => state.question);
  const metadata = question && question.metadata;

  const inlineMode = isInInlineMode(useLocation().search);

  const [fetchingStatus, setFetchingStatus] = useState(null);

  useEffect(() => {
    if (tabScreen !== "INTERPRET" || !selectedForecastId) {
      return;
    }

    if (
      loadingDistributionsStatus !== "SUCCESS" &&
      loadingDistributionsStatus !== null
    ) {
      if (loadingDistributionsStatus === "PENDING") {
        setFetchingStatus("PENDING");
      } else if (loadingDistributionsStatus === "ERROR") {
        setFetchingStatus(null);
      }
      return;
    }

    setFetchingStatus("PENDING");
    dispatch(
      fetchImpliedBeliefs({
        distributions: distributionsWithSelectedFirst,
        metadata,
        onSuccess: (data) => {
          const currentImpliedBeliefIds =
            (selectedForecast && selectedForecast.impliedBeliefIds) || [];
          dispatch(
            updateImpliedBeliefs(
              selectedForecastId,
              data,
              currentImpliedBeliefIds
            )
          );
          setFetchingStatus("SUCCESS");
        },
        onError: () => {
          setFetchingStatus("ERROR");
        },
      })
    );
  }, [
    tabScreen,
    selectedForecastId,
    loadingDistributionsStatus,
    forecasts.length,
  ]);

  const { percentileBeliefs, comparisonBeliefs, genericBeliefs } = useSelector(
    getDisplayedImpliedBeliefsSortedByCategory
  );

  return (
    <div style={{ position: "relative", textAlign: "left", top: "-20px", maxHeight: inlineMode && "243px", overflowY: "auto"}}>
      {fetchingStatus === "ERROR" ? (
        <div
          style={{
            fontSize: "24px",
            textAlign: "center",
            color: "#f66",
          }}
        >
          Error computing interpret results
        </div>
      ) : (
        <div>
          {(percentileBeliefs.length > 0 || genericBeliefs.length > 0) && (
            <div style={{ marginBottom: "20px" }}>
              <div style={{ fontWeight: 600, marginBottom: "5px" }}>
                About{" "}
                <span
                  style={{
                    color: getForecastLabelColorFunction(selectedForecastId),
                  }}
                >
                  {name}
                </span>
              </div>
              <ul>
                <li key="percentiles" style={{ marginBottom: "10px" }}>
                  {percentileBeliefs.length > 0 && (
                    <PercentileBeliefsDisplay beliefs={percentileBeliefs} />
                  )}
                </li>
                {genericBeliefs.map((belief) => (
                  <li key={belief.id}>
                    <GenericImpliedBeliefDisplay belief={belief} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div>
            {comparisonBeliefs.length > 0 && (
              <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                <ComparisonBeliefsDisplay beliefs={comparisonBeliefs} />
              </div>
            )}
          </div>
          {fetchingStatus === "PENDING" && (
            <div
              style={{
                color: "#aaa",
                fontSize: "12px",
                position: "absolute",
                right: 0,
                top: 0,
                textAlign: "center",
              }}
            >
              {genericBeliefs.length > 0 ? "updating " : "computing "}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
