import moment from "moment";
import numeral from "numeral";
// Can't import sugar-date in TypeScript
// But works with require
// https://github.com/andrewplummer/Sugar/issues/667
import { stringToDate } from "../../../helpers/stringToDate";

export function convertToValue(id, input, isDateQuestion) {
  if (id === "max") {
    if (input === "Max") {
      return null;
    }
  }

  if (id === "min") {
    if (input === "Min") {
      return null;
    }
  }

  if (id === "max" || id === "min") {
    if (input === "") {
      return null;
    }

    if (isDateQuestion) {
      const timestamp = Number(moment(stringToDate(input)).format("X"));
      return timestamp;
    }

    // By default, numeral interpret k, m, b, t as
    // thousand, million, billion, trillion. But it
    // doesn't interpret the uppercase letters similarly.
    // Because we want the uppercase letters to be
    // interpreted similarly, we'll convert all letters
    // to lowercase here.
    input = input.toLowerCase();

    // By default, numeral doesn't handle exponent strings
    // 1e9, so this code is in place to handle them.
    if (input.includes("e") && !isNaN(Number(input))) {
      input = String(Number(input));
    }

    if (isNaN(numeral(input).value())) {
      return null;
    }

    return numeral(input).value();
  }

  if (id === "notes") {
    return input;
  }

  if (id === "p") {
    // Remove % char from end if there
    input = input.replace(/\%$/, "");

    if (input === "") {
      return null;
    }

    if (isNaN(input)) {
      return null;
    }

    return Number(input) / 100;
  }

  return numeral(input).value();
}
