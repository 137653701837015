import { API_URL } from "../config";

export const makeRequestToBackend = ({
  path,
  method,
  body,
  accessToken,
}: {
  path: string;
  method?: string;
  body?: any;
  accessToken?: string;
}) => {
  const init: RequestInit = {
    credentials: "include",
  };

  if (method) {
    init["method"] = method;
  }

  if (body) {
    if (process.env.NODE_ENV === "production") {
      // for Segment tracking
      const anonymousId =
        (window as any)["analytics"]?.user &&
        (window as any)["analytics"]?.user().anonymousId();

      // anonymousId may not be available if the user has blocked Segment
      if (anonymousId) {
        body.anonymousId = anonymousId;
      }
    }
    init["body"] = JSON.stringify(body);
  }

  if (method === "POST" || method === "PATCH" || method === "DELETE") {
    init["headers"] = {
      "Content-Type": "application/json",
    };
  }

  if (accessToken) {
    init["headers"] = {
      ...init["headers"],
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return fetch(`${API_URL}${path}`, init);
};
