import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import sortBy from "lodash.sortby";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { displayUpdatedResolution } from "../actions/displayUpdatedResolution";
import { resolveBinaryQuestion } from "../actions/resolveBinaryQuestion";
import { updateBinaryQuestion } from "../actions/updateBinaryQuestion";

import { binaryPredictionsOfBinaryQuestion } from "../selectors/binaryPredictionsOfBinaryQuestion";

import { INPUT_BG_COLOR } from "../styles";

import { BinaryPrediction } from "./BinaryPrediction";
import { NewBinaryPrediction } from "./NewBinaryPrediction";
import { QuestionTags } from "./QuestionTags";

const Styles = styled.div`
  .MuiInputBase-input {
    font-size: 16px;
  }

  .custom-padding .MuiInputBase-input {
    padding-left: 4px;
    padding-bottom: 0;
  }

  .custom-padding .MuiInputBase-root {
    line-height: 18px;
  }

  .show-on-hover-container .show-on-hover {
    visibility: hidden;
  }

  .show-on-hover-container:hover .show-on-hover {
    visibility: visible;
  }

  input,
  textarea {
    background-color: ${INPUT_BG_COLOR};
    border: none;
  }
`;

export function ExpandedBinaryQuestion({ isHidden, question }) {
  const [binaryPredictionsCreatorIdQueryParam] = useQueryParam(
    "binaryPredictions.creatorId",
    StringParam
  );

  const { getAccessTokenSilently, user } = useAuth0();

  const [isEditingNotes, setIsEditingNotes] = useState(false);

  const dispatch = useDispatch();

  const {
    creator,
    resolvesBy,
    createdAt,
    resolution,
    isYourQuestion,
    sourceUrl,
    source,
    privacyStatus,
  } = question;

  const binaryPredictions = useSelector(
    binaryPredictionsOfBinaryQuestion({ id: question.id })
  );

  const binaryPredictionsToShow = binaryPredictionsCreatorIdQueryParam
    ? binaryPredictions.filter((p) => {
        if (binaryPredictionsCreatorIdQueryParam === "me") {
          return p.isYourPrediction;
        }

        return p.creator.id === binaryPredictionsCreatorIdQueryParam;
      })
    : binaryPredictions;

  const handleResolution = async (yesOrNo: "YES" | "NO" | null) => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      resolveBinaryQuestion({
        accessToken,
        binaryQuestionId: question.id,
        resolution: yesOrNo,
        onSuccess: () =>
          dispatch(
            displayUpdatedResolution({
              binaryQuestionId: question.id,
              resolution: yesOrNo,
            })
          ),
      })
    );
  };

  const [notesInput, setNotesInput] = useState(question.notes);

  const notesInputEl = useRef(null);

  const handleUpdateNotes = async () => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    setIsEditingNotes(false);
    dispatch(
      updateBinaryQuestion({
        accessToken,
        binaryQuestionId: question.id,
        fields: { notes: notesInput },
        onSuccess: () => {},
      })
    );
  };

  useEffect(() => {
    if (isEditingNotes) {
      notesInputEl.current.focus();
      notesInputEl.current.setSelectionRange(
        notesInputEl.current.value.length,
        notesInputEl.current.value.length
      );
    }
  }, [isEditingNotes]);

  return (
    <Styles style={{ display: isHidden && "none" }}>
      <div
        className="show-on-hover-container"
        style={{
          margin: "20px 0",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {!isYourQuestion && notesInput}
        {isYourQuestion && (
          <TextareaAutosize
            ref={notesInputEl}
            value={notesInput}
            onKeyDown={async (e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.stopPropagation();
                notesInputEl.current.blur();
              }
            }}
            onBlur={handleUpdateNotes}
            onChange={(e) => setNotesInput(e.target.value)}
            style={{
              minWidth: "300px",
              width: "60%",
            }}
            placeholder="Add notes"
          />
        )}
      </div>
      {question.source && (
        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              color: "#999",
              fontWeight: 600,
            }}
          >
            Source
          </div>
          {question.sourceUrl ? (
            <a
              href={question.sourceUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              {question.source.name}
            </a>
          ) : (
            question.source.name
          )}
        </div>
      )}
      <div
        style={{
          color: "#999",
          display: "grid",
          fontWeight: 600,
          gridTemplateColumns: "40% 20% auto",
        }}
      >
        <span>{question.resolution ? "Resolved" : "Resolves"}</span>
        <span>Creator</span>
        <span>Tags</span>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40% 20% auto",
          marginBottom: "30px",
        }}
      >
        <div>
          {moment(resolvesBy).format("D MMM YYYY")}
          {isYourQuestion ? (
            <>
              {" "}
              as{" "}
              <span
                className="custom-padding"
                style={{
                  display: "inline-block",
                  position: "relative",
                  top: "-2px",
                }}
              >
                <FormControl>
                  <Select
                    value={resolution}
                    onChange={(e) => handleResolution(e.target.value as any)}
                    displayEmpty
                  >
                    <MenuItem value={null}>
                      <span style={{ color: "#999" }}>Not resolved</span>
                    </MenuItem>
                    <MenuItem value={"YES"}>
                      <span style={{ color: "green" }}>Yes</span>
                    </MenuItem>
                    <MenuItem value={"NO"}>
                      <span style={{ color: "red" }}>No</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </span>
            </>
          ) : resolution === "YES" ? (
            <>
              {" "}
              as <span style={{ color: "green" }}>Yes</span>
            </>
          ) : resolution === "NO" ? (
            <>
              {" "}
              as <span style={{ color: "red" }}>No</span>
            </>
          ) : null}
        </div>
        <div>{creator.displayName}</div>
        <div>
          <QuestionTags
            binaryQuestionId={question.id}
            isYourQuestion={question.isYourQuestion}
            tags={question.tags}
          />
        </div>
      </div>
      <div
        style={{
          color: "#999",
          display: "grid",
          fontWeight: 600,
          gridColumnGap: "20px",
          gridTemplateColumns: "120px 150px 150px auto",
        }}
      >
        <span>Prediction</span>
        <span>Predictor</span>
        <span>Date</span>
        <span>Notes</span>
      </div>
      {resolution === null && user && (
        <div
          style={{
            paddingTop: "8px",
          }}
        >
          <NewBinaryPrediction
            binaryQuestionId={question.id}
            questionPrivacyStatus={privacyStatus}
          />
        </div>
      )}
      {sortBy(binaryPredictionsToShow, (p) => -new Date(p.createdAt)).map(
        (p) => (
          <div
            style={{
              borderTop: "1px solid rgb(238, 238, 238)",
              paddingTop: "8px",
            }}
          >
            <BinaryPrediction key={p.id} prediction={p} />
          </div>
        )
      )}
    </Styles>
  );
}
