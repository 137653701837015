import isNil from "lodash.isnil";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import "./DistPlot.css";

import { Legend } from "../Legend";

import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../../constants";

import {
  getDistColorForPlot,
  PLOT_OPACITY,
  USER_PLOT_OPACITY,
} from "../../helpers/getDistColor";

import { processProbValue } from "../../helpers/processProbValue";

import { QuestionMetadata } from "../../reducers/questionReducer";

import { RootState } from "../../reducers/rootReducer";

import { getIndexOfDistFunctionSelector } from "../../selectors/getIndexOfDistFunctionSelector";
import { inBoundsProbForBeliefSelector } from "../../selectors/inBoundsProbForBeliefSelector";
import { shouldShowDistributionFunctionSelector } from "../../selectors/shouldShowDistributionFunctionSelector";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";

interface Point {
  x: number;
  y: number;
}

interface DistributionToPlot {
  name: string;
  data: Point[];
}

interface DistPlotProps {
  distributions: DistributionToPlot[];
  width: number;
  height: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  metadata?: QuestionMetadata;
  relevantBelief?: any; // TODO
  areAllBeliefsValid: boolean;
}

const ANIMATION_DURATION = 0; // In milliseconds

// Way of keeping track of svg node
// TODO: Potentially refactor
let svg;

export function DistPlot(props: DistPlotProps) {
  const d3Container = useRef(null);

  const metadata = props.metadata;

  const isDateQuestion = useSelector(isDateQuestionSelector);

  const isLoadingDistributionsToDisplay = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "PENDING"
  );

  const relevantBelief = props.relevantBelief;
  const margin = props.margin;
  const width = props.width - margin.left - margin.right;
  const height = props.height - margin.top - margin.bottom;
  const isLogQuestion = metadata?.questionScale?.class === "LogScale";
  const questionScale = metadata?.questionScale;
  const graphScale = metadata?.graphScale;
  const areAllBeliefsValid = props.areAllBeliefsValid;
  const errorFetchingDistributions = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "ERROR"
  );

  const inBoundsProbForBelief = useSelector(
    inBoundsProbForBeliefSelector(relevantBelief?.id)
  );

  const instantiateSVG = () => {
    // If svg doesn't exist or is empty
    // We need to do setup
    if (!svg || !svg.empty || svg.empty()) {
      const containerSvg = d3.select(d3Container.current);

      return containerSvg
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .style("overflow", "visible")
        .append("g")
        .attr("class", "container")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    }
    const containerSvg = d3.select(d3Container.current);
    return containerSvg.select(".container");
  };

  const shouldShowDistributionFunction = useSelector(
    shouldShowDistributionFunctionSelector
  );

  const getDistributionsToShow = () => {
    return props.distributions.filter((d) => {
      return shouldShowDistributionFunction(d.name);
    });
  };

  const scaleSwitch = {
    Scale: (x) => x.scaleLinear().domain([graphScale.low, graphScale.high]),
    TimeScale: (x) =>
      x
        .scaleTime()
        .domain([
          new Date(graphScale.low * SERVER_TO_MS_TIMESTAMP_MULTIPLIER),
          new Date(graphScale.high * SERVER_TO_MS_TIMESTAMP_MULTIPLIER),
        ]),
    LogScale: (x) =>
      x
        .scaleLog()
        .base(10) // Using 10 for all log questions
        .domain([graphScale.low, graphScale.high]),
  };

  const getXScale = () => {
    const scaleFn =
      metadata && metadata.questionScale
        ? (x) => scaleSwitch[questionScale.class](x)
        : (x) => x.scaleLinear();

    return scaleFn(d3).range([0, width]);
  };

  const getYScale = (allDataPoints: Point[]) =>
    d3
      .scaleLinear()
      .domain([0, d3.max(allDataPoints, (d) => d.y)])
      .range([height, 0]);

  // highlight the interval on the graph that corresponds to the interval belief
  // that the user is hovering over, and show how much probability mass
  // is assigned to that interval
  const highlightRelevantInterval = (
    xScale,
    relevantBeliefMin,
    relevantBeliefMax
  ) => {
    if (relevantBelief) {
      if (svg.select("#clipping").empty()) {
        return svg
          .append("clipPath")
          .attr("id", "clipping")
          .append("rect")
          .attr("x", xScale(relevantBeliefMin))
          .attr("y", 0)
          .attr("width", xScale(relevantBeliefMax) - xScale(relevantBeliefMin))
          .attr("height", height); // set the y radius
      }
      svg.select("#clipping").remove();

      return svg
        .append("clipPath")
        .attr("id", "clipping")
        .append("rect")
        .attr("x", xScale(relevantBeliefMin))
        .attr("y", 0)
        .attr("width", xScale(relevantBeliefMax) - xScale(relevantBeliefMin))
        .attr("height", height);
    }
  };

  const getIndexOfDist = useSelector(getIndexOfDistFunctionSelector);

  const plotDistributions = (
    distributions,
    namedRelevantBeliefDistribution,
    xScale,
    yScale
  ) => {
    svg
      .selectAll("path")
      .data(
        relevantBelief
          ? distributions.concat(namedRelevantBeliefDistribution || [])
          : distributions,
        (d) => d?.name
      )
      .join("path")
      .attr("fill", (d) =>
        d.name === "fit"
          ? getDistColorForPlot("user", getIndexOfDist("user"))
          : getDistColorForPlot(d.name, getIndexOfDist(d.name))
      )
      .attr("fill-opacity", (d) => {
        if (d.isFaded) {
          return 0.15;
        }

        if (d.isHighlighted) {
          return 0.65;
        }

        return d.name === "user" ? USER_PLOT_OPACITY : PLOT_OPACITY;
      })
      .attr("stroke", (d) =>
        d.name === "fit"
          ? getDistColorForPlot("user", getIndexOfDist("user"))
          : getDistColorForPlot(d.name, getIndexOfDist(d.name))
      )
      .attr("stroke-opacity", (d) => {
        if (d.isFaded) {
          return 0.15;
        }

        if (d.isHighlighted) {
          return 0.65;
        }

        return 0.4;
      })
      .transition()
      .duration(ANIMATION_DURATION)
      .attr("d", (distribution) =>
        d3
          .area()
          .x((d) => xScale(d[0]))
          .y0(height)
          .y1((d) => yScale(d[1]))(distribution.data.map(({ x, y }) => [x, y]))
      )
      .attr("clip-path", (d) => d.name === "fit" && "url(#clipping)");
  };

  const plotXAxis = (xScale) => {
    const xAxis = d3.axisBottom(xScale);

    if (isLogQuestion) {
      if (questionScale.high > 10000) {
        xAxis.tickArguments([
          10,
          (d) => d3.format(".0s")(Number(d)).replace("G", "B"),
        ]);
      } else {
        xAxis.tickArguments([10, d3.format("~r")]);
      }
    } else if (
      !isDateQuestion &&
      (questionScale.high >= 10000000 || questionScale.low <= -10000000)
    ) {
      // SI prefixes but with B instead of G
      xAxis.tickFormat((d) => d3.format(".3s")(Number(d)).replace("G", "B"));
    }

    if (svg.select(".x.axis").empty()) {
      return svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
    }

    const t = svg.transition().duration(ANIMATION_DURATION);
    t.select(".x.axis").call(xAxis);
  };

  const plotYAxis = (yScale) => {
    const yAxis = d3.axisLeft(yScale).ticks(8);

    const appendYAxis = () =>
      svg
        .append("g")
        .attr("class", "y axis")
        .call(yAxis)
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("x", -10)
        .attr("dy", "0.71em")
        .attr("fill", "#000")
        .text("Probability Density");

    if (svg.select(".y.axis").empty()) {
      return appendYAxis();
    }

    svg.select(".y.axis").remove();
    appendYAxis();
  };

  const drawBoundary = (name, x) => {
    if (!svg.select(`.${name}`).empty()) {
      return;
    }

    const g = svg.append("g").attr("class", name);

    g.append("line")
      .attr("x1", x)
      .attr("y1", height)
      .attr("x2", x)
      .attr("y2", "0")
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "5 5");
  };

  const plotQuestionRange = (xScale) => {
    if (!graphScale || !questionScale) {
      return;
    }

    if (questionScale.low !== graphScale.low) {
      drawBoundary("low_boundary", xScale(questionScale.low));
    }

    if (questionScale.high !== graphScale.high) {
      drawBoundary("high_boundary", xScale(questionScale.high));
    }
  };

  const renderFitLabel = (
    distributions,
    xScale,
    relevantBeliefMin,
    relevantBeliefMax,
    areAllBeliefsValid,
    errorFetchingDistributions
  ) => {
    if (
      relevantBelief &&
      distributions.find((d) => d.name === "user") &&
      svg.select(".fit-label").empty() &&
      !isLoadingDistributionsToDisplay &&
      areAllBeliefsValid &&
      !errorFetchingDistributions
    ) {
      const g = svg.append("g").attr("class", "fit-label");

      g.append("rect")
        .attr("y", height / 2)
        .attr(
          "x",
          xScale(relevantBeliefMin) +
            (xScale(relevantBeliefMax) - xScale(relevantBeliefMin)) / 2 -
            50
        )
        .attr("width", 100)
        .attr("height", 40)
        .attr("fill", "rgba(255,255,255,0.95")
        .attr("stroke", "#999");

      g.append("text")
        .attr("y", height / 2 + 25)
        .attr(
          "x",
          xScale(relevantBeliefMin) +
            (xScale(relevantBeliefMax) - xScale(relevantBeliefMin)) / 2
        )
        .attr("text-anchor", "middle")
        .text(processProbValue(inBoundsProbForBelief) + "%");
    } else if (
      relevantBelief &&
      distributions.find((d) => d.name === "user") &&
      !isLoadingDistributionsToDisplay
    ) {
      svg
        .select(".fit-label")
        .select("rect")
        .attr(
          "x",
          xScale(relevantBeliefMin) +
            (xScale(relevantBeliefMax) - xScale(relevantBeliefMin)) / 2 -
            50
        );

      svg
        .select(".fit-label")
        .select("text")
        .attr("y", height / 2 + 25)
        .attr(
          "x",
          xScale(relevantBeliefMin) +
            (xScale(relevantBeliefMax) - xScale(relevantBeliefMin)) / 2
        )
        .text(processProbValue(inBoundsProbForBelief) + "%");
    } else {
      svg.select(".fit-label").remove();
    }
  };

  useEffect(() => {
    if (!d3Container.current) {
      return;
    }

    svg = instantiateSVG();

    // instantiate the svg before this check
    // to prevent pop-in from instantiating the svg later
    if (!graphScale) {
      return;
    }

    const xScale = getXScale();

    const distributions = getDistributionsToShow();

    const datasets = distributions.map((d) => d.data);

    const allDataPoints: Point[] = [].concat(...datasets);

    const yScale = getYScale(allDataPoints);

    const relevantBeliefDistribution =
      relevantBelief && distributions.find((d) => d.name === "user");

    const namedRelevantBeliefDistribution = relevantBeliefDistribution && {
      ...relevantBeliefDistribution,
      name: "fit",
    };

    let relevantBeliefMin =
      relevantBelief &&
      (!isNil(relevantBelief.values.min)
        ? relevantBelief.values.min
        : !isNil(questionScale.low)
        ? questionScale.low
        : d3.min(allDataPoints, (d: any) => d.x));

    let relevantBeliefMax =
      relevantBelief &&
      (!isNil(relevantBelief.values.max)
        ? relevantBelief.values.max
        : !isNil(questionScale.high)
        ? questionScale.high
        : d3.max(allDataPoints, (d: any) => d.x));

    // Data is sent from server as Unix second-based timestamps
    // But d3 expects millisecond based timestamps
    if (isDateQuestion) {
      relevantBeliefMax *= SERVER_TO_MS_TIMESTAMP_MULTIPLIER;
      relevantBeliefMin *= SERVER_TO_MS_TIMESTAMP_MULTIPLIER;
    }

    if (!graphScale) {
      return;
    }

    highlightRelevantInterval(xScale, relevantBeliefMin, relevantBeliefMax);

    plotDistributions(
      distributions,
      namedRelevantBeliefDistribution,
      xScale,
      yScale
    );

    plotXAxis(xScale);

    plotYAxis(yScale);

    plotQuestionRange(xScale);

    if (relevantBelief) {
      renderFitLabel(
        distributions,
        xScale,
        relevantBeliefMin,
        relevantBeliefMax,
        areAllBeliefsValid,
        errorFetchingDistributions
      );
    } else {
      svg.selectAll(".fit-label").remove();
    }
  });

  return (
    <div
      style={{
        height: props.height,
        position: "relative",
        width: props.width,
      }}
    >
      <Legend rightPos={props.margin.left + props.margin.right} />
      <svg ref={d3Container} />
    </div>
  );
}
