import { SetIsEnteringInCustomQuestionAction } from "../reducers/statusReducer";

export function setIsEnteringInCustomQuestion(
  isEnteringInCustomQuestion: boolean
): SetIsEnteringInCustomQuestionAction {
  return {
    type: "SET_IS_ENTERING_IN_CUSTOM_QUESTION",
    isEnteringInCustomQuestion,
  };
}
