import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { displayedBeliefsSelector } from "../selectors/displayedBeliefsSelector";
import { toggleBeliefIsActive } from "../actions/toggleBeliefIsActive";
import { updateBeliefValue } from "../actions/updateBeliefValue";

import { isSelectedForecastEditableSelector } from "../selectors/isSelectedForecastEditableSelector";

const NonIntervalBeliefInput = ({ beliefId, valueKey, initialValue }) => {
  const dispatch = useDispatch();

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const [input, setInput] = useState(initialValue);

  const updateValue = () => {
    if (!isNaN(input)) {
      dispatch(updateBeliefValue(beliefId, valueKey, Number(input)));
    }
  };

  return (
    <input
      disabled={!isSelectedForecastEditable}
      onChange={(e: any) => {
        setInput(e.target.value);
      }}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          updateValue();
        }
      }}
      onBlur={(e) => {
        updateValue();
      }}
      type="text"
      style={{ textAlign: "center", width: "35px" }}
      value={input}
    />
  );
};

const Label = ({ isActive, beliefId, label, values }) => {
  const REGEX = /\{.*?\}/g;

  // Slicing removes the curly brackets
  const valueKeys = label.match(REGEX)?.map((str) => str.slice(1, -1));

  return (
    <>
      {label.split(REGEX).map((str, i, arr) => {
        const isLastOne = i === arr.length - 1;

        // Plain "key" doesn't work properly in React
        // It's reserved for another purpose.
        // So using "valueKey" below

        return (
          <span key={i}>
            {str}
            <span
              style={{
                visibility: isActive ? "visible" : "hidden",
              }}
            >
              {!isLastOne && (
                <NonIntervalBeliefInput
                  beliefId={beliefId}
                  valueKey={valueKeys[i]}
                  initialValue={values[valueKeys[i]]}
                />
              )}
            </span>
          </span>
        );
      })}
    </>
  );
};

export function NonIntervalBeliefs() {
  const dispatch = useDispatch();

  const nonIntervalBeliefs = useSelector(
    displayedBeliefsSelector({
      interval: false,
      nonInterval: true,
    })
  );

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  return nonIntervalBeliefs ? (
    <>
      {nonIntervalBeliefs.map((b) => (
        <div key={b.id} style={{ alignItems: "center", display: "flex" }}>
          <Checkbox
            checked={b.isActive}
            disabled={!isSelectedForecastEditable}
            onChange={() => dispatch(toggleBeliefIsActive(b.id))}
            name="checkedB"
            color="primary"
          />
          <Label
            beliefId={b.id}
            label={b.label}
            values={b.values}
            isActive={b.isActive}
          />
        </div>
      ))}{" "}
    </>
  ) : null;
}
