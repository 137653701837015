import * as Sentry from "@sentry/browser";

import { setIsOpen } from "./setIsOpen";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export const getIsOpen = (responseJson: any): boolean => {
  return (
    new Date() >= new Date(responseJson.publishTime) &&
    new Date() <= new Date(responseJson.closeTime)
  );
};

export function updateIsOpen(questionId: string): AppThunk {
  return async function (dispatch) {
    try {
      const response = await makeRequestToBackend({
        path: `/question/${questionId}`,
      });
      const responseJson = await response.json();

      dispatch(setIsOpen(getIsOpen(responseJson)));
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}
