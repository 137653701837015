import { RootState } from "../reducers/rootReducer";
import { displayedImpliedBeliefsSelector } from "./displayedImpliedBeliefsSelector";
import {
  Belief,
  ComparisonBelief,
  IntervalBelief,
} from "../reducers/beliefsReducer";

export const getDisplayedImpliedBeliefsSortedByCategory = (
  state: RootState
) => {
  const impliedBeliefs = displayedImpliedBeliefsSelector(state);

  const percentileBeliefs = impliedBeliefs.filter(
    (belief) =>
      belief.type === "interval" &&
      belief.values.min === null &&
      belief.label.includes("probability in interval")
  ) as IntervalBelief[];

  const comparisonBeliefs = impliedBeliefs.filter(
    (belief) => belief.type === "comparison"
  ) as ComparisonBelief[];

  const specificBeliefs: Belief[] = [].concat(
    percentileBeliefs,
    comparisonBeliefs
  );

  const genericBeliefs = impliedBeliefs.filter(
    (belief) => !specificBeliefs.includes(belief)
  );

  return {
    percentileBeliefs,
    comparisonBeliefs,
    genericBeliefs,
  };
};
