import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "react-movable";
import styled from "styled-components";

import { BeliefRow } from "./BeliefRow";
import { TableHeadings } from "./TableHeadings";

import { addIntervalBelief } from "../../actions/addIntervalBelief";
import { setHasAddedRow } from "../../actions/setHasAddedRow";
import { reorderIntervalBeliefs } from "../../actions/reorderIntervalBeliefs";

import { setHasVisitedBefore } from "../../actions/setHasVisitedBefore";
import { NonIntervalBeliefs } from "../NonIntervalBeliefs";

import { RootState } from "../../reducers/rootReducer";

import { displayedBeliefsSelector } from "../../selectors/displayedBeliefsSelector";
import { isSelectedForecastEditableSelector } from "../../selectors/isSelectedForecastEditableSelector";

// Need to add this exactly once
let hasAddedEventListener = false;

const Styles = styled.div`
  position: relative;

  input,
  textarea {
    background-color: transparent;
    border: 1px dotted transparent;
  }

  input:disabled,
  textarea:disabled {
    color: #444;
  }

  input:hover,
  textarea:hover {
    background-color: #fff;
  }

  .gray-on-hover:hover {
    background-color: #f8f8f8;
  }

  .belief-table {
    .close-x {
      opacity: 0;
    }

    .move-icon {
      opacity: 0;
    }

    .belief-row:hover {
      background-color: rgba(246, 246, 246, 0.95);
      border-radius: 1px;
      .close-x {
        opacity: 0.5;
      }
      .close-x:hover {
        opacity: 1;
      }

      .move-icon {
        cursor: pointer;
        opacity: 0.5;
      }
      .move-icon:hover {
        opacity: 1;
      }

      input,
      textarea {
        border: 1px dotted #ccc;
      }
    }
    .belief-cell {
      display: inline-block;

      textarea {
        background-color: transparent;
        font-size: 1rem;
        height: 36px;
        margin: 0;
        border: 0;
        text-align: center;
        border: 1px dotted transparent;
        width: 150px;
      }
      input:hover,
      textarea:hover {
        background-color: #fff;
      }
    }
  }
`;

const AddBinSpan = styled.span`
  color: #2c8aff;
  cursor: pointer;

  &:hover {
    color: #0061db;
  }
`;

export const NewBeliefTable: React.FC = () => {
  const intervalBeliefs = useSelector(
    displayedBeliefsSelector({
      interval: true,
      nonInterval: false,
    })
  );

  const selectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const dispatch = useDispatch();

  const handleAddRow = (selectedForecastId) => {
    // We keep track of whether the user has added any row
    // because if the user has added a row, then when a new row
    // mounts, we focus on the first cell. If a user has not
    // added a row yet, then when a row mounts (when loading
    // the belief table initially), we don't want to focus
    // on the first cell of each row as they mount.
    dispatch(setHasAddedRow(true));
    dispatch(
      addIntervalBelief({
        min: null,
        max: null,
        p: null,
        forecastId: selectedForecastId,
      })
    );
  };

  useEffect(() => {
    if (!hasAddedEventListener && selectedForecastId) {
      window.addEventListener("keydown", (e: KeyboardEvent) => {
        if (e.key === "Enter" && (e.target as any).nodeName === "BODY") {
          handleAddRow(selectedForecastId);
        }
      });
      hasAddedEventListener = true;
    }
  });

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (!hasMounted) {
      // We keep track of whether the user has visited the
      // site before, to know whether to display the
      // start here arrow.
      dispatch(setHasVisitedBefore(true));
      setHasMounted(true);
    }
  });

  const [focusedOnCell, setFocusedOnCell] = useState(null);
  const [hoveredOverRow, setHoveredOverRow] = useState(null);

  if (!selectedForecastId) {
    return null;
  }

  return (
    <>
      <Styles>
        <TableHeadings />
        <List
          values={intervalBeliefs}
          onChange={({ oldIndex, newIndex }) => {
            dispatch(
              reorderIntervalBeliefs(
                intervalBeliefs[oldIndex].id,
                newIndex,
                selectedForecastId
              )
            );
          }}
          renderList={({ children, props }) => (
            <div {...props} className="belief-table" style={{ width: "800px" }}>
              {children}
            </div>
          )}
          renderItem={({ value, props, isDragged }) => (
            <div {...props} key={(value as any).id}>
              <BeliefRow
                belief={value}
                isDragged={isDragged}
                focusedOnCell={focusedOnCell}
                setFocusedOnCell={setFocusedOnCell}
                hoveredOverRow={hoveredOverRow}
                setHoveredOverRow={setHoveredOverRow}
              />
            </div>
          )}
        />
      </Styles>
      <div
        style={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          marginTop: "60px",
          marginBottom: "30px",
          justifyItems: "center",
        }}
      >
        {isSelectedForecastEditable ? (
          <AddBinSpan onClick={() => handleAddRow(selectedForecastId)}>
            + Add Bin
          </AddBinSpan>
        ) : (
          <div />
        )}
        <NonIntervalBeliefs />
      </div>
    </>
  );
};
