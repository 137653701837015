import { UpdateDisplayNameAction } from "../reducers/distributionsReducer";

export const updateDisplayName = (
  name: string,
  displayName: string
): UpdateDisplayNameAction => ({
  type: "UPDATE_DISPLAY_NAME",
  name,
  displayName,
});
