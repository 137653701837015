import { Modal, Spinner } from "react-bootstrap";
import React from "react";

export function SubmissionInProgressPage({ submissionStatus }) {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Metaculus Submission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submissionStatus !== "ERROR" ? (
          <>
            Metaculus submission in progress.
            <div style={{ margin: "20px 0", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
            </div>{" "}
          </>
        ) : (
          <>
            Sorry, you granted permission correctly, but something went wrong
            with submitting your distribution.
          </>
        )}
      </Modal.Body>
    </>
  );
}
