import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

const Styles = styled.div`
  .MuiButton-outlinedPrimary {
    border-color: #007bff;
    color: #007bff;
  }

  .MuiButton-outlinedPrimary:hover {
    border-color: #007bff;
  }

  /* !important here to override public.html CSS */
  .MuiButton-contained.active {
    background-color: #1976d2 !important;
    border: 1px solid #1976d2 !important;
    color: #fff !important;
  }
`;

export const FilterBtnGroup = () => {
  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const [
    questionCreatorIdQueryParam,
    setQuestionCreatorIdQueryParam,
  ] = useQueryParam("binaryQuestions.creatorId", StringParam);

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const fetchBinaryQuestions = async function () {
    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    } else if (!user) {
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken: undefined,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    }
  };

  return (
    <Styles>
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          className={questionCreatorIdQueryParam !== "me" && "active"}
          color="primary"
          variant="contained"
          onClick={async () => {
            setQuestionCreatorIdQueryParam(undefined);
            await fetchBinaryQuestions();
          }}
        >
          All Questions
        </Button>
        {user && (
          <Button
            className={questionCreatorIdQueryParam === "me" && "active"}
            color="primary"
            variant="contained"
            onClick={async () => {
              setQuestionCreatorIdQueryParam("me");
              await fetchBinaryQuestions();
            }}
          >
            My Questions
          </Button>
        )}
      </ButtonGroup>
    </Styles>
  );
};
