import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function OpenBoundsTooltip(props) {
  const title =
    props.overOrUnder === "OVER" ? "Upper Bound Open" : "Lower Bound Open";
  return (
    <OverlayTrigger
      key={"top"}
      placement={props.placement}
      trigger={["hover", "focus"]}
      overlay={
        !props.show ? (
          <span style={{ display: "none" }}></span>
        ) : (
          <Popover
            id="popover-basic"
            style={{ maxWidth: "none", width: "400px" }}
          >
            <Popover.Title as="h3">{title}</Popover.Title>
            <Popover.Content>
              Click "Edit" to assign probability{" "}
              {props.overOrUnder === "OVER" ? "above" : "below"} the question
              range.
            </Popover.Content>
          </Popover>
        )
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
