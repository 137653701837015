import { SetLoadingDistributionsToDisplayStatusAction } from "../reducers/statusReducer";

type Status = "PENDING" | "ERROR" | "SUCCESS";

export const setLoadingDistributionsToDisplayStatus = (
  loadingDistributionsToDisplayStatus: Status
): SetLoadingDistributionsToDisplayStatusAction => ({
  type: "SET_LOADING_DISTRIBUTIONS_TO_DISPLAY_STATUS",
  loadingDistributionsToDisplayStatus,
});
