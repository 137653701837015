export interface ScaleData {
  class: string;
  low: number;
  high: number;
  width: number;
}

export interface QuestionMetadata {
  source: string | null;
  sourceUrl: string | null;
  questionId: string | null;
  questionScale: ScaleData | null;
  graphScale: ScaleData | null;
  isUpperBoundClosed: boolean | null;
  isLowerBoundClosed: boolean | null;
  isOpen: boolean | null;
}

export interface QuestionState {
  title: string | null;
  metadata: QuestionMetadata;
}

export interface AddQuestionAction {
  type: "ADD_QUESTION";
  title: string;
  metadata: QuestionMetadata;
}

export interface UpdateQuestionTitleAction {
  type: "UPDATE_QUESTION_TITLE";
  title: string;
}

export interface SetIsOpenAction {
  type: "SET_IS_OPEN";
  isOpen: boolean;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type QuestionAction =
  | AddQuestionAction
  | UpdateQuestionTitleAction
  | SetIsOpenAction
  | ResetStateAction;

const initialState = null;

export function questionReducer(
  state = initialState,
  action: QuestionAction
): QuestionState | null {
  if (action.type === "ADD_QUESTION") {
    return {
      title: action.title,
      metadata: action.metadata,
    };
  }

  if (action.type === "UPDATE_QUESTION_TITLE") {
    return {
      ...state,
      title: action.title,
    };
  }

  if (action.type === "SET_IS_OPEN") {
    return {
      ...state,
      metadata: {
        ...state.metadata,
        isOpen: action.isOpen,
      },
    };
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
