import { Distribution } from "../reducers/distributionsReducer";
import { Forecast } from "../reducers/forecastsReducer";
import { RootState } from "../reducers/rootReducer";

import { addBeliefs } from "./addBeliefs";
import { addDistribution } from "./addDistribution";
import { addForecast } from "./addForecast";

import { replaceSnapshotIds } from "../helpers/snapshots/replaceSnapshotIds";
import { retrieveSnapshot } from "../helpers/snapshots/retrieveSnapshot";
import { updateSnapshot } from "../helpers/snapshots/updateSnapshot";
import { haveSameScale } from "../helpers/haveSameScale";
import { boundsAreSubset } from "../helpers/boundsAreSubset";

import { AppThunk } from "../reducers/rootReducer";

export const importForecast = (
  key: string,
  {
    onError: errorImportingDistribution,
    onDone: doneImportingDistribution,
  }: {
    onDone: () => void;
    onError: (msg: string) => void;
  }
): AppThunk => (dispatch, getState) => {
  const state: RootState = getState();
  if (state.distributions.find((d) => d.name === key)) {
    return errorImportingDistribution("Distribution already imported");
  }

  retrieveSnapshot({
    key,
    onDone: ({
      version: snapshotVersion,
      state: snapshotState,
      createdAt,
      user,
    }) => {
      snapshotState = updateSnapshot(snapshotState, snapshotVersion);

      // We replace all ids so we can import a snapshot into itself
      // without id clashing
      snapshotState = replaceSnapshotIds(snapshotState);

      if (!haveSameScale(snapshotState, state)) {
        return errorImportingDistribution("Distribution not on same scale");
      }

      if (!boundsAreSubset(snapshotState, state)) {
        return errorImportingDistribution("Distribution bounds incompatible");
      }

      const userForecast = snapshotState.forecasts.find(
        (f: Forecast) => f.name === "user"
      );

      userForecast.createdAt = createdAt;

      userForecast.name = key; // change this from "user" to the key
      if (userForecast.type === "editable") {
        userForecast.type = "fixed"; // for now, we can't edit imported forecasts
      }

      const userForecastBeliefIds = [
        ...userForecast.intervalBeliefIds,
        ...userForecast.nonIntervalBeliefIds,
        // TODO: Uncomment line below when we want to include impliedBeliefs
        // ...userForecast.impliedBeliefIds,
      ];

      const beliefs = snapshotState.beliefs.filter((b) =>
        userForecastBeliefIds.includes(b.id)
      );

      const userDist: Distribution = snapshotState.distributions.find(
        (d: Distribution) => d.name === "user"
      );

      // If the importing distribution has an open bound
      // and the imported has a closed bound,
      // set the probability for that bound to 0 for the imported distribution.
      // (Otherwise it would show up as NaN.)
      if (!state.question.metadata.isLowerBoundClosed) {
        if (snapshotState.question.metadata.isLowerBoundClosed) {
          userDist.outOfBounds.pBelow = 0;
        }
      }

      if (!state.question.metadata.isUpperBoundClosed) {
        if (snapshotState.question.metadata.isUpperBoundClosed) {
          userDist.outOfBounds.pAbove = 0;
        }
      }

      userDist.name = key; // change this from "user" to the key

      // make sure key is initial displayed name
      // and it's not overridden by a displayName
      userDist.displayName = user ? user.displayName : key;

      dispatch(addBeliefs(beliefs));
      dispatch(addDistribution(userDist));
      dispatch(addForecast(userForecast));
      doneImportingDistribution();
    },
    onError: (errMsg) => {
      errorImportingDistribution(errMsg);
    },
  });
};
