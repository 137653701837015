import { setHasVisitedBeforeInLocalStorage } from "../helpers/setHasVisitedBeforeInLocalStorage";

import { AppThunk } from "../reducers/rootReducer";
import { SetHasVisitedBeforeAction } from "../reducers/statusReducer";

export function setHasVisitedBefore(hasVisitedBefore: boolean): AppThunk {
  return (dispatch) => {
    setHasVisitedBeforeInLocalStorage(true);

    const action: SetHasVisitedBeforeAction = {
      type: "SET_HAS_VISITED_BEFORE",
      hasVisitedBefore,
    };

    dispatch(action);
  };
}
