import { AppThunk } from "../reducers/rootReducer";

import { fetchAndReplaceBinaryQuestions } from "./fetchAndReplaceBinaryQuestions";

export function fetchAndReplaceBinaryQuestionsAndResetOffset({
  accessToken,
  offsetQueryParam,
  setOffsetQueryParam,
}: {
  accessToken: string;
  offsetQueryParam: number;
  setOffsetQueryParam: (number) => void;
}): AppThunk {
  return async function (dispatch) {
    if (offsetQueryParam !== 0) {
      setOffsetQueryParam(0);
    }

    dispatch(
      fetchAndReplaceBinaryQuestions({
        accessToken,
      })
    );
  };
}
