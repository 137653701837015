import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

export const AuthRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect &&
      loginWithRedirect({
        appState: {
          returnTo: "/profile",
        },
      });
  }, []);

  return <div style={{ textAlign: "center" }}>Redirecting to login</div>;
};
