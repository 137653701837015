import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import immutableStateInvariantMiddleware from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import queryString from "query-string";

import { App } from "./components/App";
import { RenderError } from "./errors/RenderError";
import { rootReducer } from "./reducers/rootReducer";
import { initialState as statusInitialState } from "./reducers/statusReducer";
import * as serviceWorker from "./serviceWorker";
import { retrieveSnapshot } from "./helpers/snapshots/retrieveSnapshot";
import { updateSnapshot } from "./helpers/snapshots/updateSnapshot";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://400eb7a0b0724356be09e38c2abb70d4@o400564.ingest.sentry.io/5259104",
  });
}

const queryParams = queryString.parse(window.location.search);

const regexResultArray = /\/builder\/([^?/]*)/.exec(window.location.pathname);
const snapshotKey = regexResultArray && regexResultArray[1];

const nonDevToolsMiddleware =
  process.env.NODE_ENV === "production"
    ? [thunk]
    : [immutableStateInvariantMiddleware(), thunk];

const middleware = compose(
  applyMiddleware(...nonDevToolsMiddleware),
  (window as any).devToolsExtension
    ? (window as any).devToolsExtension()
    : (f) => f
);

function retrieveSnapshotFromServer() {
  return new Promise(async (resolve, reject) => {
    await retrieveSnapshot({
      key: snapshotKey,
      onDone: ({ version, state }) => {
        resolve({ version, state });
      },
    });
  });
}

function retrieveAndLoadSnapshot(isSecondFetchAttempt = false) {
  retrieveSnapshotFromServer()
    .then(({ state, version }: any) => {
      if (!state) {
        // Happens if Firebase connection succeeded
        // but there's no document with that id.
        // So we just create a default state.
        const store = createStore(rootReducer, middleware);
        startReactApp(store);
        return;
      }

      const hasRefreshed =
        localStorage.getItem("elicit-snapshot-refreshed") === "true";

      if (hasRefreshed) {
        Sentry.captureMessage(
          "Refresh attempt succeeded in retrieving snapshot"
        );
        // We reset this so that in the future if Firebase fails to retrieve
        // the snapshot data we try to refresh the page once.
        // See the catch block below.
        localStorage.setItem("elicit-snapshot-refreshed", "false");
      }

      if (isSecondFetchAttempt) {
        Sentry.captureMessage(
          "Second fetch attempt succeeded in retrieving snapshot"
        );
      }

      state = updateSnapshot(state, version);

      const store = createStore(
        rootReducer,
        {
          ...state,
          beliefsTable: {
            hoveringOverBelief: null,
            focusLowerOutOfBoundsBelief: null,
            focusUpperOutOfBoundsBelief: null,
          },
          hydrated: true,
          sessionSnapshotRecords: [],
          status: {
            ...state.status,
            hasAddedRow: false,
            // We want to update whether the question is open
            // because this may have changed since we saved the
            // snapshot. Only for non-custom questions.
            hasUpdatedIsOpen:
              state.question.metadata.source === "Metaculus" ? false : null,
          },
          user: null,
        },
        middleware
      );

      try {
        startReactApp(store);
      } catch (err) {
        throw new RenderError(
          `Error trying to render snapshot ${snapshotKey}`,
          err
        );
      }
    })
    .catch((err) => {
      Sentry.captureException(err);
      if (err instanceof RenderError) {
        alert(
          "We couldn't load this snapshot. Usually this is because the snapshot is old and is no longer compatible with the app."
        );
      } else {
        // ZJM: As far as I know, this only happens when
        // establishing a connection to Firebase takes over 10
        // seconds and Firebase gives up. EDIT: This sometimes
        // happens right away as well. Unclear why, but seems
        // related to some network problem, potentially occurring
        // in the initialization of Firebase or in the particular fetch
        // attempt.
        if (!isSecondFetchAttempt) {
          return retrieveAndLoadSnapshot(true);
        } else {
          Sentry.captureMessage(
            "Second fetch attempt failed in retrieving snapshot"
          );
        }

        // Following code refreshes the page once.
        // This seems typically sufficient to avoid this error.
        // After one refresh, if still no connection, we display the error message.
        const hasRefreshedAlready =
          localStorage.getItem("elicit-snapshot-refreshed") === "true";

        if (hasRefreshedAlready) {
          localStorage.setItem("elicit-snapshot-refreshed", "false");
          Sentry.captureMessage(
            "Refresh attempt failed in retrieving snapshot"
          );
        } else {
          localStorage.setItem("elicit-snapshot-refreshed", "true");
          // We return below because otherwise app starts
          // to render before reload starts
          console.log(err);
          //return document.location.reload();
        }

        // In this case we load the app with didFailToLoadFirebaseSnapshot
        // set to true, and this displays a simple error message
        // that you can see in App.tsx
        const store = createStore(
          rootReducer,
          {
            status: {
              ...statusInitialState,
              didFailToLoadFirebaseSnapshot: true,
            },
          } as any, // TODO clean up types here
          middleware
        );
        startReactApp(store);
      }
    });
}

if (snapshotKey) {
  retrieveAndLoadSnapshot();
} else {
  const store = createStore(rootReducer, middleware);
  startReactApp(store);
}

function startReactApp(reduxStore) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={reduxStore}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
