import { Button, Popover } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import React from "react";
import styled from "styled-components";

import { Filtering } from "./Filtering";

const Styles = styled.div`
  .MuiButton-outlinedPrimary {
    border-color: #007bff;
    color: #007bff;
  }

  .MuiButton-outlinedPrimary:hover {
    border-color: #007bff;
  }
`;

export const FilterBtn = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  return (
    <Styles>
      <Button variant="outlined" color="primary" onClick={handleClick}>
        <FilterListIcon style={{ marginRight: "10px" }} /> Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "20px" }}>
          <Filtering />
        </div>
      </Popover>
    </Styles>
  );
};
