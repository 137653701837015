import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { HomepageTabs } from "./HomepageTabs";
import { NewQuestionInput } from "../NewQuestionInput";

import { resetState } from "../../actions/resetState";

export function Homepage() {
  const dispatch = useDispatch();

  // Reset state when mounting homepage
  useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <div
      className="elicit-home-page"
      style={{
        minHeight: "100%",
        margin: "200px auto 100px auto",
        width: "800px",
      }}
    >
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://image.thum.io/get/viewportWidth/1400/png/width/1400/crop/640/https://forecast.elicit.org"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1400" />
        <meta property="og:image:height" content="640" />
      </Helmet>
      <NewQuestionInput />
      <HomepageTabs />
    </div>
  );
}
