import { LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP } from "../constants";

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

export const hasBeenLongEnoughToShowPleaseLogin = () => {
  const timestampString = localStorage.getItem(
    LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP
  );

  const hasBeenLongEnough =
    timestampString === null || Date.now() - Number(timestampString) > ONE_WEEK;

  return hasBeenLongEnough;
};
