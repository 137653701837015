import { RootState } from "../reducers/rootReducer";

import { isUpperOutOfBoundsBelief } from "./isUpperOutOfBoundsBelief";
import { displayedBeliefsSelector } from "./displayedBeliefsSelector";

export const isFirstUpperOutOfBoundsBeliefSelector = (beliefId: string) => (
  state: RootState
) => {
  const beliefs = displayedBeliefsSelector({
    interval: true,
    nonInterval: false,
  })(state);

  const upperOutOfBoundsBeliefs = beliefs.filter((b) =>
    isUpperOutOfBoundsBelief(b.id)(state)
  );

  if (
    upperOutOfBoundsBeliefs[0] &&
    upperOutOfBoundsBeliefs[0].id === beliefId
  ) {
    return true;
  }

  return false;
};
