export const shortenProbability = (prob?: number) => {
  if (isNaN(prob)) {
    return null;
  }

  const preliminaryResult = Number(String(prob.toPrecision(3)));
  if (String(preliminaryResult).length > 4) {
    return preliminaryResult.toExponential();
  }
  return preliminaryResult;
};
