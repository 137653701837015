// This updates a field of the belief's "values"
// object with the value argument.
// So only use if the belief in question has a "values"
// object and you want to update a field within this object.
import { UpdateBeliefValueAction } from "../reducers/beliefsReducer";

export const updateBeliefValue = (
  beliefId: string,
  field: string,
  value: any
): UpdateBeliefValueAction => ({
  type: "UPDATE_BELIEF_VALUE",
  beliefId,
  field,
  value,
});
