import React, { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditableCell } from "./EditableCell";
import { EditableNotesTextarea } from "./EditableNotesTextarea";

import { addHoverForBelief } from "../../actions/addHoverForBelief";
import { deleteBelief } from "../../actions/deleteBelief";
import { removeHoverForBelief } from "../../actions/removeHoverForBelief";

import { isSelectedForecastEditableSelector } from "../../selectors/isSelectedForecastEditableSelector";

export const BeliefRow = ({
  belief,
  isDragged,
  focusedOnCell,
  setFocusedOnCell,
  hoveredOverRow,
  setHoveredOverRow,
}) => {
  const dispatch = useDispatch();

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const onMouseEnter = () => {
    if (!isSelectedForecastEditable) {
      return;
    }

    setHoveredOverRow(belief.id);
    if (!focusedOnCell) {
      dispatch(addHoverForBelief(belief.id));
    }
  };

  const onMouseLeave = (e: MouseEvent) => {
    if (!isSelectedForecastEditable) {
      return;
    }

    setHoveredOverRow(null);
    if (focusedOnCell !== belief.id) {
      dispatch(removeHoverForBelief(belief.id));
    }
  };

  return (
    <div
      className="belief-row"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        alignItems: "center",
        backgroundColor: isDragged ? "rgba(246,246,246,0.95)" : undefined,
        boxShadow: isDragged ? "0 0 1px #ccc inset" : undefined,
        display: "flex",
        padding: "2px 20px",
      }}
    >
      <div
        data-movable-handle
        className="move-icon"
        style={{
          cursor: "pointer",
          position: "relative",
          top: "-2px",
          marginRight: "10px",
          visibility: !isSelectedForecastEditable ? "hidden" : "visible",
        }}
      >
        <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/OOjs_UI_icon_draggable.svg" />
      </div>

      <div
        className="belief-cell"
        style={{ display: "inline-block", padding: "5px 8px" }}
      >
        <EditableCell
          belief={belief}
          id={"min"}
          isDragged={isDragged}
          setFocusedOnCell={setFocusedOnCell}
          hoveredOverRow={hoveredOverRow}
        />
      </div>

      <div
        className="belief-cell"
        style={{ display: "inline-block", padding: "5px 8px" }}
      >
        <EditableCell
          belief={belief}
          id={"max"}
          isDragged={isDragged}
          setFocusedOnCell={setFocusedOnCell}
          hoveredOverRow={hoveredOverRow}
        />
      </div>

      <div
        className="belief-cell"
        style={{ display: "inline-block", margin: "8px", padding: "5px 8px" }}
      >
        <EditableCell
          belief={belief}
          id={"p"}
          isDragged={isDragged}
          setFocusedOnCell={setFocusedOnCell}
          hoveredOverRow={hoveredOverRow}
        />
      </div>

      <div
        className="belief-cell"
        style={{ display: "inline-block", padding: "5px 8px" }}
      >
        <EditableNotesTextarea
          belief={belief}
          id={"notes"}
          isDragged={isDragged}
          focusedOnCell={focusedOnCell}
          setFocusedOnCell={setFocusedOnCell}
          hoveredOverRow={hoveredOverRow}
        />
      </div>

      {isSelectedForecastEditable && (
        <span
          className="close-x"
          style={{
            color: "#ff4444",
            cursor: "pointer",
            fontSize: "28px",
            position: "relative",
            top: "-2px",
            left: "-2px",
          }}
          onClick={() => dispatch(deleteBelief(belief.id))}
        >
          ×
        </span>
      )}
    </div>
  );
};
