import { isValidValueInIntervalBelief } from "../helpers/isValidValueInIntervalBelief";
import { isDateQuestionSelector } from "./isDateQuestionSelector";
import { Belief } from "../reducers/beliefsReducer";
import { RootState } from "../reducers/rootReducer";

export const isBeliefValidSelector = (beliefId: string) => (
  state: RootState
) => {
  const belief: Belief = state.beliefs.find((b) => b.id === beliefId);

  const isDateQuestion = isDateQuestionSelector(state);

  if (!belief) {
    return null;
  }

  const ctx = {
    min: belief.values.min,
    max: belief.values.max,
    graphMax: state.question.metadata.graphScale.high,
    graphMin: state.question.metadata.graphScale.low,
    isUpperBoundClosed: state.question.metadata.isUpperBoundClosed,
    isLowerBoundClosed: state.question.metadata.isLowerBoundClosed,
    isDateQuestion,
  };

  const [isValidMin] = isValidValueInIntervalBelief(
    "min",
    belief.values.min,
    ctx
  );
  const [isValidMax] = isValidValueInIntervalBelief(
    "max",
    belief.values.max,
    ctx
  );
  const [isValidP] = isValidValueInIntervalBelief("p", belief.values.p, ctx);
  const [isValidNotes] = isValidValueInIntervalBelief(
    "notes",
    belief.notes,
    ctx
  );

  return isValidMin && isValidMax && isValidP && isValidNotes;
};
