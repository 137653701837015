export interface Belief {
  id: string;
  isActive: boolean;
  type: string;
  label: string;
  subLabels?: string[];
  values?: any;
  notes: string | null;
}

interface IntervalValues {
  p: number;
  min: number;
  max: number;
}

export interface IntervalBelief extends Belief {
  type: "interval";
  values: IntervalValues;
}

export interface ComparisonBelief extends Belief {
  values: any;
  comparisonId: string;
  type: "comparison";
}

export interface AddBeliefsAction {
  type: "ADD_BELIEFS";
  beliefs: Belief[];
}

export interface UpdateImpliedBeliefsAction {
  type: "UPDATE_IMPLIED_BELIEFS";
  oldImpliedBeliefIds: string[];
  impliedBeliefs: Belief[];
}

export interface AddIntervalBeliefAction {
  type: "ADD_INTERVAL_BELIEF";
  beliefFields: any;
}

interface AddOutOfBoundsIntervalBeliefAction {
  type: "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF";
  beliefId: string;
  max: number;
  min: number;
}

export interface DeleteBeliefAction {
  type: "DELETE_BELIEF";
  beliefId: string;
}

export interface ToggleBeliefIsActiveAction {
  type: "TOGGLE_BELIEF_IS_ACTIVE";
  beliefId: string;
}

export interface UpdateBeliefAction {
  type: "UPDATE_BELIEF";
  beliefId: string;
  field: string;
  value: any;
}

export interface UpdateBeliefValueAction {
  type: "UPDATE_BELIEF_VALUE";
  beliefId: string;
  field: string;
  value: any;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type BeliefsAction =
  | AddBeliefsAction
  | UpdateImpliedBeliefsAction
  | AddIntervalBeliefAction
  | AddOutOfBoundsIntervalBeliefAction
  | DeleteBeliefAction
  | ToggleBeliefIsActiveAction
  | UpdateBeliefAction
  | UpdateBeliefValueAction
  | ResetStateAction;

const initialState = [] as Belief[];

export function beliefsReducer(
  state: Belief[] = initialState,
  action: BeliefsAction
) {
  if (action.type === "ADD_BELIEFS") {
    return state.concat(action.beliefs);
  }

  if (action.type === "UPDATE_IMPLIED_BELIEFS") {
    const filteredState = state.filter(
      (b) => !action.oldImpliedBeliefIds.includes(b.id)
    );
    return filteredState.concat(action.impliedBeliefs);
  }

  if (action.type === "ADD_INTERVAL_BELIEF") {
    return [
      ...state,
      {
        ...action.beliefFields,
        type: "interval",
        isActive: false,
      },
    ];
  }

  if (action.type === "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF") {
    return [
      ...state,
      {
        id: action.beliefId,
        values: {
          min: action.min,
          max: action.max,
          p: null,
        },
        isActive: false,
        type: "interval",
      },
    ];
  }

  if (action.type === "DELETE_BELIEF") {
    return state.filter((belief) => belief.id !== action.beliefId);
  }

  if (action.type === "TOGGLE_BELIEF_IS_ACTIVE") {
    return state.map((belief) => {
      if (belief.id !== action.beliefId) {
        return belief;
      } else {
        return {
          ...belief,
          isActive: !belief.isActive,
        };
      }
    });
  }

  if (action.type === "UPDATE_BELIEF") {
    return state.map((belief) => {
      if (belief.id !== action.beliefId) {
        return belief;
      } else {
        return { ...belief, [action.field]: action.value };
      }
    });
  }

  if (action.type === "UPDATE_BELIEF_VALUE") {
    return state.map((belief) => {
      if (belief.id !== action.beliefId) {
        return belief;
      } else {
        return {
          ...belief,
          values: { ...belief.values, [action.field]: action.value },
        };
      }
    });
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
