import * as Sentry from "@sentry/browser";

import { AppThunk } from "../reducers/rootReducer";

import { addQuestion } from "./addQuestion";
import { fetchStartingForecasts } from "./fetchStartingForecasts";

export function loadCustomQuestion(
  {
    title,
    low,
    high,
    scale,
    lowOpen,
    highOpen,
    questionId,
  }: {
    title: string;
    low: number;
    high: number;
    scale: string | null;
    lowOpen: boolean;
    highOpen: boolean;
    questionId: string;
  },
  onDone?: () => void,
  onError?: () => void
): AppThunk {
  return async function (dispatch) {
    try {
      const metadata = {
        source: null,
        sourceUrl: null,
        questionId,
        questionScale: {
          class: scale || "Scale",
          low,
          high,
          logBase: high / low,
          width: high - low,
        },
        graphScale: {
          class: scale || "Scale",
          low,
          high,
          logBase: high / low,
          width: high - low,
        },
        isUpperBoundClosed: !highOpen,
        isLowerBoundClosed: !lowOpen,
        isOpen: null,
      };

      dispatch(addQuestion(title, metadata));

      dispatch(fetchStartingForecasts(metadata));

      onDone && onDone();
    } catch (e) {
      Sentry.captureException(e);
      onError && onError();
    }
  };
}
