import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import sortBy from "lodash.sortby";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { predictorsOtherThanYouSelector } from "../selectors/predictorsOtherThanYouSelector";

const Styles = styled.div`
  .MuiInputBase-input {
    font-size: 14px;
  }
`;

export function AddPredictorDropdown() {
  const [predictorsQueryParam, setPredictorsQueryParam] = useQueryParam(
    "predictors",
    StringParam
  );

  const predictorsOtherThanYou = useSelector(predictorsOtherThanYouSelector);

  const predictorIdsWithPotentiallyCommunity = predictorsQueryParam
    ? predictorsQueryParam.split(",")
    : [];

  const predictorsOtherThanYouNotDisplayed = sortBy(
    predictorsOtherThanYou.filter(
      (p) => !predictorIdsWithPotentiallyCommunity.includes(p.id)
    ),
    (p) => p.displayName
  );

  const shouldIncludeCommunity = !predictorIdsWithPotentiallyCommunity.includes(
    "community"
  );

  const autocompleteEl = useRef(null);

  return (
    <Styles>
      <Autocomplete
        ref={autocompleteEl}
        id="view-user-autocomplete"
        value={null}
        onChange={(e, obj) => {
          setPredictorsQueryParam(
            !predictorsQueryParam ? obj.id : predictorsQueryParam + `,${obj.id}`
          );

          const inputEl =
            autocompleteEl.current.children[0].children[1].children[0];

          inputEl.style.color = "transparent";

          setTimeout(() => {
            inputEl.blur();
            inputEl.style.color = "rgba(0, 0, 0, 0.87)";
          }, 100);
        }}
        clearOnBlur={true}
        options={
          shouldIncludeCommunity
            ? [
                {
                  id: "community",
                },
                ...predictorsOtherThanYouNotDisplayed,
              ]
            : predictorsOtherThanYouNotDisplayed
        }
        getOptionLabel={(obj) =>
          obj.id === "community" ? "Community" : obj.displayName
        }
        renderInput={(params) => (
          <TextField {...params} label="Show predictor" margin="normal" />
        )}
        style={{
          fontSize: "12px",
          marginTop: "-18px",
          width: "150px",
        }}
      />
    </Styles>
  );
}
