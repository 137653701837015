import * as Sentry from "@sentry/browser";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { SNAPSHOT_VERSION } from "../constants";

import { addDistributionForForecast } from "../actions/addDistributionForForecast";
import { addBeliefFits } from "../actions/addBeliefFits";
import { createForecast } from "../actions/createForecast";
import { setLoadingDistributionsToDisplayStatus } from "../actions/setLoadingDistributionsToDisplayStatus";
import { setSnapshotVersion } from "../actions/setSnapshotVersion";
import { updateDistribution } from "../actions/updateDistribution";
import { updateImpliedBeliefs } from "../actions/updateImpliedBeliefs";

import { areBeliefsEqualInRelevantWays } from "../helpers/areBeliefsEqualInRelevantWays";
import { distributionFromFramedHistogram } from "../helpers/distributionFromFramedHistogram";
import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { RootState } from "../reducers/rootReducer";

import { areAllIntervalBeliefsOfSelectedForecastValidSelector } from "../selectors/areAllIntervalBeliefsOfSelectedForecastValidSelector";
import { displayedBeliefsSelector } from "../selectors/displayedBeliefsSelector";

let loadingAttempts = [] as number[];

export function FetchDistributionsToDisplayOnChange() {
  const forecasts = useSelector((state: RootState) => state.forecasts);
  const distributions = useSelector((state: RootState) => state.distributions);

  const selectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );
  const selectedForecast = forecasts.find((f) => f.id === selectedForecastId);
  const prevForecastIdRef = useRef<string | null>(null);

  const beliefs = useSelector(
    displayedBeliefsSelector({
      interval: true,
      nonInterval: true,
    })
  );
  const prevBeliefsRef = useRef([] as any[]);
  const prevShowSubmissionRef = useRef(null);

  const showSubmission = useSelector(
    (state: RootState) => state.showSubmission
  );

  const metadata = useSelector((state: RootState) => state.question?.metadata);
  const dispatch = useDispatch();

  const [hasMounted, setHasMounted] = useState(false);

  const areAllBeliefsValid = useSelector(
    areAllIntervalBeliefsOfSelectedForecastValidSelector
  );

  const isFetchingDistributions = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "PENDING"
  );

  useEffect(() => {
    if (
      areBeliefsEqualInRelevantWays(prevBeliefsRef.current, beliefs) &&
      showSubmission.showCount === prevShowSubmissionRef.current
    ) {
      prevBeliefsRef.current = beliefs;
      prevShowSubmissionRef.current = showSubmission.showCount;
      return;
    }
    prevBeliefsRef.current = beliefs;
    prevShowSubmissionRef.current = showSubmission.showCount;

    // we don't want to re-fit on selected forecast change unless
    // prev forecast is null
    // TODO: change this when copying beliefs is implemented
    if (prevForecastIdRef.current !== selectedForecastId) {
      if (prevForecastIdRef.current !== null) {
        prevForecastIdRef.current = selectedForecastId;
        return;
      }
      prevForecastIdRef.current = selectedForecastId;
    }

    if (beliefs.length === 0 || !areAllBeliefsValid) {
      return;
    }

    // Typically, we don't want to fetch distributions
    // on initial page load. So if hasMounted is false, we
    // set hasMounted to true and return.
    // There are two situations in which we want to
    // to fetch  distributions immediately on page load.
    // First, if there are no implied beliefs, because
    // that indicates this is a snapshot originally created
    // as version < 3, prior to implied beliefs.
    // Second, if the snapshot was taken while fetching
    // distributions from the backend.
    if (
      !hasMounted &&
      selectedForecast &&
      selectedForecast.impliedBeliefIds.length &&
      !isFetchingDistributions
    ) {
      setHasMounted(true);
      return;
    }

    const loadingTimestamp = Date.now();

    loadingAttempts = [...loadingAttempts, loadingTimestamp];

    dispatch(setLoadingDistributionsToDisplayStatus("PENDING"));

    const fetchAndLoadData = async () => {
      let data;
      try {
        const response = await makeRequestToBackend({
          path: "/fit",
          method: "POST",
          body: {
            beliefs,
            metadata,
            returnSubmission: showSubmission.show,
          },
        });
        data = await response.json();

        if (!response.ok) {
          if (data.invalidBeliefs) {
            alert(data.invalidBeliefs);
          }
          throw Error(data.error);
        }
      } catch (e) {
        Sentry.captureException(e);

        const isMostRecentLoadingAttempt =
          loadingAttempts.slice(-1)[0] === loadingTimestamp;

        if (isMostRecentLoadingAttempt) {
          loadingAttempts = [];
          dispatch(setLoadingDistributionsToDisplayStatus("ERROR"));
        }

        return;
      }

      const isMostRecentLoadingAttempt =
        loadingAttempts.slice(-1)[0] === loadingTimestamp;

      if (isMostRecentLoadingAttempt) {
        loadingAttempts = [];

        data.forEach((fittedDistribution) => {
          const existingDistribution = distributions.find(
            (dist) => dist.name === fittedDistribution.framedHistogram.name
          );

          const distributionId =
            (existingDistribution && existingDistribution.id) || uuidv4();

          const distribution = distributionFromFramedHistogram({
            framedHistogram: fittedDistribution.framedHistogram,
            id: distributionId,
          });

          const existingForecast = forecasts.find(
            (forecast) =>
              forecast.name === fittedDistribution.framedHistogram.name
          );

          const forecastId = existingForecast?.id || uuidv4();

          if (existingDistribution) {
            dispatch(updateDistribution(existingDistribution.id, distribution));
          } else if (existingForecast) {
            dispatch(addDistributionForForecast(distribution, forecastId));
          } else {
            dispatch(
              createForecast({
                id: forecastId,
                name: distribution.name,
                type: "fixed_distribution",
                beliefs: [],
                distribution: distribution,
              })
            );
          }

          if (fittedDistribution.beliefFits) {
            dispatch(
              addBeliefFits(distributionId, fittedDistribution.beliefFits)
            );
          }
        });

        dispatch(setLoadingDistributionsToDisplayStatus("SUCCESS"));

        // If we just fit the distribution,
        // we know that it's now the most recent version
        dispatch(setSnapshotVersion(SNAPSHOT_VERSION));
      }
    };

    fetchAndLoadData();

    setHasMounted(true);
  }, [beliefs, showSubmission.showCount]);

  return null;
}
