import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

const Styles = styled.div`
  .MuiInputBase-input {
    font-size: 16px;
  }
`;

export function Sorting() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [sortByQueryParam, setSortByQueryParam] = useQueryParam(
    "binaryQuestions.sortBy",
    StringParam
  );

  const [sortBySelectValue, setSortBySelectValue] = useState(
    sortByQueryParam || "popularity"
  );

  const dispatch = useDispatch();

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const fetchBinaryQuestions = async function (e) {
    setSortByQueryParam(e.target.value);
    setSortBySelectValue(e.target.value);

    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    } else if (!user) {
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken: undefined,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    }
  };

  return (
    <Styles style={{ marginTop: "-20px" }}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sortBySelectValue}
          onChange={fetchBinaryQuestions}
          style={{ width: "250px" }}
        >
          <MenuItem value="popularity">Popularity</MenuItem>
          <MenuItem value="-createdAt">Created At: Descending</MenuItem>
          <MenuItem value="createdAt">Created At: Ascending</MenuItem>
          <MenuItem value="-resolvesBy">Resolving At: Descending</MenuItem>
          <MenuItem value="resolvesBy">Resolving At: Ascending</MenuItem>
        </Select>
      </FormControl>
    </Styles>
  );
}
