import { RootState } from "../reducers/rootReducer";

export const isSelectedForecastDistributionOnlySelector = (
  state: RootState
) => {
  const selectedForecastId = state.status.selectedForecastId;

  const selectedForecast = state.forecasts.find(
    (f) => f.id === selectedForecastId
  );

  if (selectedForecast?.type === "fixed_distribution") {
    return true;
  }

  return false;
};
