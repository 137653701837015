import {
  AddBeliefFitsAction,
  FitDescription,
} from "../reducers/beliefFitsReducer";

export const addBeliefFits = (
  distributionId: string,
  beliefFits: { [beliefId: string]: FitDescription }
): AddBeliefFitsAction => ({
  type: "ADD_BELIEF_FITS",
  distributionId,
  beliefFits,
});
