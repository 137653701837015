import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { PersistentSnapshotRecords } from "./PersistentSnapshotRecords";
import { PleaseLoginModal } from "./PleaseLoginModal";
import { SnapshotRecords } from "./SnapshotRecords";

import { SubmitForecastModal } from "./submitForecastModal/SubmitForecastModal";

import { createSnapshot } from "../actions/createSnapshot";
import { addSnapshotRecord } from "../actions/addSnapshotRecord";

import { RootState } from "../reducers/rootReducer";

import { isUserForecastSelectedSelector } from "../selectors/isUserForecastSelectedSelector";
import { hasBeenLongEnoughToShowPleaseLogin } from "../helpers/hasBeenLongEnoughToShowPleaseLogin";

export function OptionsColumns() {
  const dispatch = useDispatch();

  const isUserForecastSelected = useSelector(isUserForecastSelectedSelector);

  const isLoadingDistributions = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "PENDING"
  );

  const isMetaculusQuestion = useSelector(
    (state: RootState) => state.question.metadata.source === "Metaculus"
  );

  const hasQuestionId = useSelector(
    (state: RootState) => state.question.metadata.questionId
  );

  const question = useSelector((state: RootState) => state.question);

  const [isSavePending, setIsSavePending] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const [shouldShowPleaseLoginModal, setShouldShowPleaseLoginModal] = useState(
    false
  );

  const saveSnapshot = async () => {
    setIsSavePending(true);
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      createSnapshot({
        accessToken,
        onDone: (key) => {
          const timestamp = Date.now();

          dispatch(addSnapshotRecord(key, timestamp));

          const url = `${window.location.origin}/builder/${key}`;
          window.history.pushState({}, "", url);

          setIsSavePending(false);
        },
      })
    );
  };

  const canSubmitForecast =
    question &&
    question.metadata &&
    question.metadata.questionId &&
    question.metadata.isOpen;

  const shouldShowSubmitBtn = canSubmitForecast && isUserForecastSelected;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          overflowX: "visible",
          textAlign: "center",
        }}
      >
        <Button
          onClick={() => {
            saveSnapshot();
            if (!user && hasBeenLongEnoughToShowPleaseLogin()) {
              setShouldShowPleaseLoginModal(true);
            }
          }}
          disabled={isSavePending || isLoadingDistributions}
        >
          Save Snapshot
        </Button>
        <PleaseLoginModal
          show={shouldShowPleaseLoginModal}
          handleClose={() => setShouldShowPleaseLoginModal(false)}
        />
        <br />
        <div
          style={{
            display: "inline-block",
            marginTop: "10px",
            whiteSpace: "nowrap",
            width: "1px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {isMetaculusQuestion || !hasQuestionId || !user ? (
              <SnapshotRecords />
            ) : (
              <PersistentSnapshotRecords />
            )}
          </div>
        </div>
      </div>

      {(window as any).Intercom && (
        <div style={{ marginLeft: "30px", textAlign: "left" }}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="get-help-tooltip" {...props}>
                We can help you make this prediction. Click to start a chat!
              </Tooltip>
            )}
          >
            <Button
              onClick={() => {
                (window as any).Intercom("boot", { user_data: user });
                (window as any).Intercom(
                  "showNewMessage",
                  "Can you help me..."
                );
              }}
            >
              Get Help
            </Button>
          </OverlayTrigger>
        </div>
      )}

      {shouldShowSubmitBtn && (
        <div style={{ marginLeft: "30px", textAlign: "left" }}>
          {" "}
          <SubmitForecastModal />
        </div>
      )}
    </div>
  );
}
