import { AddSnapshotRecordAction } from "../reducers/sessionSnapshotRecordsReducer";

type Timestamp = number;

export const addSnapshotRecord = (
  key: string,
  createdAt: Timestamp
): AddSnapshotRecordAction => ({
  type: "ADD_SNAPSHOT_RECORD",
  key,
  createdAt,
});
