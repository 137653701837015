import moment from "moment";
import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../../../constants";
import { processProbValue } from "../../../helpers/processProbValue";
import { formatNumberString } from "../../../helpers/formatNumberString";

export function convertToInput(
  id: string,
  value: any,
  isDateQuestion: boolean
) {
  if (id === "max") {
    if (value === null) {
      return "Max";
    }

    if (value === undefined) {
      return "Max";
    }

    if (isDateQuestion) {
      return moment
        .utc(value * SERVER_TO_MS_TIMESTAMP_MULTIPLIER)
        .format("YYYY-MM-DD");
    }

    return formatNumberString(String(value));
  }

  if (id === "min") {
    if (value === null) {
      return "Min";
    }

    if (value === undefined) {
      return "Min";
    }

    if (isDateQuestion) {
      return moment
        .utc(value * SERVER_TO_MS_TIMESTAMP_MULTIPLIER)
        .format("YYYY-MM-DD");
    }

    return formatNumberString(String(value));
  }

  if (id === "p") {
    // if (!value) would mess up 0
    if (value === null || value === undefined) {
      return "%";
    }

    return processProbValue(value) + "%";
  }

  if (id === "notes") {
    return value;
  }
}
