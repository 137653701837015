import { getHasVisitedBeforeFromLocalStorage } from "../helpers/getHasVisitedBeforeFromLocalStorage";

import { Distribution } from "./distributionsReducer";
import { QuestionMetadata } from "./questionReducer";

type Status = "PENDING" | "ERROR" | "SUCCESS" | null;
export type TabScreen = "BUILD" | "INTERPRET" | "NOTES";

export interface StatusState {
  binaryQuestionText: string | null;
  checkingClosenessToSubmissionStatus: Status;
  didFailToLoadFirebaseSnapshot: boolean;
  hasAddedRow: boolean;
  hasUpdatedIsOpen: boolean | null;
  hasVisitedBefore: boolean;
  idOfHighlightedDistribution: string | null;
  isCloseEnoughToSubmission: boolean | null;
  isEnteringInCustomQuestion: boolean;
  loadingBeliefsStatus: Status;
  loadingDistributionsToDisplayStatus: Status;
  loadingSearchPageQuestionsStatus: Status;
  namesOfVisibleDistributions: string[];
  selectedForecastId: string | null;
  shouldShowBinaryToCustomFlow: boolean;
  shouldShowSubmissionOnLegend: boolean;
  shouldShowStartArrow: boolean;
  submissionFlowScreen: "SKIP_CLOSENESS" | null;
  tabScreen: TabScreen;
  version: number | null;
}

// note: this initial state is duplicated at ../../node-backend/src/app/snapshots-router/create-snapshot-from-densities.ts
export const initialState = {
  binaryQuestionText: null,
  checkingClosenessToSubmissionStatus: null,
  didFailToLoadFirebaseSnapshot: false,
  hasAddedRow: false,
  hasUpdatedIsOpen: null,
  hasVisitedBefore: getHasVisitedBeforeFromLocalStorage(),
  idOfHighlightedDistribution: null,
  isCloseEnoughToSubmission: null,
  isEnteringInCustomQuestion: false,
  loadingBeliefsStatus: null,
  loadingDistributionsToDisplayStatus: null,
  loadingSearchPageQuestionsStatus: null,
  namesOfVisibleDistributions: [],
  selectedForecastId: null,
  shouldShowBinaryToCustomFlow: false,
  shouldShowSubmissionOnLegend: false,
  shouldShowStartArrow: !getHasVisitedBeforeFromLocalStorage(),
  submissionFlowScreen: null,
  tabScreen: "BUILD" as TabScreen,
  version: null,
};

export interface SetLoadingBeliefsStatusAction {
  type: "SET_LOADING_BELIEFS_STATUS";
  loadingBeliefsStatus: Status;
}

interface AddOutOfBoundsIntervalAction {
  type: "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF";
}

export interface SetLoadingDistributionsToDisplayStatusAction {
  type: "SET_LOADING_DISTRIBUTIONS_TO_DISPLAY_STATUS";
  loadingDistributionsToDisplayStatus: Status;
}

export interface SetCheckingClosenessToSubmissionStatusAction {
  type: "SET_CHECKING_CLOSENESS_TO_SUBMISSION_STATUS";
  checkingClosenessToSubmissionStatus: Status;
}

export interface SetIsCloseEnoughToSubmissionAction {
  type: "SET_IS_CLOSE_ENOUGH_TO_SUBMISSION";
  isCloseEnoughToSubmission: boolean;
}

export interface SetHasVisitedBeforeAction {
  type: "SET_HAS_VISITED_BEFORE";
  hasVisitedBefore: boolean;
}

export interface SetShouldShowStartArrowAction {
  type: "SET_SHOULD_SHOW_START_ARROW";
  shouldShowStartArrow: boolean;
}

export interface SetHasAddedRowAction {
  type: "SET_HAS_ADDED_ROW";
  hasAddedRow: boolean;
}

export interface SetShouldShowSubmissionOnLegendAction {
  type: "SET_SHOULD_SHOW_SUBMISSION_ON_LEGEND";
  shouldShowSubmissionOnLegend: boolean;
}

export interface SetSubmissionFlowScreenAction {
  type: "SET_SUBMISSION_FLOW_SCREEN";
  submissionFlowScreen: "SKIP_CLOSENESS";
}

export interface SetIsEnteringInCustomQuestionAction {
  type: "SET_IS_ENTERING_IN_CUSTOM_QUESTION";
  isEnteringInCustomQuestion: boolean;
}

export interface SetShouldShowBinaryToCustomFlowAction {
  type: "SET_SHOULD_SHOW_BINARY_TO_CUSTOM_FLOW";
  shouldShowBinaryToCustomFlow: boolean;
}

export interface SetBinaryQuestionTextAction {
  type: "SET_BINARY_QUESTION_TEXT";
  binaryQuestionText: string;
}

export interface SetLoadingSearchPageQuestionsStatusAction {
  type: "SET_LOADING_SEARCH_PAGE_QUESTIONS_STATUS";
  loadingSearchPageQuestionsStatus: Status;
}

interface AddDistributionAction {
  type: "ADD_DISTRIBUTION";
  distribution: Distribution;
}

export interface UpdateNamesOfVisibleDistributionsAction {
  type: "UPDATE_NAMES_OF_VISIBLE_DISTRIBUTIONS";
  isVisible: boolean;
  name: string;
}

export interface SetIsOpenAction {
  type: "SET_IS_OPEN";
}

interface AddQuestionAction {
  type: "ADD_QUESTION";
  metadata: QuestionMetadata;
}

export interface SetTabScreenAction {
  type: "SET_TAB_SCREEN";
  tabScreen: "BUILD" | "INTERPRET" | "NOTES";
}

export interface SelectForecastAction {
  type: "SELECT_FORECAST";
  selectedForecastId: string;
}

export interface SetSnapshotVersionAction {
  type: "SET_SNAPSHOT_VERSION";
  version: number;
}

export interface HighlightPlotAction {
  type: "HIGHLIGHT_PLOT";
  distributionId: string;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type StatusAction =
  | SetLoadingBeliefsStatusAction
  | AddOutOfBoundsIntervalAction
  | SetLoadingDistributionsToDisplayStatusAction
  | SetCheckingClosenessToSubmissionStatusAction
  | SetIsCloseEnoughToSubmissionAction
  | SetHasVisitedBeforeAction
  | SetShouldShowStartArrowAction
  | SetHasAddedRowAction
  | SetShouldShowSubmissionOnLegendAction
  | SetSubmissionFlowScreenAction
  | SetIsEnteringInCustomQuestionAction
  | SetShouldShowBinaryToCustomFlowAction
  | SetBinaryQuestionTextAction
  | SetLoadingSearchPageQuestionsStatusAction
  | AddDistributionAction
  | UpdateNamesOfVisibleDistributionsAction
  | SetIsOpenAction
  | AddQuestionAction
  | SetTabScreenAction
  | SelectForecastAction
  | SetSnapshotVersionAction
  | HighlightPlotAction
  | ResetStateAction;

export function statusReducer(
  state: StatusState = initialState,
  action: StatusAction
): StatusState {
  if (action.type === "SET_LOADING_BELIEFS_STATUS") {
    return {
      ...state,
      loadingBeliefsStatus: action.loadingBeliefsStatus,
    };
  }

  if (action.type === "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF") {
    return { ...state, hasAddedRow: true };
  }

  if (action.type === "SET_LOADING_DISTRIBUTIONS_TO_DISPLAY_STATUS") {
    return {
      ...state,
      loadingDistributionsToDisplayStatus:
        action.loadingDistributionsToDisplayStatus,
    };
  }

  if (action.type === "SET_CHECKING_CLOSENESS_TO_SUBMISSION_STATUS") {
    return {
      ...state,
      checkingClosenessToSubmissionStatus:
        action.checkingClosenessToSubmissionStatus,
    };
  }

  if (action.type === "SET_IS_CLOSE_ENOUGH_TO_SUBMISSION") {
    return {
      ...state,
      isCloseEnoughToSubmission: action.isCloseEnoughToSubmission,
    };
  }

  if (action.type === "SET_HAS_VISITED_BEFORE") {
    return {
      ...state,
      hasVisitedBefore: action.hasVisitedBefore,
    };
  }

  if (action.type === "SET_SHOULD_SHOW_START_ARROW") {
    return {
      ...state,
      shouldShowStartArrow: action.shouldShowStartArrow,
    };
  }

  if (action.type === "SET_HAS_ADDED_ROW") {
    return {
      ...state,
      hasAddedRow: action.hasAddedRow,
    };
  }

  if (action.type === "SET_SHOULD_SHOW_SUBMISSION_ON_LEGEND") {
    return {
      ...state,
      shouldShowSubmissionOnLegend: action.shouldShowSubmissionOnLegend,
    };
  }

  if (action.type === "SET_SUBMISSION_FLOW_SCREEN") {
    return {
      ...state,
      submissionFlowScreen: action.submissionFlowScreen,
    };
  }

  if (action.type === "SET_IS_ENTERING_IN_CUSTOM_QUESTION") {
    return {
      ...state,
      isEnteringInCustomQuestion: action.isEnteringInCustomQuestion,
    };
  }

  if (action.type === "SET_SHOULD_SHOW_BINARY_TO_CUSTOM_FLOW") {
    return {
      ...state,
      shouldShowBinaryToCustomFlow: action.shouldShowBinaryToCustomFlow,
    };
  }

  if (action.type === "SET_BINARY_QUESTION_TEXT") {
    return {
      ...state,
      binaryQuestionText: action.binaryQuestionText,
    };
  }

  if (action.type === "SET_LOADING_SEARCH_PAGE_QUESTIONS_STATUS") {
    return {
      ...state,
      loadingSearchPageQuestionsStatus: action.loadingSearchPageQuestionsStatus,
    };
  }

  if (action.type === "ADD_DISTRIBUTION") {
    // Add name if new
    if (state.namesOfVisibleDistributions.includes(action.distribution.name)) {
      return state;
    }

    return {
      ...state,
      namesOfVisibleDistributions: state.namesOfVisibleDistributions.concat(
        action.distribution.name
      ),
    };
  }

  if (action.type === "UPDATE_NAMES_OF_VISIBLE_DISTRIBUTIONS") {
    if (action.isVisible) {
      if (state.namesOfVisibleDistributions.includes(action.name)) {
        return state;
      }

      return {
        ...state,
        namesOfVisibleDistributions: state.namesOfVisibleDistributions.concat(
          action.name
        ),
      };
    } else {
      if (!state.namesOfVisibleDistributions.includes(action.name)) {
        return state;
      }

      return {
        ...state,
        namesOfVisibleDistributions: state.namesOfVisibleDistributions.filter(
          (n) => n !== action.name
        ),
      };
    }
  }

  if (action.type === "SET_IS_OPEN") {
    return {
      ...state,
      hasUpdatedIsOpen: true,
    };
  }

  if (action.type === "ADD_QUESTION") {
    const didSetIsOpen =
      action.metadata.isOpen === true || action.metadata.isOpen === false;

    if (didSetIsOpen) {
      return {
        ...state,
        hasUpdatedIsOpen: true,
      };
    }
  }

  if (action.type === "SET_TAB_SCREEN") {
    return {
      ...state,
      tabScreen: action.tabScreen,
    };
  }

  if (action.type === "SELECT_FORECAST") {
    return {
      ...state,
      selectedForecastId: action.selectedForecastId,
    };
  }

  if (action.type === "SET_SNAPSHOT_VERSION") {
    return {
      ...state,
      version: action.version,
    };
  }

  if (action.type === "HIGHLIGHT_PLOT") {
    return {
      ...state,
      idOfHighlightedDistribution: action.distributionId,
    };
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
