import {
  Distribution,
  UpdateDistributionAction,
} from "../reducers/distributionsReducer";

export const updateDistribution = (
  id: string,
  distribution: Distribution
): UpdateDistributionAction => ({
  type: "UPDATE_DISTRIBUTION",
  id,
  distribution,
});
