import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { createQuestion } from "../actions/createQuestion";

import { getSearchFormPlaceholder } from "../helpers/getSearchFormPlaceholder";

import { RootState } from "../reducers/rootReducer";

import { BinaryToCustomFlowModal } from "./BinaryToCustomFlowModal";
import { NewQuestionForm } from "./NewQuestionForm";

const searchFormPlaceholder = getSearchFormPlaceholder();

export const NewQuestionInput = withRouter((props) => {
  const [questionInput, setQuestionInput] = useState("");
  const [questionStatus, setQuestionStatus] = useState(null);

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");

  const handleNewQuestion = () => {
    setQuestionStatus(null);
    dispatch(
      createQuestion({
        newQuestionInput: questionInput,
        onPending: () => setQuestionStatus("PENDING"),
        onDone: () => setQuestionStatus("SUCCESS"),
        onError: (errMsg) => {
          setQuestionStatus("ERROR");
          setErrorMessage(errMsg);
        },
        onBinaryInput: (input: string) => {
          props.history.push({
            pathname: "/binary",
            search: `?fillTitle=${input}`,
          });
        },
        setNoLongerPending: () => setQuestionStatus(null),
      })
    );
  };

  const question = useSelector((state: RootState) => state.question);
  const metadata = question && question.metadata;
  const questionId = metadata && metadata.questionId;

  const isEnteringInCustomQuestion = useSelector(
    (state: RootState) => state.status.isEnteringInCustomQuestion
  );

  useEffect(() => {
    if (questionStatus === "SUCCESS") {
      props.history.push({
        pathname: "/builder",
        search: questionId && `?q_id=${questionId}`,
      });
    }
  }, [questionStatus]);

  return (
    <>
      <BinaryToCustomFlowModal />
      <form noValidate autoComplete="off">
        <TextField
          id="standard-basic"
          placeholder={searchFormPlaceholder}
          fullWidth
          onChange={(e) => setQuestionInput(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
              handleNewQuestion();
              e.preventDefault();
            }
          }}
          value={questionInput}
          disabled={questionStatus === "PENDING"}
          helperText={
            questionStatus === "ERROR"
              ? errorMessage
              : isEnteringInCustomQuestion
              ? "Nice question! Now choose the question type and a rough lower and upper bound."
              : "Enter a question you want to forecast"
          }
        />
        {isEnteringInCustomQuestion && (
          <NewQuestionForm questionInput={questionInput} />
        )}
      </form>
    </>
  );
});
