import { getDistColorForLabel } from "../helpers/getDistColor";

import { RootState } from "../reducers/rootReducer";

import { getIndexOfDistFunctionSelector } from "../selectors/getIndexOfDistFunctionSelector";

export const getForecastLabelColorFunctionSelector = (state: RootState) => (
  forecastId
) => {
  const forecast = state.forecasts.find((f) => f.id === forecastId);

  if (!forecast) {
    return;
  }

  const distribution = state.distributions.find(
    (d) => d.id === forecast.distributionId
  );

  if (!distribution) {
    return;
  }

  const distIndex = getIndexOfDistFunctionSelector(state)(distribution.name);

  const distColor = getDistColorForLabel(distribution.name, distIndex);

  return distColor;
};
