import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { isInInlineMode } from "../helpers/isInInlineMode";
import { LoginButton } from "./LoginBtn";
import { LogoutBtn } from "./LogoutBtn";
import { useLocation } from "react-router-dom";

import { RootState } from "../reducers/rootReducer";

const BrandStyle = styled.div`
  .brand-primary {
    color: rgb(170, 168, 168);
  }

  .brand:hover .brand-primary {
    color: rgb(100, 98, 98);
  }

  .brand-secondary {
    color: rgba(170, 168, 168, 0.33);
  }

  .brand:hover .brand-secondary {
    color: rgba(100, 98, 98, 0.33);
  }
`;

export function Header() {
  const { isLoading, user } = useAuth0();

  const inlineMode = isInInlineMode(useLocation().search);

  if (inlineMode) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        fontSize: "16px",
        justifyContent: "space-between",
      }}
    >
      <BrandStyle style={{ margin: "10px 0 0 20px" }}>
        <a
          href="/"
          className="brand"
          style={{
            fontFamily: '"Open Sans"',
            fontSize: "22px",
            textDecoration: "none",
          }}
        >
          <span className="brand-primary">Elicit</span>
          <span
            className="brand-secondary"
            style={{
              marginLeft: "8px",
            }}
          >
            Forecast
          </span>
        </a>
      </BrandStyle>

      {!isLoading && (
        <div style={{ margin: "20px 20px 0 0" }}>
          {user && (
            <a href="/profile" style={{ marginRight: "20px" }}>
              Profile
            </a>
          )}
          {user && (
            <a href="/snapshots" style={{ marginRight: "20px" }}>
              Snapshot History
            </a>
          )}
          {user ? <LogoutBtn /> : <LoginButton />}
        </div>
      )}
    </div>
  );
}
