import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { addHoverForBelief } from "../../actions/addHoverForBelief";
import { removeHoverForBelief } from "../../actions/removeHoverForBelief";
import { setShouldShowStartArrow } from "../../actions/setShouldShowStartArrow";
import { updateBelief } from "../../actions/updateBelief";

import { convertToInput } from "./helpers/convertToInput";

import { RootState } from "../../reducers/rootReducer";

import { isSelectedForecastEditableSelector } from "../../selectors/isSelectedForecastEditableSelector";

const BottomMessage = (props) => (
  <div
    style={{
      bottom: "-3px",
      color: "#999",
      fontSize: "10px",
      position: "absolute",
      right: 0,
    }}
  >
    {props.children}
  </div>
);

export const EditableNotesTextarea = (props: any) => {
  const { belief, id, isDragged, setFocusedOnCell, hoveredOverRow } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [wantsToExpand, setWantsToExpand] = useState(false);
  const [canBeExpanded, setCanBeExpanded] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const inputEl = useRef(null);

  const value = belief[id];

  const beliefId = belief.id;

  const [input, setInput] = useState(convertToInput(id, value, false) || "");

  const shouldShowStartArrow = useSelector(
    (state: RootState) => state.status.shouldShowStartArrow
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputEl.current.clientHeight < inputEl.current.scrollHeight) {
      if (!canBeExpanded) {
        setCanBeExpanded(true);
      }
    } else if (inputEl.current.clientHeight >= inputEl.current.scrollHeight) {
      if (canBeExpanded) {
        setCanBeExpanded(false);
      }
    }

    if (wantsToExpand && canBeExpanded) {
      setIsExpanded(true);
    }

    if (!wantsToExpand) {
      setIsExpanded(false);
    }
  });

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const onFocus = () => {
    setIsFocused(true);
    setFocusedOnCell(beliefId);
    dispatch(addHoverForBelief(beliefId));
    dispatch(setShouldShowStartArrow(false));
  };

  const onBlur = () => {
    dispatch(updateBelief(beliefId, "notes", input));

    setIsFocused(false);
    setIsExpanded(false);
    setFocusedOnCell(null);
    if (hoveredOverRow) {
      dispatch(addHoverForBelief(hoveredOverRow));
    } else {
      dispatch(removeHoverForBelief(belief.id));
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TextareaAutosize
        ref={inputEl}
        disabled={!isSelectedForecastEditable}
        style={{
          backgroundColor: isDragged && "transparent",
          border: (shouldShowStartArrow || isDragged) && "1px dotted #ccc",
          padding: "5px",
          position: "relative",
          margin: "10px 0",
          textAlign: "left",
          top: "2px",
          width: "300px",
        }}
        maxRows={isFocused || isExpanded ? 1000 : 5}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={!isDragged ? input : value}
      />
      {canBeExpanded && !isExpanded && (
        <BottomMessage>
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              setWantsToExpand(true);
            }}
          >
            expand
          </span>
        </BottomMessage>
      )}

      {isExpanded && !isFocused && (
        <BottomMessage>
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              setWantsToExpand(false);
            }}
          >
            collapse
          </span>
        </BottomMessage>
      )}
    </div>
  );
};
