import isNil from "lodash.isnil";
import numeral from "numeral";

export const stringToNumber = (str: string) => {
  if (isNil(str) || str === "") {
    return NaN;
  }

  str = str.toLowerCase();

  // By default, numeral doesn't handle exponent strings
  // 1e9, so this code is in place to handle them.
  if (str.includes("e") && !isNaN(Number(str))) {
    str = String(Number(str));
  }

  const result = numeral(str).value();

  if (result === null) {
    return NaN;
  }

  return numeral(str).value();
};
