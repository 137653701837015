import React from "react";

import { isInInlineMode } from "../helpers/isInInlineMode";
import { useLocation } from "react-router-dom";

export function Footer() {
  const inlineMode = isInInlineMode(useLocation().search);

  if (inlineMode) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        fontSize: "16px",
        margin: "50px auto",
        marginBottom: "20px",
        justifyContent: "center",
      }}
    >
      <span style={{ marginRight: "10px" }}>
        <a href="/faq">FAQ</a>
      </span>
      •
      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
        <a href="/predictit">PredictIt Notifications</a>
      </span>
      •
      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
        <a href="/terms">Terms of Service</a>
      </span>
      •
      <span style={{ marginLeft: "10px" }}>
        <a href="/privacy">Privacy Policy</a>
      </span>
    </div>
  );
}
