import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import styles from "styled-components";
import { useQueryParam, NumberParam } from "use-query-params";

import { fetchAndReplaceBinaryQuestions } from "../actions/fetchAndReplaceBinaryQuestions";

import { binaryQuestionsCountSelector } from "../selectors/binaryQuestionsCountSelector";

const Styles = styles.div`
 .pagination a {
    display: block;
    padding: 10px;
    cursor: pointer;
  }

  .pagination a:hover {
    text-decoration: underline;
  }

  .pagination li.active {
    background-color: #f8f8f8;
    font-weight: 600;
  }

  .pagination {
    margin-bottom: 0;
  }
`;

export function BinaryQuestionsPagination() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [limitQueryParam] = useQueryParam("limit", NumberParam);

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const dispatch = useDispatch();

  const binaryQuestionsCount = useSelector(binaryQuestionsCountSelector);

  const fetchBinaryQuestions = async function ({ selected }) {
    setOffsetQueryParam(selected * limitQueryParam);

    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchAndReplaceBinaryQuestions({ accessToken }));
    } else if (!user) {
      dispatch(fetchAndReplaceBinaryQuestions({ accessToken: undefined }));
    }
  };

  const pageCount = Math.ceil(binaryQuestionsCount / limitQueryParam);

  if (pageCount === 1 || pageCount === 0) {
    return <div style={{ height: "50px" }} />;
  }

  return (
    <Styles style={{ marginRight: "10px" }}>
      <ReactPaginate
        pageCount={Math.ceil(binaryQuestionsCount / limitQueryParam)}
        forcePage={Math.floor(offsetQueryParam / limitQueryParam)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={fetchBinaryQuestions}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </Styles>
  );
}
