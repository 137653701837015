import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { Overlay, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { addBinaryPrediction } from "../actions/addBinaryPrediction";
import { createBinaryPrediction } from "../actions/createBinaryPrediction";

import { PREDICTOR_COLUMN_WIDTH } from "../constants";

import { shortenProbability } from "../helpers/shortenProbability";

import { communityPredictionSelector } from "../selectors/communityPredictionSelector";
import { mostRecentPredictionOfUserFunctionSelector } from "../selectors/mostRecentPredictionOfUserFunctionSelector";
import { yourMostRecentPredictionSelector } from "../selectors/yourMostRecentPrediction";

import { INPUT_BG_COLOR } from "../styles";

const COPY_ICON_PATH = "images/copy_icon.svg";

const Styles = styled.div`
  input {
    background-color: ${INPUT_BG_COLOR};
    border: none;
  }

  input:focus {
    border: auto;
  }

  .MuiInputBase-input {
    font-size: 16px;
    border: none;
  }

  .blue-on-hover:hover {
    color: rgb(61, 144, 251);
  }

  .copy-icon {
    opacity: 0.75;
  }

  .copy-icon:hover {
    opacity: 1;
  }
`;

export function CollapsedBinaryQuestion({
  isExpanded,
  handleExpand,
  handleCollapse,
  question,
  questionFocus,
  changeFocus,
  i,
}) {
  const { getAccessTokenSilently, user } = useAuth0();

  const [predictorsQueryParam, setPredictorsQueryParam] = useQueryParam(
    "predictors",
    StringParam
  );

  const dispatch = useDispatch();

  const [hasCopied, setHasCopied] = useState(false);

  const inputEl = useRef(null);

  const copyEl = useRef(null);

  useEffect(() => {
    if (questionFocus === i && !inputEl.current) {
      // If ths question has not input to focus on
      // e.g., it's resolved
      // then try the next question.
      changeFocus(questionFocus + 1);
    } else if (questionFocus === i) {
      inputEl.current.focus();
    }
  }, [questionFocus, inputEl.current]);

  const { title, privacyStatus } = question;

  const yourMostRecentPrediction = useSelector(
    yourMostRecentPredictionSelector(question.id)
  );

  const [predictionInput, setPredictionInput] = useState("");

  const communityPrediction = useSelector(
    communityPredictionSelector(question.id)
  );

  useEffect(() => {
    if (
      yourMostRecentPrediction &&
      document.activeElement !== inputEl.current
    ) {
      setPredictionInput(String(yourMostRecentPrediction.prediction) + "%");
    }
  }, [yourMostRecentPrediction]);

  const predictorIdsWithPotentialCommunity = predictorsQueryParam
    ? predictorsQueryParam.split(",")
    : [];

  const shouldShowCommunity = predictorIdsWithPotentialCommunity.includes(
    "community"
  );

  const predictorIds = predictorIdsWithPotentialCommunity.filter(
    (pId) => pId !== "community"
  );

  const mostRecentPredictionOfUserFunction = useSelector(
    mostRecentPredictionOfUserFunctionSelector({
      binaryQuestionId: question.id,
    })
  );

  return (
    <Styles
      style={{
        display: "grid",
        gridColumnGap: "20px",
        gridTemplateColumns: `auto 
          ${
            user ? PREDICTOR_COLUMN_WIDTH : ""
          } ${predictorIdsWithPotentialCommunity.reduce(
          (acc, id) => acc + ` ${PREDICTOR_COLUMN_WIDTH}`,
          ""
        )}`,
      }}
    >
      <span
        className="blue-on-hover"
        onClick={isExpanded ? handleCollapse : handleExpand}
        style={{
          cursor: "pointer",
          display: "inline-block",
          marginRight: "5px",
        }}
      >
        <span style={{ fontWeight: 600 }}>{title}</span>
        {privacyStatus === "PRIVATE" && ` 🔒 `}{" "}
        {isExpanded && (
          <>
            <span
              className="copy-icon"
              ref={copyEl}
              onClick={(e) => e.stopPropagation()}
              style={{
                display: "inline-block",
                marginLeft: "5px",
              }}
            >
              <CopyToClipboard
                onCopy={() => {
                  setHasCopied(true);
                  setTimeout(() => setHasCopied(false), 1000);
                }}
                text={`${window.location.origin}/binary/questions/${question.id}`}
              >
                <img
                  src={COPY_ICON_PATH}
                  style={{
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </CopyToClipboard>
            </span>
            <Overlay target={copyEl.current} show={hasCopied} placement="top">
              {(props) => (
                <Tooltip id={`copied-tooltip-${i}`} {...props}>
                  URL copied!
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
        {question.resolution && (
          <div
            style={{ color: "#999", fontSize: "smaller", marginTop: "10px" }}
          >
            Resolved {question.resolution === "YES" ? "Yes" : "No"}
            {question.resolvedAt &&
              ` on ${moment(question.resolvedAt).format("D MMM YYYY")}`}
          </div>
        )}
      </span>

      {user && (
        <span>
          {question.resolution ? (
            <span style={{ width: "50px" }}>{predictionInput}</span>
          ) : (
            <input
              ref={inputEl}
              disabled={question.resolution}
              value={predictionInput}
              onBlur={async () => {
                setPredictionInput(predictionInput.replace("%", "") + "%");

                if (
                  !isNaN(Number(predictionInput)) &&
                  predictionInput !== "" &&
                  (!yourMostRecentPrediction ||
                    Number(predictionInput) !==
                      yourMostRecentPrediction.prediction)
                ) {
                  const accessToken = user
                    ? await getAccessTokenSilently()
                    : undefined;

                  dispatch(
                    createBinaryPrediction({
                      accessToken,
                      binaryQuestionId: question.id,
                      fields: {
                        prediction: Number(predictionInput.replace("%", "")),
                        privacyStatus: "PUBLIC",
                        notes: "",
                      },
                      onSuccess: (binaryPrediction) =>
                        dispatch(addBinaryPrediction(binaryPrediction)),
                    })
                  );
                }
              }}
              onChange={(e) => setPredictionInput(e.target.value)}
              onFocus={() => {
                changeFocus(null);
                setPredictionInput(predictionInput.replace("%", ""));
              }}
              onKeyUp={async (e) => {
                if (e.key === "Enter") {
                  inputEl.current.blur();
                  changeFocus(i + 1);
                }
              }}
              style={{
                width: "100px",
              }}
            />
          )}
        </span>
      )}

      {shouldShowCommunity && (
        <span key="community">
          <span
            style={{
              alignItems: "center",
              display: "inline-flex",
              width: "100px",
            }}
          >
            {!isNaN(communityPrediction) ? (
              <span style={{ whiteSpace: "nowrap" }}>
                {shortenProbability(communityPrediction)}
                <span
                  style={{
                    display: "inline-block",
                    width: "2px",
                  }}
                />
                %
              </span>
            ) : (
              "--"
            )}
          </span>
        </span>
      )}
      {predictorIds.map((id) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {shortenProbability(
            mostRecentPredictionOfUserFunction({ userId: id })
          )}
          %
        </span>
      ))}
    </Styles>
  );
}
