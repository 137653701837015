import { useAuth0 } from "@auth0/auth0-react";
import { PredictItNotifications } from "predict-it-notifications";
import React, { useEffect, useState } from "react";

export function PredictItNotificationsContainer() {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const login = () =>
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
    });

  useEffect(() => {
    const fn = async () => {
      const at = await getAccessTokenSilently();
      setAccessToken(at);
    };

    if (user && !accessToken) {
      fn();
    }
  }, [user]);

  const [accessToken, setAccessToken] = useState(null);

  return (
    <div
      className="predict-it-notifications-container"
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        width: "1200px",
      }}
    >
      <PredictItNotifications accessToken={accessToken} login={login} />
    </div>
  );
}
