import React from "react";
import { useSelector } from "react-redux";

import { HomepageMetaculusQuestions } from "./HomepageMetaculusQuestions";

import { RootState } from "../../reducers/rootReducer";

interface Question {
  id: number;
  title: string;
}

const backupQuestions: Question[] = [
  {
    id: 3992,
    title:
      "What fraction of US adults will be vaccinated against the 2020-2021 seasonal influenza?",
  },
  {
    id: 4022,
    title:
      "What will be the fatality ratio of US COVID-19 patients who receive invasive ventilation?",
  },
  {
    id: 3864,
    title:
      "Out of the 25 largest U.S companies by revenue, how many will file for bankruptcy over the next four years?",
  },
  {
    id: 3854,
    title:
      "How many US state governors, members of the Supreme Court, and members of Congress will die from COVID-19 in 2020?",
  },
  {
    id: 605,
    title:
      "How much greater (in ˚C) will the average global temperature in 2100 be than the average global temperature in 1880?",
  },
  {
    id: 3730,
    title: "How many communist states will there be in 2050?",
  },
  {
    id: 3987,
    title:
      "What will be the peak unemployment rate in the United States for calendar year 2020?",
  },
  {
    id: 3781,
    title:
      "Which month of 2020 will see the biggest global increase of COVID-19 cases?",
  },
  {
    id: 974,
    title: `What will be the value of the (herein described) "AI winter index" at end of 2021?`,
  },
  {
    id: 4029,
    title:
      "What proportion of House Democratic incumbents will be defeated in the 2020 election?",
  },
];

export const HomepageMetaculusQuestionsContainer = () => {
  const loadedSearchPageQuestions = useSelector(
    (state: RootState) => state.searchPageQuestions
  );

  const loadingSearchPageQuestionsStatus = useSelector(
    (state: RootState) => state.status.loadingSearchPageQuestionsStatus
  );

  return loadingSearchPageQuestionsStatus === "PENDING" ? (
    <HomepageMetaculusQuestions isLoading={true} questions={[]} />
  ) : loadingSearchPageQuestionsStatus === "ERROR" ? (
    <HomepageMetaculusQuestions isLoading={false} questions={backupQuestions} />
  ) : loadingSearchPageQuestionsStatus === "SUCCESS" ? (
    <HomepageMetaculusQuestions
      isLoading={false}
      questions={loadedSearchPageQuestions}
    />
  ) : null;
};
