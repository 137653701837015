import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";
import { getForecastNameForImpliedBeliefsSelector } from "../../selectors/getForecastNameForImpliedBeliefsSelector";
import { getForecastLabelColorFunctionSelector } from "../../selectors/getForecastLabelColorFunctionSelector";

import { ForecastSelectorDropdown } from "../ForecastSelectorDropdown";
import { TemplatedBeliefDisplay } from "./TemplatedBeliefDisplay";

import { ComparisonBelief } from "../../reducers/beliefsReducer";
import { RootState } from "../../reducers/rootReducer";

const ComparisonBeliefDisplay = ({
  belief,
  baselineName,
}: {
  belief: ComparisonBelief;
  baselineName: string;
}) => {
  const isDateQuestion = useSelector(isDateQuestionSelector);

  const forecasts = useSelector((state: RootState) => state.forecasts);

  const comparisonName = useSelector(
    getForecastNameForImpliedBeliefsSelector(
      forecasts.find((f) => f.distributionId === belief.comparisonId)?.id
    )
  );

  // allow templating by baseline and comparison names
  const values = {
    ...belief.values,
    baselineName,
    comparisonName,
  };

  return (
    <TemplatedBeliefDisplay
      label={belief.label}
      values={values}
      isDateQuestion={isDateQuestion}
      beliefId={belief.id}
      subLabels={belief.subLabels}
    />
  );
};

export const ComparisonBeliefsDisplay = ({
  beliefs,
}: {
  beliefs: ComparisonBelief[];
}) => {
  const forecasts = useSelector((state: RootState) => state.forecasts);

  const baselineSelectedForecastId = useSelector(
    (state: RootState) => state.status.selectedForecastId
  );

  const name = useSelector(
    getForecastNameForImpliedBeliefsSelector(baselineSelectedForecastId)
  );

  const comparisonDistributionIds = beliefs.map((b) => b.comparisonId);
  const selectableComparisonForecasts = forecasts.filter((f) =>
    comparisonDistributionIds.includes(f.distributionId)
  );

  const [selectedComparisonForecast, setSelectedComparisonForecast] = useState(
    selectableComparisonForecasts && selectableComparisonForecasts[0]
  );

  useEffect(() => {
    setSelectedComparisonForecast(
      selectableComparisonForecasts && selectableComparisonForecasts[0]
    );
  }, [selectableComparisonForecasts.length, baselineSelectedForecastId]);

  const selectedComparisonForecastName = useSelector(
    getForecastNameForImpliedBeliefsSelector(selectedComparisonForecast?.id)
  );

  const getForecastLabelColorFunction = useSelector(
    getForecastLabelColorFunctionSelector
  );

  const baselineColor = getForecastLabelColorFunction(
    baselineSelectedForecastId
  );

  const comparisonColor = getForecastLabelColorFunction(
    selectedComparisonForecast?.id
  );

  return selectedComparisonForecast ? (
    <>
      {selectableComparisonForecasts.length > 1 ? (
        <div style={{ fontWeight: 600, marginBottom: "5px" }}>
          Compare{" "}
          <span
            style={{
              color: baselineColor,
            }}
          >
            {name}
          </span>{" "}
          with{" "}
          <ForecastSelectorDropdown
            selectedForecast={selectedComparisonForecast}
            selectableForecasts={selectableComparisonForecasts}
            setSelectedForecast={setSelectedComparisonForecast}
          />
        </div>
      ) : (
        <div style={{ fontWeight: 600, marginBottom: "5px" }}>
          Compare{" "}
          <span
            style={{
              color: baselineColor,
            }}
          >
            {name}
          </span>{" "}
          with{" "}
          <span
            style={{
              color: comparisonColor,
            }}
          >
            {selectedComparisonForecastName}
          </span>
        </div>
      )}
      {selectableComparisonForecasts.length > 1 ? (
        <ul>
          {beliefs
            .filter(
              (b) =>
                b.comparisonId === selectedComparisonForecast.distributionId
            )
            .map((b) => (
              <li key={b.id}>
                <ComparisonBeliefDisplay belief={b} baselineName={name} />
              </li>
            ))}
        </ul>
      ) : (
        <ul>
          {beliefs
            .filter(
              (b) =>
                b.comparisonId === selectedComparisonForecast.distributionId
            )
            .map((b) => (
              <li key={b.id}>
                <ComparisonBeliefDisplay belief={b} baselineName={name} />
              </li>
            ))}
        </ul>
      )}
    </>
  ) : null;
};
