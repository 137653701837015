import moment from "moment";
import React, { useRef, useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useDispatch } from "react-redux";

import { BaseLegendDistLabel } from "./BaseLegendDistLabel";

import { updateDisplayName } from "../actions/updateDisplayName";

export const LegendDistLabel = ({
  createdAt,
  distribution,
  forecastId,
  key,
}) => {
  const [status, setStatus] = useState("BASE"); // Either "BASE" or "EDITING"
  const [inputValue, setInputValue] = useState(
    distribution.displayName || distribution.name
  );
  const inputEl = useRef(null);
  const dispatch = useDispatch();

  const handleClickEditIcon = () => {
    setStatus("EDITING");
    // Timeout to ensure DOM element exists
    setTimeout(() => inputEl.current.focus(), 1);
  };

  return (
    <>
      <div
        className="legend-label"
        key={key}
        style={{
          alignItems: "baseline",
          display: "flex",
          fontSize: "16px",
        }}
      >
        {status === "BASE" ? (
          <BaseLegendDistLabel
            distribution={distribution}
            forecastId={forecastId}
            handleClickEditIcon={handleClickEditIcon}
          />
        ) : (
          <AutosizeInput
            ref={inputEl}
            onBlur={(e) => {
              e.preventDefault();
              dispatch(updateDisplayName(distribution.name, inputValue));
              setStatus("BASE");
            }}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                e.preventDefault();
                dispatch(updateDisplayName(distribution.name, inputValue));
                setStatus("BASE");
              }
            }}
            value={inputValue}
          />
        )}
      </div>
    </>
  );
};
