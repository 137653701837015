export class RenderError extends Error {
  originalError: string;
  msg: string;

  constructor(msg, originalError) {
    super(msg);
    this.msg = msg;
    this.originalError = originalError;
  }
}
