import isEqual from "lodash.isequal";
import sortBy from "lodash.sortby";

const takeOutIrrelevantProperties = (b) => ({
  ...b,
  notes: undefined,
});

export function areBeliefsEqualInRelevantWays(prevBeliefs, beliefs) {
  // Notes are "taken out" of the beliefs
  const processedPrevBeliefs = sortBy(
    prevBeliefs.map(takeOutIrrelevantProperties),
    "id"
  );

  const processedBeliefs = sortBy(
    beliefs.map(takeOutIrrelevantProperties),
    "id"
  );

  return isEqual(processedPrevBeliefs, processedBeliefs);
}
