import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { createBrowserHistory } from "history";

import { CheckingClosenessPageContainer } from "./CheckingClosenessPageContainer";

import { setCheckingClosenessToSubmissionStatus } from "../../actions/setCheckingClosenessToSubmissionStatus";
import { setIsCloseEnoughToSubmission } from "../../actions/setIsCloseEnoughToSubmission";
import { setSubmissionFlowScreen } from "../../actions/setSubmissionFlowScreen";

import { RootState } from "../../reducers/rootReducer";

export function SubmitForecastModal() {
  const dispatch = useDispatch();

  const distsLoaded = useSelector(
    (state: RootState) =>
      state.status.loadingDistributionsToDisplayStatus === "SUCCESS"
  );

  const query = new URLSearchParams(createBrowserHistory().location.search);

  const [show, setShow] = useState(query.has("success"));

  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setSubmitted(false);
    setShow(false);
    dispatch(setSubmissionFlowScreen(null));
    dispatch(setIsCloseEnoughToSubmission(null));
    dispatch(setCheckingClosenessToSubmissionStatus(null));
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} disabled={!distsLoaded}>
        Submit to Metaculus
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <CheckingClosenessPageContainer
          handleClose={handleClose}
          submitted={submitted}
          setSubmitted={setSubmitted}
        />
      </Modal>
    </>
  );
}
