import { RootState } from "../reducers/rootReducer";

export const isUpperOutOfBoundsBelief = (beliefId: string) => (
  state: RootState
) => {
  const belief = state.beliefs.find((b) => b.id === beliefId);
  if (!belief) {
    throw Error(`Belief with id ${beliefId} not found`);
  }

  if (belief.values.min !== state.question.metadata.graphScale.high) {
    return false;
  }

  // Shouldn't be possible given current validation
  if (belief.values.max !== null) {
    return false;
  }

  return true;
};
