import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { importForecast } from "../actions/importForecast";

const snapshotTooOldMsg = (
  <p>
    Snapshot too old. To import it:
    <ol>
      <li>Open the snapshot</li>
      <li>Resave the snapshot to update it</li>
      <li>Import the resaved version</li>
    </ol>
  </p>
);

export function ImportForecastInput() {
  const dispatch = useDispatch();

  const [importInput, setImportInput] = useState("");

  const [importStatus, setImportStatus] = useState<string | null>(null);

  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div
      className="small-input-font-size"
      style={{
        paddingBottom: "8px",
        textAlign: "center",
      }}
    >
      <TextField
        style={{ width: "230px" }}
        placeholder="+ Add snapshot via URL"
        onChange={(e) => setImportInput(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === "Enter") {
            e.preventDefault();
            setImportStatus("PENDING");
            const regexResultArray = /\/builder\/([^?/]*)/.exec(importInput);
            const key = regexResultArray && regexResultArray[1];
            if (key) {
              dispatch(
                importForecast(key, {
                  onError: (msg) => {
                    setImportStatus("ERROR");
                    setErrorMsg(
                      msg === "snapshot-too-old"
                        ? (snapshotTooOldMsg as any)
                        : msg
                    );
                  },
                  onDone: () => setImportStatus("SUCCESS"),
                })
              );
              setImportInput("");
            } else {
              setImportStatus("ERROR");
              setErrorMsg("Not a snapshot URL");
            }
          }
        }}
        value={importInput}
        disabled={importStatus === "PENDING"}
        error={importStatus === "ERROR"}
        helperText={importStatus === "ERROR" && errorMsg}
      />
    </div>
  );
}
