import { RootState } from "../reducers/rootReducer";

import { selectedForecastSelector } from "./selectedForecastSelector";

export const displayedBeliefsSelector = ({
  interval,
  nonInterval,
}: {
  interval: boolean;
  nonInterval: boolean;
}) => (state: RootState) => {
  const selectedForecast = selectedForecastSelector(state);

  if (!selectedForecast) {
    return [];
  }

  let beliefs = [];

  if (interval) {
    const displayedIntervalBeliefs = selectedForecast.intervalBeliefIds.map(
      (id) => {
        return state.beliefs.find((b) => b.id === id);
      }
    );
    beliefs = beliefs.concat(displayedIntervalBeliefs);
  }

  if (nonInterval) {
    const displayedNonIntervalBeliefs = selectedForecast.nonIntervalBeliefIds.map(
      (id) => {
        return state.beliefs.find((b) => b.id === id);
      }
    );
    beliefs = beliefs.concat(displayedNonIntervalBeliefs);
  }

  return beliefs;
};
