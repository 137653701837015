import { RootState } from "../reducers/rootReducer";

// To keep the colors of distributions constant,
// we want to know the index of each distributions
// wrt to ALL the distributions, not just the ones
// getting currently displayed. We also want to treat
// the presence of the 3 core distributions as not
// relevant to the index.
export const getIndexOfDistFunctionSelector = (state: RootState) => (
  distName
) => {
  const anonDists = state.distributions.filter(
    (d) =>
      !(
        d.name === "user" ||
        d.name === "community" ||
        d.name === "metaculusSubmission"
      )
  );

  return anonDists.findIndex((d) => d.name === distName);
};
