import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { setShouldShowBinaryToCustomFlow } from "../actions/setShouldShowBinaryToCustomFlow";

import { RootState } from "../reducers/rootReducer";

import { NewQuestionForm } from "./NewQuestionForm";

export const BinaryToCustomFlowModal = withRouter((props) => {
  const dispatch = useDispatch();

  const shouldShow = useSelector(
    (state: RootState) => state.status.shouldShowBinaryToCustomFlow
  );

  const binaryQuestionText = useSelector(
    (state: RootState) => state.status.binaryQuestionText
  );

  const handleClose = () => dispatch(setShouldShowBinaryToCustomFlow(false));

  return (
    <>
      <Modal
        className="binary-to-custom-flow"
        show={shouldShow}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Binary Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{binaryQuestionText}</strong>
          </p>
          <p>
            You've entered in the URL of a binary question. Elicit currently
            helps you build probability density functions for numeric questions.
            The best way to use Elicit for binary questions is to build your own
            distribution for a related numeric question.
          </p>
          <p>
            For example, you can answer the question "Will a Covid-19 vaccine be
            administered to at least 10,000 people in 2020?" with the question
            "How many people will receive a Covid-19 vaccine in 2020?"
          </p>
          <NewQuestionForm />
        </Modal.Body>
      </Modal>
    </>
  );
});
