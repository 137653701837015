import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { fetchCsv } from "../actions/fetchCsv";

export function CsvExport() {
  const { user, getAccessTokenSilently, isLoading: isLoadingAuth } = useAuth0();

  const [csv, setCsv] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    (async function fn() {
      if (!isLoadingAuth) {
        const accessToken = user ? await getAccessTokenSilently() : undefined;
        dispatch(
          fetchCsv({
            accessToken,
            onSuccess: (data) => {
              // nothing made it past filter
              if (data === "") {
                setCsv(null);
              } else {
                const blob = new Blob([data], {
                  type: "text/csv;charset=utf-8;",
                });
                setCsv(blob);
              }
            },
          })
        );
      }
    })();
  }, [isLoadingAuth, location]);

  return csv !== null ? (
    <div style={{ textAlign: "left", fontSize: "16px" }}>
      <a href={URL.createObjectURL(csv)} download="binary_export.csv">
        Download CSV
      </a>
    </div>
  ) : null;
}
