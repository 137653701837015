import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDCMenu } from "@material/menu";

import { ImportForecastInput } from "./ImportForecastInput";

import { importForecast } from "../actions/importForecast";
import { makeRequestToBackend } from "../helpers/makeRequestToBackend";
import { RootState } from "../reducers/rootReducer";

export const MaterialDropdown = ({ shouldOpen }) => {
  const menuRef = useRef(null);

  const forecastNames = useSelector((state: RootState) =>
    state.forecasts.map((f) => f.name)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // Because there's no React component for a Material design
    // dropdown, we're using the vanilla HTML/JS version.
    // This version opens imperatively in the following way.
    // Anytime the shouldOpen props changes (and is truth-y), we open
    // it this way.
    //
    // shouldOpen starts off false, so the dropdown starts off closed.
    // shouldOpen then gets assigned {} when the user tries to open
    // the dropdown. This triggers the following code. React
    // doesn't easily know when it's closed, though. So any component
    // assigning {} to shouldOpen to trigger an opening needs to
    // first inspect the DOM to ensure that the dropdown is closed.
    if (shouldOpen) {
      menuRef.current = new MDCMenu(document.querySelector(".mdc-menu"));
      menuRef.current.open = true;
    }
  }, [shouldOpen]);

  const questionId = useSelector(
    (state: RootState) => state.question.metadata.questionId
  );

  const isCustom = useSelector(
    (state: RootState) => state.question.metadata.source === null
  );

  const [snapshotsToImport, setSnapshotsToImport] = useState([]);

  useEffect(() => {
    fetchMRecentForecastsOfOtherUsers();

    async function fetchMRecentForecastsOfOtherUsers() {
      if (!questionId || !isCustom) {
        return;
      }

      const response = await makeRequestToBackend({
        path: `/questions/${questionId}/snapshots?user_most_recent=true&expand=user&snapshot.fields=createdAt`,
        method: "GET",
      });

      const data = await response.json();
      setSnapshotsToImport(data);
    }
  }, []);

  return (
    <div className="mdc-menu mdc-menu-surface" style={{ width: "260px" }}>
      <div style={{ padding: "10px 10px 0 10px" }}>
        <ImportForecastInput />
      </div>
      <ul
        className="mdc-list"
        role="menu"
        aria-hidden="true"
        aria-orientation="vertical"
        tabIndex={-1}
      >
        {snapshotsToImport
          .filter((s) => !forecastNames.includes(s.id))
          .map((s) => (
            <>
              <li
                className="mdc-list-item"
                role="menuitem"
                onClick={() => {
                  dispatch(
                    importForecast(s.id, {
                      onError: () => {},
                      onDone: () => {},
                    })
                  );
                }}
              >
                <span className="mdc-list-item__ripple"></span>
                <span
                  className="mdc-list-item__text"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      fontSize: "16px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {s.user.displayName}
                  </span>
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      marginTop: "-7px",
                    }}
                  >
                    {moment(s.createdAt).format("l - h:mm:ss a")}
                  </span>
                </span>
              </li>
            </>
          ))}
      </ul>
    </div>
  );
};
