import React from "react";
import { formatPercent, formatPoint, formatDollarAmount } from "./utils";

export const ValueDisplay = ({
  valueKey,
  value,
  isDateQuestion,
}: {
  valueKey: string;
  value: any;
  isDateQuestion: boolean;
}) => {
  if (valueKey === "p" || (valueKey && valueKey.slice(-1) === "P")) {
    return (
      <span>
        <strong>{formatPercent(value)}</strong>%
      </span>
    );
  }

  if (valueKey && valueKey.slice(-7) === "Dollars") {
    return (
      <span>
        $
        <strong>
          {value === undefined ? undefined : formatDollarAmount(value)}
        </strong>
      </span>
    );
  }

  if (typeof value === "number") {
    const formatAsDate = isDateQuestion && valueKey.slice(-5) !== "Ratio";
    return <strong>{formatPoint(value, formatAsDate)}</strong>;
  }

  return <span>{value}</span>;
};
