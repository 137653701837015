import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import React, { useState } from "react";

const getLink = (tag: string, predictors?: string[]) =>
  `binary?binaryQuestions.sortBy=popularity&binaryQuestions.tags%5B%5D=${tag}&limit=20&offset=0&predictors=${
    predictors ? predictors.join(",") : ""
  }`;

const carouselItems = [
  {
    title: "Slate Star Codex 2020",
    description:
      "A list of the questions Scott Alexander predicted on in 2020, with his and others' predictions",
    href: getLink("ssc", ["ZWkz_HrM60"]),
  },
  {
    title: "Existential risk",
    description:
      "A wide range of predictions relating to existential risk, based on Michael Aird’s compilation",
    href: getLink("x risk", ["community"]),
  },
  {
    title: "Philosophical beliefs and values",
    description:
      "Brian Tomasik's and Pablo Stafforini’s credences on philosophical questions",
    href: getLink("what I believe", ["vFchjNPvRh", "lgTzPfsaIst"]),
  },
  {
    title: "Artificial Intelligence",
    description:
      "Predictions on various components of Artificial Intelligence and AGI collected from a range of sources",
    href: getLink("AI", ["community"]),
  },
];

const SLIDES_PER_PAGE = 2;

export const HomepageBinaryQuestions = () => {
  const [carouselValue, setCarouselValue] = useState(0);

  return (
    <Carousel
      arrows
      value={carouselValue}
      onChange={(i) => {
        if (!(i > carouselItems.length - SLIDES_PER_PAGE)) {
          setCarouselValue(i);
        }
      }}
      slidesPerPage={SLIDES_PER_PAGE}
      slidesPerScroll={1}
      animationSpeed={250}
      offset={40}
      itemWidth={320}
    >
      {carouselItems.map((data, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "200px",
            marginBottom: "100px",
            padding: "20px",
          }}
        >
          <a href={data.href} style={{ marginBottom: "20px" }}>
            {data.title} ⟶
          </a>
          <div
            style={{
              color: "#888",
            }}
          >
            {data.description}
          </div>
        </div>
      ))}
    </Carousel>
  );
};
