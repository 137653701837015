import { RootState } from "../reducers/rootReducer";

export const getForecastNameForDisplayFunctionSelector = (state: RootState) => (
  forecastId: string
) => {
  const forecast = state.forecasts.find((f) => f.id === forecastId);

  if (!forecast) {
    return null;
  }

  if (forecast.name === "user") {
    return "Your forecast";
  }

  if (forecast.name === "community") {
    return "Metaculus community";
  }

  if (forecast.name === "metaculusSubmission") {
    return "Metaculus submission";
  }

  const dist = state.distributions.find(
    (d) => d.id === forecast.distributionId
  );

  if (dist.displayName) {
    return dist.displayName;
  }

  return forecast.name;
};
