import { RootState } from "../reducers/rootReducer";

export const boundsAreSubset = (
  importedState: RootState,
  importingState: RootState
) => {
  // If a bound is open in the imported distribution,
  // it must be open in the importing distribution.
  // It's fine if a bound is open in the importing distribution
  // but not the imported distribution
  // (In that case, we just consider the imported distribution
  // to have assigned 0 probability outside that bound)
  if (!importedState.question.metadata.isLowerBoundClosed) {
    if (importingState.question.metadata.isLowerBoundClosed) {
      return false;
    }
  }

  if (!importedState.question.metadata.isUpperBoundClosed) {
    if (importingState.question.metadata.isUpperBoundClosed) {
      return false;
    }
  }

  return true;
};
