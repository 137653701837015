import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { addTagToQuestion } from "../actions/addTagToQuestion";
import { associateTagWithQuestion } from "../actions/associateTagWithQuestion";
import { disassociateTagWithQuestion } from "../actions/disassociateTagWithQuestion";
import { removeTagFromQuestion } from "../actions/removeTagFromQuestion";

import { INPUT_BG_COLOR } from "../styles";

const ShowXOnHover = styled.span`
  input {
    background-color: ${INPUT_BG_COLOR};
    border: none;
  }

  .show-on-hover {
    opacity: 0.25;
  }

  .show-on-hover:hover {
    opacity: 1;
  }
`;

export function QuestionTags({ binaryQuestionId, isYourQuestion, tags }) {
  const dispatch = useDispatch();

  const [tagInput, setTagInput] = useState("");

  const { getAccessTokenSilently, user } = useAuth0();

  return (
    <span>
      {isYourQuestion && (
        <div style={{ marginBottom: "5px" }}>
          <input
            type="text"
            placeholder="Add tag"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                const accessToken = user
                  ? await getAccessTokenSilently()
                  : undefined;

                dispatch(
                  associateTagWithQuestion({
                    accessToken,
                    binaryQuestionId,
                    tagName: tagInput,
                    onSuccess: () => {
                      dispatch(
                        addTagToQuestion({
                          questionId: binaryQuestionId,
                          tag: { name: tagInput },
                        })
                      );
                      setTagInput("");
                    },
                  })
                );
              }
            }}
          />
        </div>
      )}
      {tags.map((t, i) => (
        <>
          {" "}
          <ShowXOnHover
            key={i}
            style={{
              borderRadius: "2px",
              border: "solid 1px #3d90fb",
              backgroundColor: "rgba(61, 144, 251, 0.12)",
              color: "#3d90fb",
              display: "inline-block",
              padding: "2px 5px",
              marginBottom: "5px",
              whiteSpace: "nowrap",
            }}
          >
            {t.name}
            {isYourQuestion && (
              <span
                className="show-on-hover"
                onClick={async (e) => {
                  const accessToken = user
                    ? await getAccessTokenSilently()
                    : undefined;

                  dispatch(
                    disassociateTagWithQuestion({
                      accessToken,
                      binaryQuestionId,
                      tagName: t.name,
                      onSuccess: () => {
                        dispatch(
                          removeTagFromQuestion({
                            questionId: binaryQuestionId,
                            tag: t,
                          })
                        );
                      },
                    })
                  );
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "smaller",
                  marginLeft: "5px",
                }}
              >
                ❌
              </span>
            )}
          </ShowXOnHover>{" "}
        </>
      ))}
    </span>
  );
}
