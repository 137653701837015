import { v4 as uuidv4 } from "uuid";

import { AddIntervalBeliefAction as BeliefsAddIntervalBeliefActions } from "../reducers/beliefsReducer";
import { AddIntervalBeliefAction as ForecastsAddIntervalBeliefAction } from "../reducers/forecastsReducer";

type AddIntervalBeliefAction = BeliefsAddIntervalBeliefActions &
  ForecastsAddIntervalBeliefAction;

export const addIntervalBelief = ({
  min,
  max,
  p,
  forecastId,
}: {
  min: number | null;
  max: number | null;
  p: number | null;
  forecastId: string;
}): AddIntervalBeliefAction => ({
  type: "ADD_INTERVAL_BELIEF",
  beliefFields: {
    id: uuidv4(),
    values: {
      min,
      max,
      p,
    },
  },
  forecastId,
});
