import { AddForecastAction } from "../reducers/forecastsReducer";
export const addForecast = ({
  id,
  createdAt,
  name,
  type,
  distributionId,
  intervalBeliefIds,
  nonIntervalBeliefIds,
  impliedBeliefIds,
  notes,
}: {
  id: string;
  createdAt?: number;
  name: string;
  type: string; // TODO: enum,
  distributionId: string;
  intervalBeliefIds: string[];
  nonIntervalBeliefIds: string[];
  impliedBeliefIds: string[];
  notes?: string;
}): AddForecastAction => ({
  type: "ADD_FORECAST",
  forecast: {
    id,
    createdAt,
    name,
    type,
    notes: notes || "",
    distributionId,
    intervalBeliefIds,
    nonIntervalBeliefIds,
    impliedBeliefIds,
  },
});
