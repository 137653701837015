import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox } from "@material-ui/core";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  useQueryParam,
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
} from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

import { userSelector } from "../selectors/userSelector";

import { FilteringByTag } from "./FilteringByTag";

export function Filtering() {
  const { getAccessTokenSilently, user } = useAuth0();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    "binaryQuestions.resolved": StringParam,
    "binaryQuestions.hasPredicted": StringParam,
    "binaryQuestions.creatorId": StringParam,
    "binaryPredictions.creatorId": StringParam,
  });

  const dispatch = useDispatch();

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const fetchBinaryQuestions = async function () {
    if (user) {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    } else if (!user) {
      dispatch(
        fetchAndReplaceBinaryQuestionsAndResetOffset({
          accessToken: undefined,
          offsetQueryParam,
          setOffsetQueryParam,
        })
      );
    }
  };

  const handleResolvedOnlyCheckboxChange = (e: any) => {
    setFilterQueryParams({
      "binaryQuestions.resolved": e.target.checked ? "true" : undefined,
    });
    fetchBinaryQuestions();
  };

  const handleUnresolvedOnlyCheckboxChange = (e: any) => {
    setFilterQueryParams({
      "binaryQuestions.resolved": e.target.checked ? "false" : undefined,
    });
    fetchBinaryQuestions();
  };

  const handleHasNotPredictedCheckboxChange = (e: any) => {
    setFilterQueryParams({
      "binaryQuestions.hasPredicted": e.target.checked ? "false" : undefined,
    });

    if (
      e.target.checked &&
      filterQueryParams["binaryPredictions.creatorId"] === "me"
    ) {
      setFilterQueryParams({
        "binaryPredictions.creatorId": undefined,
      });
    }

    fetchBinaryQuestions();
  };

  const userState = useSelector(userSelector);

  const handleMyPredictionsOnlyCheckboxChange = (e: any) => {
    setFilterQueryParams({
      "binaryPredictions.creatorId": e.target.checked ? "me" : undefined,
      "binaryQuestions.hasPredicted": e.target.checked ? "true" : undefined,
    });
    fetchBinaryQuestions();
  };

  const [searchQueryParam, setSearchQueryParam] = useQueryParam(
    "binaryQuestions.search",
    StringParam
  );

  const [tagsQueryParam, setTagsQueryParam] = useQueryParam(
    "binaryQuestions.tags[]",
    ArrayParam
  );

  const reset = () => {
    setFilterQueryParams({
      "binaryQuestions.resolved": undefined,
      "binaryQuestions.hasPredicted": undefined,
      "binaryQuestions.creatorId": undefined,
      "binaryPredictions.creatorId": undefined,
    });

    setSearchQueryParam(undefined);

    setTagsQueryParam(undefined);
  };

  return (
    <div style={{ width: "400px" }}>
      <FilteringByTag />
      <hr />
      <div>
        <Checkbox
          checked={filterQueryParams["binaryQuestions.resolved"] === "true"}
          onChange={handleResolvedOnlyCheckboxChange}
        />
        Resolved
      </div>
      <div>
        <Checkbox
          checked={filterQueryParams["binaryQuestions.resolved"] === "false"}
          onChange={handleUnresolvedOnlyCheckboxChange}
        />
        Open
      </div>
      <hr />
      {userState && (
        <>
          <div>
            <Checkbox
              checked={
                filterQueryParams["binaryPredictions.creatorId"] === "me" &&
                filterQueryParams["binaryQuestions.hasPredicted"] === "true"
              }
              onChange={handleMyPredictionsOnlyCheckboxChange}
            />
            My predictions
          </div>
          <div>
            <Checkbox
              checked={
                filterQueryParams["binaryQuestions.hasPredicted"] === "false"
              }
              onChange={handleHasNotPredictedCheckboxChange}
            />
            Questions I haven't predicted on
          </div>
          <hr />
        </>
      )}
      <Button
        onClick={async () => {
          reset();
          await fetchBinaryQuestions();
        }}
        variant="secondary"
        size="sm"
        style={{ marginLeft: "10px" }}
      >
        Clear Filters
      </Button>
    </div>
  );
}
