import { UpdateUserMetaculusApiKeyAction } from "../reducers/userReducer";

export const updateUserMetaculusApiKey = (
  apiDomain: string,
  key: string
): UpdateUserMetaculusApiKeyAction => ({
  type: "UPDATE_USER_METACULUS_API_KEY",
  apiDomain,
  key,
});
