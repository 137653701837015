import { Button, Modal } from "react-bootstrap";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setShowSubmission } from "../../actions/setShowSubmission";
import { setShouldShowSubmissionOnLegend } from "../../actions/setShouldShowSubmissionOnLegend";
import { setSubmissionFlowScreen } from "../../actions/setSubmissionFlowScreen";

import { RootState } from "../../reducers/rootReducer";

export function CheckingClosenessPage({ handleClose }) {
  const dispatch = useDispatch();

  const isCloseEnoughToSubmission = useSelector(
    (state: RootState) => state.status.isCloseEnoughToSubmission
  );

  const checkingClosenessToSubmissionStatus = useSelector(
    (state: RootState) => state.status.checkingClosenessToSubmissionStatus
  );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Metaculus Fit</Modal.Title>
      </Modal.Header>
      {
        checkingClosenessToSubmissionStatus === "PENDING" ? (
          <Modal.Body>
            Metaculus requires us to submit your distribution using a mixture of
            logistic curves. Checking fit now.
            <div style={{ margin: "20px 0", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
            </div>
          </Modal.Body>
        ) : checkingClosenessToSubmissionStatus === "SUCCESS" &&
          !isCloseEnoughToSubmission ? (
          <>
            <Modal.Body>
              <p>
                The distribution you built might look different when we convert
                it into the logistic mixtures Metaculus requires. Before
                submitting, do you want to check what we'll submit to Metaculus
                and have the option to make changes?
              </p>
              <p>
                Turning "Smooth" on in the bottom left corner sometimes helps
                get your distribution in the best shape for Metaculus
                submission.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  dispatch(setShouldShowSubmissionOnLegend(true));
                  dispatch(setShowSubmission(true));
                  handleClose();
                }}
                variant="secondary"
              >
                Check Submission
              </Button>
              <Button
                onClick={() => {
                  dispatch(setSubmissionFlowScreen("SKIP_CLOSENESS"));
                }}
              >
                Continue with Submission
              </Button>
            </Modal.Footer>
          </>
        ) : checkingClosenessToSubmissionStatus === "ERROR" ? (
          <>
            <Modal.Body>
              An error has occurred. Please try again later.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} variant="secondary">
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : null /* This component doesn't handle if fit is good*/
      }
    </>
  );
}
