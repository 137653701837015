import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function updateUserInDB({
  accessToken,
  updates,
  onDone,
  onError,
}: {
  accessToken: string;
  updates: any;
  onDone: () => void;
  onError: () => void;
}): AppThunk {
  return async function(dispatch) {
    try {
      const response = await makeRequestToBackend({
        path: "/users/me",
        method: "PATCH",
        body: updates,
        accessToken,
      });

      if (!response.ok) {
        const data = await response.json();
        throw Error(data.error);
      }

      return onDone();
    } catch (e) {
      Sentry.captureException(e);
      return onError();
    }
  };
}
