import { RootState } from "../reducers/rootReducer";

export const shouldShowDistributionSelector = (distName: string) => (
  state: RootState
) => {
  if (distName === "metaculusSubmission") {
    return state.showSubmission.show;
  }

  return state.status.namesOfVisibleDistributions.includes(distName);
};
