import isNil from "lodash.isnil";

import { RootState } from "../reducers/rootReducer";

export const inBoundsProbForBeliefSelector = (beliefId: string) => (
  state: RootState
) => {
  if (isNil(beliefId)) {
    return null;
  }

  const belief = state.beliefs.find((b) => b.id === beliefId);
  if (!belief) {
    throw Error(`Belief with id ${beliefId} not found`);
  }

  const userDistId = state.distributions.find((d) => d.name === "user")?.id;

  const beliefFitDescription =
    state.beliefFits[beliefId] && state.beliefFits[beliefId][userDistId];

  if (!beliefFitDescription) {
    return null;
  }

  const fittedProb = beliefFitDescription.pInInterval;

  let prob = fittedProb;

  if (belief.values.max === null) {
    prob -=
      state.distributions.find((d) => d.name === "user").outOfBounds?.pAbove ||
      0;
  }

  if (belief.values.min === null) {
    prob -=
      state.distributions.find((d) => d.name === "user").outOfBounds?.pBelow ||
      0;
  }

  return prob;
};
