import * as Sentry from "@sentry/browser";

import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

export function fetchImpliedBeliefs({
  distributions,
  metadata,
  onSuccess,
  onError,
}: {
  distributions: any;
  metadata: any;
  onSuccess: (data: any) => void;
  onError: () => void;
}): AppThunk {
  return async function() {
    try {
      const response = await makeRequestToBackend({
        path: `/implied_beliefs`,
        method: "POST",
        body: { distributions, metadata },
      });

      const data = await response.json();

      if (!response.ok) {
        throw Error(data.error);
      }

      onSuccess(data);
    } catch (e) {
      Sentry.captureException(e);
      onError();
      return;
    }
  };
}
