import {
  AddQuestionAction,
  QuestionMetadata,
} from "../reducers/questionReducer";

export const addQuestion = (
  title: string,
  metadata: QuestionMetadata
): AddQuestionAction => ({
  type: "ADD_QUESTION",
  title,
  metadata,
});
