import React from "react";

import { Tooltip } from "./Tooltip";

export function NotesTooltip(props) {
  return (
    <Tooltip
      content={
        "Record your thoughts on why this probability seems right to you"
      }
      title={"Notes"}
    >
      {props.children}
    </Tooltip>
  );
}
