import { AppThunk } from "../reducers/rootReducer";

import { fetchBinaryQuestions } from "./fetchBinaryQuestions";
import { replaceBinaryPredictions } from "./replaceBinaryPredictions";
import { replaceBinaryQuestions } from "./replaceBinaryQuestions";
import { setBinaryQuestionsCount } from "./setBinaryQuestionsCount";

export function fetchAndReplaceBinaryQuestions({
  accessToken,
}: {
  accessToken: string;
}): AppThunk {
  return async function (dispatch) {
    dispatch(
      fetchBinaryQuestions({
        accessToken,
        onSuccess: ({ binaryQuestions, binaryQuestionsCount }) => {
          const binaryQuestionsWithoutPredictions = binaryQuestions.map(
            (q) => ({
              ...q,
              binaryPredictions: undefined,
            })
          );

          dispatch(replaceBinaryQuestions(binaryQuestionsWithoutPredictions));

          const binaryPredictions = binaryQuestions.reduce(
            (acc, val) => acc.concat(val.binaryPredictions),
            []
          );

          dispatch(replaceBinaryPredictions(binaryPredictions));

          dispatch(setBinaryQuestionsCount(binaryQuestionsCount));
        },
      })
    );
  };
}
