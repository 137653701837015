import { useAuth0 } from "@auth0/auth0-react";
import uniqBy from "lodash.uniqby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { AddPredictorDropdown } from "./AddPredictorDropdown";
import { BinaryQuestion } from "./BinaryQuestion";
import { BinaryQuestionsPagination } from "./BinaryQuestionsPagination";
import { CalibrationChart } from "./CalibrationChart";
import { DefaultQueryParams } from "./DefaultQueryParams";
import { FilterBtn } from "./FilterBtn";
import { FilterBtnGroup } from "./FilterBtnGroup";
import { FilterTags } from "./FilterTags";
import { NewBinaryQuestion } from "./NewBinaryQuestion";
import { CsvExport } from "./CsvExport";
import { Searching } from "./Searching";
import { Sorting } from "./Sorting";

import { fetchAndReplaceBinaryQuestions } from "../actions/fetchAndReplaceBinaryQuestions";

import { PREDICTOR_COLUMN_WIDTH } from "../constants";

import { makeRequestToBackend } from "../dependencies/makeRequestToBackend";

import { binaryQuestionsSelector } from "../selectors/binaryQuestionsSelector";
import { predictorsOtherThanYouSelector } from "../selectors/predictorsOtherThanYouSelector";

const Styles = styled.div`
  .show-on-hover-container .show-on-hover {
    opacity: 0;
  }

  .show-on-hover-container:hover .show-on-hover {
    opacity: 1;
  }

  .show-on-hover:hover {
    opacity: 1;
  }
`;

const CloseX = ({ handleOnClick }) => (
  <span
    className="show-on-hover"
    style={{
      color: "#ff4444",
      cursor: "pointer",
      fontSize: "20px",
      position: "absolute",
      right: "-15px",
      textAlign: "right",
      top: "-7px",
      width: "50px",
      zIndex: 2,
    }}
    onClick={handleOnClick}
  >
    ×
  </span>
);

export function BinaryQuestions() {
  const { getAccessTokenSilently, user, isLoading: isLoadingAuth } = useAuth0();

  const [predictorsQueryParam, setPredictorsQueryParam] = useQueryParam(
    "predictors",
    StringParam
  );

  const dispatch = useDispatch();

  const binaryQuestions = useSelector(binaryQuestionsSelector);

  const [hasFetchedBeforeLogin, setHasFetchedBeforeLogin] = useState(false);
  const [hasFetchedAfterLogin, setHasFetchedAfterLogin] = useState(false);

  useEffect(() => {
    (async function fn() {
      if (user && !hasFetchedAfterLogin) {
        const accessToken = await getAccessTokenSilently();
        dispatch(fetchAndReplaceBinaryQuestions({ accessToken }));
        setHasFetchedAfterLogin(true);
      } else if (!user && !hasFetchedBeforeLogin) {
        dispatch(fetchAndReplaceBinaryQuestions({ accessToken: undefined }));
        setHasFetchedBeforeLogin(true);
      }
    })();
  }, [hasFetchedBeforeLogin, hasFetchedAfterLogin, user]);

  const predictorIdsWithPotentialCommunity = predictorsQueryParam
    ? predictorsQueryParam.split(",")
    : [];

  const shouldShowCommunity = predictorIdsWithPotentialCommunity.includes(
    "community"
  );

  const predictorIds = predictorIdsWithPotentialCommunity.filter(
    (pId) => pId !== "community"
  );

  const [questionFocus, setQuestionFocus] = useState(null);

  const predictorsOtherThanYou = useSelector(predictorsOtherThanYouSelector);

  const [extraUsers, setExtraUsers] = useState([]);

  useEffect(() => {
    (async function () {
      const users = await Promise.all(
        predictorIds.map(async (pId) => {
          const response = await makeRequestToBackend({
            path: `/users/${pId}?user.fields=id,displayName`,
          });

          const user = await response.json();

          return user;
        })
      );

      setExtraUsers(uniqBy([...extraUsers, ...users], (u) => u.id));
    })();
  }, [predictorsQueryParam]);

  return (
    <Styles
      className="binary-questions-namespace"
      style={{ margin: "50px auto 0", maxWidth: "1200px" }}
    >
      <Helmet>
        <meta name="viewport" content="width=device-width" />
      </Helmet>
      <DefaultQueryParams />
      {user ? (
        <NewBinaryQuestion />
      ) : (
        !isLoadingAuth && (
          <div
            style={{
              position: "relative",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            Log in to create binary questions
          </div>
        )
      )}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: "50px 0 10px 0",
          minWidth: "1100px",
        }}
      >
        <div style={{ alignItems: "center", display: "flex" }}>
          <Searching />
          <div style={{ marginLeft: "20px" }}>
            <FilterBtnGroup />
          </div>
          <div style={{ marginLeft: "20px" }}>
            <FilterBtn />
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Sorting />
          </div>
        </div>
        <AddPredictorDropdown />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <FilterTags />
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            borderBottom: "1px solid #eee",
            color: "#666",
            display: "grid",
            fontWeight: 600,
            gridColumnGap: "20px",
            gridTemplateColumns: `auto ${
              user ? PREDICTOR_COLUMN_WIDTH : ""
            } ${predictorIdsWithPotentialCommunity.reduce(
              (acc, id) => acc + ` ${PREDICTOR_COLUMN_WIDTH}`,
              ""
            )}`,
            padding: "20px 0",
          }}
        >
          <div>Question</div>
          {user && <div>You</div>}
          {shouldShowCommunity && (
            <div>
              <span
                className="show-on-hover-container"
                style={{ position: "relative" }}
              >
                Community
                <CloseX
                  handleOnClick={() => {
                    const newPredictorIds = predictorIdsWithPotentialCommunity.filter(
                      (pId) => pId !== "community"
                    );

                    setPredictorsQueryParam(
                      newPredictorIds ? newPredictorIds.join(",") : undefined
                    );
                  }}
                />
              </span>
            </div>
          )}
          {predictorIds.map((id, i) => (
            <div>
              <span
                className="show-on-hover-container"
                key={id}
                style={{ position: "relative" }}
              >
                {[...predictorsOtherThanYou, ...extraUsers].find(
                  (p) => p.id === id
                ) &&
                  [...predictorsOtherThanYou, ...extraUsers].find(
                    (p) => p.id === id
                  ).displayName}{" "}
                <CloseX
                  handleOnClick={() => {
                    const newPredictorIds = predictorIdsWithPotentialCommunity.filter(
                      (pId) => pId !== id
                    );

                    setPredictorsQueryParam(
                      newPredictorIds ? newPredictorIds.join(",") : undefined
                    );
                  }}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
      {binaryQuestions.map((q, i) => {
        return (
          <BinaryQuestion
            key={q.id}
            i={i}
            question={q}
            questionFocus={questionFocus}
            changeFocus={(i: number) => {
              setQuestionFocus(i);
            }}
          />
        );
      })}
      <div style={{ alignItems: "center", display: "flex" }}>
        <BinaryQuestionsPagination />
        <div style={{ marginLeft: "10px" }}>
          <CsvExport />
        </div>
      </div>
      <CalibrationChart />
    </Styles>
  );
}
