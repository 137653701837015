import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { addBinaryPrediction } from "../actions/addBinaryPrediction";
import { createBinaryPrediction } from "../actions/createBinaryPrediction";

import { userSelector } from "../selectors/userSelector";

import { INPUT_BG_COLOR } from "../styles";

const Styles = styled.div`
  input,
  textarea {
    background-color: ${INPUT_BG_COLOR};
    border: none;
  }

  input:focus {
    border: auto;
  }

  .MuiInputBase-input {
    font-size: 16px;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
`;

export function NewBinaryPrediction({
  binaryQuestionId,
  questionPrivacyStatus,
}) {
  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const [createPredictionStatus, setCreatePredictionStatus] = useState(null);

  const userInfo = useSelector(userSelector);

  const inputEl = useRef(null);
  const notesInputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const [predictionInput, setPredictionInput] = useState("%");
  const [isPrivatePrediction, setIsPrivatePrediction] = useState(
    questionPrivacyStatus === "PRIVATE"
  );
  const [notesInput, setNotesInput] = useState("");

  const handleCreatePrediction = async () => {
    setCreatePredictionStatus("PENDING");

    const accessToken = user ? await getAccessTokenSilently() : undefined;

    dispatch(
      createBinaryPrediction({
        accessToken,
        binaryQuestionId,
        fields: {
          prediction: Number(predictionInput.replace("%", "")),
          privacyStatus: isPrivatePrediction ? "PRIVATE" : "PUBLIC",
          notes: notesInput,
        },
        onSuccess: (binaryPrediction) => {
          setCreatePredictionStatus("SUCCESS");
          setTimeout(() => setCreatePredictionStatus(null), 100);

          inputEl.current.blur();
          setPredictionInput("%");
          setIsPrivatePrediction(false);
          setNotesInput("");
          dispatch(addBinaryPrediction(binaryPrediction));
        },
      })
    );
  };

  return (
    <Styles
      style={{
        display: "grid",
        gridColumnGap: "20px",
        gridTemplateColumns: "120px 150px 150px auto",
      }}
    >
      <span style={{ textAlign: "left", width: "100px", marginRight: "20px" }}>
        <input
          ref={inputEl}
          value={predictionInput}
          onChange={(e) => setPredictionInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCreatePrediction();
            }
          }}
          onBlur={(e) => {
            setPredictionInput(predictionInput.replace("%", "") + "%");
          }}
          onFocus={(e) => {
            setPredictionInput(predictionInput.replace("%", ""));
          }}
          style={{ width: "80px" }}
        />
      </span>
      <span>
        {userInfo && userInfo.displayName} {isPrivatePrediction && " 🔒 "}
      </span>
      <span style={{ color: "#999" }}>
        {moment(new Date()).format("D MMM YYYY")}
      </span>
      <span
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          minWidth: "600px",
        }}
      >
        <TextareaAutosize
          ref={notesInputEl}
          value={notesInput}
          onKeyDown={(e) => {
            if (!e.shiftKey && e.key === "Enter") {
              e.stopPropagation();
              notesInputEl.current.blur();
              handleCreatePrediction();
            }
          }}
          onChange={(e) => setNotesInput(e.target.value)}
          style={{
            marginBottom: "8px",
            width: "70%",
          }}
          placeholder="Add notes"
        />

        <span>
          {questionPrivacyStatus !== "PRIVATE" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivatePrediction}
                  color="primary"
                  onChange={(e) => setIsPrivatePrediction(e.target.checked)}
                />
              }
              label="Private"
              style={{ marginTop: "-5px" }}
            />
          )}

          <Button
            disabled={
              createPredictionStatus === "PENDING" ||
              createPredictionStatus === "SUCCESS"
            }
            onClick={handleCreatePrediction}
            size="sm"
            style={{ marginTop: "-10px" }}
          >
            Predict
          </Button>
        </span>
      </span>
    </Styles>
  );
}
