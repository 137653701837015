import { updateSnapshotToV3 } from "./updateSnapshotToV3";
import { updateV3SnapshotToV4 } from "./updateV3SnapshotToV4";

export function updateSnapshot(state, version) {
  if (version < 3) {
    state = updateSnapshotToV3(state, version);
  }
  if (version < 4) {
    state = updateV3SnapshotToV4(state);
  }
  return state;
}
