import { UpdateBeliefAction } from "../reducers/beliefsReducer";

export const updateBelief = (
  beliefId: string,
  field: string,
  value: any
): UpdateBeliefAction => ({
  type: "UPDATE_BELIEF",
  beliefId,
  field,
  value,
});
