import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { formatInput } from "../beliefTable/helpers/formatInput";

import { addOutOfBoundsIntervalBelief } from "../../actions/addOutOfBoundsIntervalBelief";

import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../../constants";

import { formatDistNameForOutOfBoundsUI } from "../../helpers/formatDistNameForOutOfBoundsUI";
import { getDistColorForLabel } from "../../helpers/getDistColor";
import { processProbValue } from "../../helpers/processProbValue";

import { RootState } from "../../reducers/rootReducer";

import { getIndexOfDistFunctionSelector } from "../../selectors/getIndexOfDistFunctionSelector";
import { isHoveringOverOutOfBoundsBelief } from "../../selectors/isHoveringOverOutOfBoundsBelief";
import { isSelectedForecastEditableSelector } from "../../selectors/isSelectedForecastEditableSelector";
import { shouldShowDistributionFunctionSelector } from "../../selectors/shouldShowDistributionFunctionSelector";

import { OpenBoundsTooltip } from "../tooltips/OpenBoundsTooltip";

import { OUT_OF_BOUNDS_PROB_Z_INDEX } from "../../zIndexScale";
import { isDateQuestionSelector } from "../../selectors/isDateQuestionSelector";

export function OutOfBoundsDisplay({ metadata, overOrUnder }) {
  const dispatch = useDispatch();
  const distributions = useSelector((state: RootState) => state.distributions);

  const isDateQuestion = useSelector(isDateQuestionSelector);

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const isOver = overOrUnder === "OVER";
  const hasClosedBoundKey = isOver
    ? "isUpperBoundClosed"
    : "isLowerBoundClosed";
  const boundKey = isOver ? "high" : "low";
  const tooltipPlacement = isOver ? "left" : "right";
  const leftPos = isOver ? undefined : "-20px";
  const rightPos = isOver ? "10px" : undefined;
  const probKey = isOver ? "pAbove" : "pBelow";

  const shouldHighlight = useSelector(
    isHoveringOverOutOfBoundsBelief(overOrUnder)
  );

  const shouldShowDistributionFunction = useSelector(
    shouldShowDistributionFunctionSelector
  );

  const getIndexOfDist = useSelector(getIndexOfDistFunctionSelector);

  const hasOpenBound = !metadata[hasClosedBoundKey];

  let bound = metadata.graphScale[boundKey];

  if (isDateQuestion) {
    bound = moment
      .utc(bound * SERVER_TO_MS_TIMESTAMP_MULTIPLIER)
      .format("Y-M-D");
  }

  const shouldShowUser = shouldShowDistributionFunction("user");
  const userOutOfBounds = distributions.find((d) => d.name === "user")
    ?.outOfBounds;
  const shouldShowUserAndSomethingToShow = shouldShowUser && userOutOfBounds;

  const [isHoveringOverEdit, setIsHoveringOverEdit] = useState(false);

  // These concepts currently coincide
  const shouldHideOverlay = isHoveringOverEdit;

  if (!hasOpenBound) {
    return null;
  }

  return (
    <OpenBoundsTooltip
      placement={tooltipPlacement}
      show={!shouldHideOverlay && isSelectedForecastEditable}
      overOrUnder={overOrUnder}
    >
      <div
        style={{
          padding: "4px 0",
          position: "absolute",
          top: "440px",
          left: leftPos,
          right: rightPos,
          width: "220px",
          zIndex: OUT_OF_BOUNDS_PROB_Z_INDEX,
        }}
      >
        <div style={{ marginBottom: "5px" }}>{`${
          isOver ? "More than" : "Less than"
        } ${formatInput({
          id: isOver ? "max" : "min",
          input: String(bound),
          isDateQuestion,
        })}:`}</div>

        {shouldShowUserAndSomethingToShow && (
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                alignItems: "center",
                backgroundColor: shouldHighlight && "#fff",
                border: shouldHighlight
                  ? "1px solid #999"
                  : "1px solid transparent",
                borderRadius: "2px",
                color: shouldHighlight
                  ? "rgb(88, 140, 231)"
                  : "rgb(108, 160, 251)",
                fontWeight: shouldHighlight && 600,
                display: "flex",
                justifyContent: "center",
                padding: "5px 10px",
              }}
            >
              You: {processProbValue(userOutOfBounds[probKey])}%
              {isSelectedForecastEditable && (
                <button
                  onClick={() =>
                    dispatch(addOutOfBoundsIntervalBelief(overOrUnder))
                  }
                  style={{
                    backgroundColor: shouldHideOverlay ? "#ddd" : "#eee",
                    border: "1px solid transparent",
                    borderRadius: "3px",
                    fontSize: "12px",
                    fontWeight: 500,
                    marginLeft: "5px",
                  }}
                  onMouseEnter={(e) => {
                    setIsHoveringOverEdit(true);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setIsHoveringOverEdit(false);
                    e.stopPropagation();
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        )}

        {distributions
          .filter((d) => d.name !== "user")
          .map((d, i) => {
            return (
              shouldShowDistributionFunction(d.name) &&
              d?.outOfBounds && (
                <div
                  style={{
                    color: getDistColorForLabel(d.name, getIndexOfDist(d.name)),
                    textAlign: "center",
                  }}
                >
                  {d.displayName || formatDistNameForOutOfBoundsUI(d.name)}:{" "}
                  {processProbValue(d?.outOfBounds[probKey])}%
                </div>
              )
            );
          })}
      </div>
    </OpenBoundsTooltip>
  );
}
