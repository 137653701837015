import * as Sentry from "@sentry/browser";

import { dateStringToTimestampString } from "../helpers/customQuestions/dateStringToTimestampString";
import { formatNumberStringIntoMachineReadable } from "../helpers/formatNumberStringIntoMachineReadable";
import { makeRequestToBackend } from "../helpers/makeRequestToBackend";

import { AppThunk } from "../reducers/rootReducer";

import { isCustomQuestionValid } from "../helpers/customQuestions/isCustomQuestionValid";

export const createCustomQuestion = ({
  questionText,
  low,
  lowOpen,
  high,
  highOpen,
  isLogQuestion,
  isDateQuestion,
  accessToken,
  onSuccess,
  onError,
}): AppThunk => async () => {
  const [isValid, errorDescription] = isCustomQuestionValid({
    low,
    high,
    isLogQuestion,
    isDateQuestion,
  });

  if (!isValid) {
    return onError(errorDescription);
  }

  try {
    const response = await makeRequestToBackend({
      path: `/questions`,
      method: "POST",
      accessToken,
      body: {
        questionName: questionText,
        min: isDateQuestion
          ? dateStringToTimestampString(low)
          : formatNumberStringIntoMachineReadable(low),
        lowOpen,
        max: isDateQuestion
          ? dateStringToTimestampString(high)
          : formatNumberStringIntoMachineReadable(high),
        highOpen,
        isLogQuestion: isLogQuestion,
        isDateQuestion,
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw Error(data.error);
    }

    onSuccess(data.id);
  } catch (e) {
    Sentry.captureException(e);
    return;
  }
};
