import React from "react";
import { ValueDisplay } from "./ValueDisplay";

export const TemplatedLabelBeliefDisplay = ({
  label,
  values,
  isDateQuestion,
  beliefId,
}) => {
  const TEMPLATED_VALUES_REGEX = /\{.*?\}/g;

  // Get the value keys in the order they appear in the label
  // Slicing removes the curly brackets
  const valueKeys = label
    .match(TEMPLATED_VALUES_REGEX)
    ?.map((str) => str.slice(1, -1));

  // go through the string and swap in each value as you reach it
  return (
    <>
      {label.split(TEMPLATED_VALUES_REGEX).map((str, i) => {
        const valueKey = valueKeys[i];
        const value = values[valueKey];

        return (
          <span key={beliefId + i}>
            <span dangerouslySetInnerHTML={{ __html: str }} />
            <ValueDisplay
              valueKey={valueKey}
              value={value}
              isDateQuestion={isDateQuestion}
            />
          </span>
        );
      })}
    </>
  );
};
