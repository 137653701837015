import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import { updateForecastNotes } from "../actions/updateForecastNotes";

import { isInInlineMode } from "../helpers/isInInlineMode";

import { isSelectedForecastEditableSelector } from "../selectors/isSelectedForecastEditableSelector";
import { selectedForecastSelector } from "../selectors/selectedForecastSelector";

export const GlobalForecastNotes = () => {
  const inlineMode = isInInlineMode(useLocation().search);

  const isSelectedForecastEditable = useSelector(
    isSelectedForecastEditableSelector
  );

  const selectedForecast = useSelector(selectedForecastSelector);

  const inputEl = useRef(null);

  const [input, setInput] = useState(selectedForecast?.notes || "");

  useEffect(() => {
    setInput(selectedForecast?.notes || "");
  }, [selectedForecast?.id]);

  const dispatch = useDispatch();

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const onBlur = () => {
    dispatch(updateForecastNotes(selectedForecast.id, input));
  };

  const baseStyle = {
    color: "#212529",
    padding: "5px",
    position: "relative" as "relative",
    textAlign: "left" as "left",
    top: "2px",
    width: "800px",
    outline: "none",
    border: "1px solid #dee2e6",
    borderRadius: "3px",
  };

  if (inlineMode) {
    return (
      <textarea
        ref={inputEl}
        disabled={!isSelectedForecastEditable}
        style={{
          ...baseStyle,
          height: "252px",
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={input}
      />
    );
  }

  return (
    <TextareaAutosize
      ref={inputEl}
      disabled={!isSelectedForecastEditable}
      style={baseStyle}
      minRows={10}
      onChange={onChange}
      onBlur={onBlur}
      value={input}
    />
  );
};
