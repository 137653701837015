import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import { LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP } from "../constants";

export const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();
  const state = useSelector((state) => state);

  return (
    <Button
      onClick={() => {
        localStorage.setItem("elicit-login-state", JSON.stringify(state));
        localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_PLEASE_LOGIN_TIMESTAMP);
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
        });
      }}
      variant="primary"
      size={props.size || "sm"}
    >
      {props.loginText || "Log In"}
    </Button>
  );
};
