import { SetIsCloseEnoughToSubmissionAction } from "../reducers/statusReducer";

export function setIsCloseEnoughToSubmission(
  isCloseEnoughToSubmission: boolean
): SetIsCloseEnoughToSubmissionAction {
  return {
    type: "SET_IS_CLOSE_ENOUGH_TO_SUBMISSION",
    isCloseEnoughToSubmission,
  };
}
