import {
  AddDistributionAction as DistributionsAddDistributionAction,
  Distribution,
} from "../reducers/distributionsReducer";
import { AddDistributionAction as ForecastsAddDistributionAction } from "../reducers/forecastsReducer";

type AddDistributionAction = DistributionsAddDistributionAction &
  ForecastsAddDistributionAction;

export const addDistributionForForecast = (
  distribution: Distribution,
  forecastId: string
): AddDistributionAction => ({
  type: "ADD_DISTRIBUTION",
  distribution,
  forecastId,
});
