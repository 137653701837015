import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  useQueryParam,
  useQueryParams,
  ArrayParam,
  NumberParam,
  StringParam,
} from "use-query-params";

import { fetchAndReplaceBinaryQuestionsAndResetOffset } from "../actions/fetchAndReplaceBinaryQuestionsAndResetOffset";

const ShowXOnHover = styled.span`
  background-color: rgba(61, 144, 251, 0.12);

  &:hover {
    background-color: rgba(61, 144, 251, 0.2);
  }

  .show-on-hover {
    filter: grayscale(1);
    opacity: 0.25;
  }

  &:hover .show-on-hover {
    filter: grayscale(0.1);
    opacity: 1;
  }
`;

const Tag = ({ label, onClose }) => (
  <ShowXOnHover
    onClick={onClose}
    style={{
      borderRadius: "2px",
      border: "solid 1px #3d90fb",
      color: "#3d90fb",
      cursor: "pointer",
      marginRight: "5px",
      padding: "2px 5px",
      whiteSpace: "nowrap",
    }}
  >
    {label}
    <span
      className="show-on-hover"
      style={{
        fontSize: "smaller",
        marginLeft: "5px",
      }}
    >
      ❌
    </span>
  </ShowXOnHover>
);

export function FilterTags() {
  const { getAccessTokenSilently, user } = useAuth0();

  const dispatch = useDispatch();

  const [offsetQueryParam, setOffsetQueryParam] = useQueryParam(
    "offset",
    NumberParam
  );

  const [tagsQueryParam, setTagsQueryParam] = useQueryParam(
    "binaryQuestions.tags[]",
    ArrayParam
  );

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    "binaryQuestions.resolved": StringParam,
    "binaryQuestions.hasPredicted": StringParam,
    "binaryQuestions.creatorId": StringParam,
    "binaryPredictions.creatorId": StringParam,
  });

  const [searchQueryParam, setSearchQueryParam] = useQueryParam(
    "binaryQuestions.search",
    StringParam
  );

  const toggleOffFilterAndRefetch = async (filterName) => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    setFilterQueryParams({
      [filterName]: undefined,
    });

    dispatch(
      fetchAndReplaceBinaryQuestionsAndResetOffset({
        accessToken,
        offsetQueryParam,
        setOffsetQueryParam,
      })
    );
  };

  const clearSearchAndRefetch = async (filterName) => {
    const accessToken = user ? await getAccessTokenSilently() : undefined;

    setSearchQueryParam(undefined);

    dispatch(
      fetchAndReplaceBinaryQuestionsAndResetOffset({
        accessToken,
        offsetQueryParam,
        setOffsetQueryParam,
      })
    );
  };

  return (
    <div style={{ height: "60px" }}>
      {tagsQueryParam &&
        tagsQueryParam.map((tName, i) => {
          return (
            <ShowXOnHover
              key={tName}
              style={{
                borderRadius: "2px",
                border: "solid 1px #3d90fb",
                backgroundColor: "rgba(61, 144, 251, 0.12)",
                color: "#3d90fb",
                padding: "2px 5px",
                marginRight: "5px",
                whiteSpace: "nowrap",
              }}
            >
              Tag: {tName}
              <span
                className="show-on-hover"
                onClick={async () => {
                  const accessToken = user
                    ? await getAccessTokenSilently()
                    : undefined;

                  setTagsQueryParam(
                    tagsQueryParam.filter((tName2) => tName !== tName2)
                  );

                  dispatch(
                    fetchAndReplaceBinaryQuestionsAndResetOffset({
                      accessToken,
                      offsetQueryParam,
                      setOffsetQueryParam,
                    })
                  );
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "smaller",
                  marginLeft: "5px",
                }}
              >
                ❌
              </span>
            </ShowXOnHover>
          );
        })}
      {searchQueryParam && (
        <Tag
          label={`Search: ${searchQueryParam}`}
          onClose={clearSearchAndRefetch}
        />
      )}
      {filterQueryParams["binaryQuestions.resolved"] === "true" && (
        <Tag
          label={"Resolved"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryQuestions.resolved")
          }
        />
      )}
      {filterQueryParams["binaryQuestions.resolved"] === "false" && (
        <Tag
          label={"Open"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryQuestions.resolved")
          }
        />
      )}
      {filterQueryParams["binaryQuestions.creatorId"] === "me" && (
        <Tag
          label={"My Questions"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryQuestions.creatorId")
          }
        />
      )}
      {filterQueryParams["binaryPredictions.creatorId"] === "me" && (
        <Tag
          label={"My Predictions"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryPredictions.creatorId")
          }
        />
      )}
      {filterQueryParams["binaryQuestions.hasPredicted"] === "true" && (
        <Tag
          label={"Questions I've Predicted On"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryQuestions.hasPredicted")
          }
        />
      )}
      {filterQueryParams["binaryQuestions.hasPredicted"] === "false" && (
        <Tag
          label={"Questions I Haven't Predicted On"}
          onClose={async () =>
            toggleOffFilterAndRefetch("binaryQuestions.hasPredicted")
          }
        />
      )}
    </div>
  );
}
