import { v4 as uuidv4 } from "uuid";

export function updateV3SnapshotToV4(state) {
  const newState = {
    // stay the same
    beliefsTable: state.beliefsTable,
    hydrated: state.hydrated,
    searchPageQuestions: state.searchPageQuestions,
    showSubmission: state.showSubmission,
    user: state.user,
  };

  const combinedBeliefs = state.beliefs.concat(state.impliedBeliefs || []);
  newState["beliefs"] = combinedBeliefs.map((b) => {
    return {
      ...b,
      notes: (state.beliefNotes && state.beliefNotes[b.id]) || null,
      values: {
        ...b.values,
        baselineP: b.values.baselineP || null,
        comparisonP: b.values.comparionsP || null,
      },
    };
  });

  newState["distributions"] = [];
  newState["forecasts"] = [];
  let userDistributionId = null;
  let userForecastId = null;

  state.currentDistributions.forEach((d) => {
    const distributionId = uuidv4();
    newState["distributions"].push({
      id: distributionId,
      // Snapshot like UcMT8pkjA have no d.metadata
      // and so outOfBounds would be undefined w/o the : {}
      outOfBounds: d.metadata
        ? d.metadata.out_of_bounds && {
            lowOpen: d.metadata.out_of_bounds.low_open,
            pBelow: d.metadata.out_of_bounds.p_below,
            lowerBound: d.metadata.out_of_bounds.lower_bound,
            highOpen: d.metadata.out_of_bounds.high_open,
            pAbove: d.metadata.out_of_bounds.p_above,
            upperBound: d.metadata.out_of_bounds.upper_bound,
          }
        : {},
      histogram: d.histogram,
      name: d.name,
      displayName: d.displayName,
    });

    const forecastId = uuidv4();
    let forecastType = "fixed";

    if (d.name === "user") {
      userDistributionId = distributionId;
      userForecastId = forecastId;
      forecastType = "editable";
    } else if (d.name === "community" || d.name === "metaculusSubmission") {
      forecastType = "fixed_distribution";
    }

    newState["forecasts"].push({
      id: forecastId,
      type: forecastType,
      distributionId: distributionId,
      intervalBeliefIds:
        d.name !== "user"
          ? []
          : state.beliefs.filter((b) => b.type === "interval").map((b) => b.id),
      nonIntervalBeliefIds:
        d.name !== "user"
          ? []
          : state.beliefs.filter((b) => b.type !== "interval").map((b) => b.id),
      impliedBeliefIds:
        d.name !== "user"
          ? []
          : (state.impliedBeliefs && state.impliedBeliefs.map((b) => b.id)) ||
            [],
      name: d.name,
    });
  });

  newState["beliefFits"] = {};
  for (const [beliefId, pInInterval] of Object.entries(
    state.fittedProbabilities
  )) {
    newState["beliefFits"][beliefId] = {};
    newState["beliefFits"][beliefId][userDistributionId] = {
      pInInterval: pInInterval,
    };
  }

  newState["question"] = {
    ...state.forecast,
    metadata: {
      ...state.forecast.metadata,
      questionScale: {
        ...state.forecast.metadata.questionScale,
        logBase: state.forecast.metadata.questionScale.log_base,
      },
      graphScale: {
        ...state.forecast.metadata.graphScale,
        logBase: state.forecast.metadata.graphScale.log_base,
      },
      isLowerBoundClosed: state.forecast.metadata.hasLowerBound,
      isUpperBoundClosed: state.forecast.metadata.hasUpperBound,
    },
  };

  newState["status"] = {
    ...state.status,
    selectedForecastId: userForecastId,
    version: 4,
  };

  return newState;
}
