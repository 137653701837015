import isNil from "lodash.isnil";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CheckingClosenessPage } from "./CheckingClosenessPage";
import { AfterClosenessPage } from "./AfterClosenessPage";
import { SubmittedPage } from "./SubmittedPage";

import { checkForecastForFit } from "../../actions/checkForecastForFit";
import { setCheckingClosenessToSubmissionStatus } from "../../actions/setCheckingClosenessToSubmissionStatus";
import { setIsCloseEnoughToSubmission } from "../../actions/setIsCloseEnoughToSubmission";

import { RootState } from "../../reducers/rootReducer";

export function CheckingClosenessPageContainer({
  handleClose,
  submitted,
  setSubmitted,
}) {
  const dispatch = useDispatch();

  const submissionFlowScreen = useSelector(
    (state: RootState) => state.status.submissionFlowScreen
  );

  const isCloseEnoughToSubmission = useSelector(
    (state: RootState) => state.status.isCloseEnoughToSubmission
  );

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (!hasMounted) {
      dispatch(
        checkForecastForFit({
          onPending: () =>
            dispatch(setCheckingClosenessToSubmissionStatus("PENDING")),
          onSuccess: (data) => {
            dispatch(setCheckingClosenessToSubmissionStatus("SUCCESS"));

            if (data["highPrecisionFit"]) {
              dispatch(setIsCloseEnoughToSubmission(true));
            } else {
              dispatch(setIsCloseEnoughToSubmission(false));
            }
          },
          onError: () =>
            dispatch(setCheckingClosenessToSubmissionStatus("ERROR")),
        })
      );

      setHasMounted(true);
    }
  }, [hasMounted]);

  const isPastClosenessCheck =
    (!isNil(isCloseEnoughToSubmission) && isCloseEnoughToSubmission) ||
    submissionFlowScreen === "SKIP_CLOSENESS";

  return (
    <>
      {isPastClosenessCheck ? (
        submitted ? (
          <SubmittedPage />
        ) : (
          <AfterClosenessPage
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
        )
      ) : (
        <CheckingClosenessPage handleClose={handleClose} />
      )}
    </>
  );
}
