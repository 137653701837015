import moment from "moment";
import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../constants";

export function isValidValueInIntervalBelief(
  id: string,
  value: null | number | string,
  {
    max,
    min,
    graphMax,
    graphMin,
    isUpperBoundClosed,
    isLowerBoundClosed,
    isDateQuestion,
  }: {
    max: number | null;
    min: number | null;
    graphMax: number;
    graphMin: number;
    isUpperBoundClosed: boolean;
    isLowerBoundClosed: boolean;
    isDateQuestion: boolean;
  }
): [boolean] | [boolean, string] {
  // Max and Min can always be null
  if (id === "max" || id === "min") {
    if (value === null) {
      return [true];
    }
  }

  // Ensure max isn't less than or equal to min
  if (id === "max" && value !== null) {
    if (min !== null && value < min) {
      return [false, "max < min"];
    }

    if (min !== null && value === min) {
      return [false, "max = min"];
    }
  }

  // Ensure min isn't greater than or equal to max
  if (id === "min" && value !== null) {
    if (max !== null && value > max) {
      return [false, "min > max"];
    }

    if (max !== null && value === max) {
      return [false, "min = max"];
    }
  }

  const lowerBoundDisplay = isDateQuestion
    ? moment.utc(graphMin * SERVER_TO_MS_TIMESTAMP_MULTIPLIER).format("Y-M-D")
    : graphMin;

  const upperBoundDisplay = isDateQuestion
    ? moment.utc(graphMax * SERVER_TO_MS_TIMESTAMP_MULTIPLIER).format("Y-M-D")
    : graphMax;

  // Max can't exceed upper bound
  if (isUpperBoundClosed && id === "max") {
    if (value !== null && value > graphMax) {
      return [false, `value > ${upperBoundDisplay}`];
    }
  }

  // Min can't equal or exceed upper bound
  if (isUpperBoundClosed && id === "min") {
    if (value !== null && value >= graphMax) {
      return [false, `value ≥ ${upperBoundDisplay}`];
    }
  }

  // Min can't be below lower bound
  if (isLowerBoundClosed && id === "min") {
    if (value !== null && value < graphMin) {
      return [false, `value < ${lowerBoundDisplay}`];
    }
  }

  // Max can't equal or be below lower bound
  if (isLowerBoundClosed && id === "max") {
    if (value !== null && value <= graphMin) {
      return [false, `value ≤ ${lowerBoundDisplay}`];
    }
  }

  // Neither max nor min can be below min
  // Even if open lower bound
  if ((id === "max" || id === "min") && value !== null && value < graphMin) {
    return [false, `value < ${lowerBoundDisplay}`];
  }

  // Neither max nor min can be above max
  // Even if open upper bound
  if ((id === "max" || id === "min") && value !== null && value > graphMax) {
    return [false, `value > ${upperBoundDisplay}`];
  }

  if (id === "max" || id === "min") {
    if (!isNaN(Number(value))) {
      return [true];
    }

    return [false, "not a number"];
  }

  if (id === "p") {
    if (value !== null && isNaN(Number(value))) {
      return [false, "not a number"];
    }

    if (Number(value) < 0 || Number(value) > 1) {
      return [false, "outside range"];
    }

    return [true];
  }

  if (id === "notes") {
    return [true];
  }

  return [false, "unexpected error"];
}
