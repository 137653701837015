import { arrayMove } from "react-movable";

import { Belief } from "./beliefsReducer";
import { Distribution } from "./distributionsReducer";

export interface Forecast {
  id: string;
  createdAt?: number;
  name: string;
  type: string;
  notes: string | null;
  distributionId: string;
  intervalBeliefIds: string[];
  nonIntervalBeliefIds: string[];
  impliedBeliefIds: string[];
}

export interface AddForecastAction {
  type: "ADD_FORECAST";
  forecast: Forecast;
}

export interface RemoveForecastAction {
  type: "REMOVE_FORECAST";
  forecastId: string;
}

export interface UpdateImpliedBeliefsAction {
  type: "UPDATE_IMPLIED_BELIEFS";
  forecastId: string;
  impliedBeliefs: Belief[];
}

export interface AddDistributionAction {
  type: "ADD_DISTRIBUTION";
  distribution: Distribution;
  forecastId: string | null;
}

export interface AddIntervalBeliefAction {
  type: "ADD_INTERVAL_BELIEF";
  forecastId: string;
  beliefFields: { id: string };
}

interface AddOutOfBoundsIntervalBeliefAction {
  type: "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF";
  beliefId: string;
}

export interface DeleteBeliefAction {
  type: "DELETE_BELIEF";
  beliefId: string;
}

export interface ReorderBeliefsAction {
  type: "REORDER_BELIEFS";
  forecastId: string;
  beliefId: string;
  newIndex: number;
}

export interface UpdateForecastNotesAction {
  type: "UPDATE_FORECAST_NOTES";
  forecastId: string;
  notes: string;
}

interface ResetStateAction {
  type: "RESET_STATE";
}

type ForecastsAction =
  | AddForecastAction
  | RemoveForecastAction
  | UpdateImpliedBeliefsAction
  | AddDistributionAction
  | AddIntervalBeliefAction
  | AddOutOfBoundsIntervalBeliefAction
  | DeleteBeliefAction
  | ReorderBeliefsAction
  | UpdateForecastNotesAction
  | ResetStateAction;

const initialState = [] as Forecast[];

export function forecastsReducer(
  state = initialState,
  action: ForecastsAction
): Forecast[] {
  if (action.type === "ADD_FORECAST") {
    return [...state, action.forecast];
  }

  if (action.type === "REMOVE_FORECAST") {
    return state.filter((f) => f.id !== action.forecastId);
  }

  if (action.type === "UPDATE_IMPLIED_BELIEFS") {
    const impliedBeliefIds = action.impliedBeliefs.map((belief) => belief.id);
    return state.map((forecast) => {
      if (forecast.id !== action.forecastId) {
        return forecast;
      } else {
        return {
          ...forecast,
          impliedBeliefIds: impliedBeliefIds,
        };
      }
    });
  }

  if (action.type === "ADD_INTERVAL_BELIEF") {
    return state.map((forecast) => {
      if (forecast.id !== action.forecastId) {
        return forecast;
      } else {
        return {
          ...forecast,
          intervalBeliefIds: [
            ...forecast.intervalBeliefIds,
            action.beliefFields.id,
          ],
        };
      }
    });
  }

  if (action.type === "ADD_OUT_OF_BOUNDS_INTERVAL_BELIEF") {
    return state.map((forecast) => {
      if (forecast.name !== "user") {
        return forecast;
      } else {
        return {
          ...forecast,
          intervalBeliefIds: [...forecast.intervalBeliefIds, action.beliefId],
        };
      }
    });
  }

  if (action.type === "REORDER_BELIEFS") {
    return state.map((forecast) => {
      if (forecast.id !== action.forecastId) {
        return forecast;
      } else {
        const oldIndex = forecast.intervalBeliefIds.findIndex(
          (beliefId) => beliefId === action.beliefId
        );

        const newArray = arrayMove(
          forecast.intervalBeliefIds,
          oldIndex,
          action.newIndex
        );

        return {
          ...forecast,
          intervalBeliefIds: newArray,
        };
      }
    });
  }

  if (action.type === "DELETE_BELIEF") {
    return state.map((forecast) => {
      return {
        ...forecast,
        intervalBeliefIds: forecast.intervalBeliefIds.filter(
          (id) => id !== action.beliefId
        ),
        nonIntervalBeliefIds: forecast.nonIntervalBeliefIds.filter(
          (id) => id !== action.beliefId
        ),
      };
    });
  }

  if (action.type === "ADD_DISTRIBUTION") {
    return state.map((forecast) => {
      if (forecast.id !== action.forecastId) {
        return forecast;
      } else {
        return {
          ...forecast,
          distributionId: action.distribution.id,
        };
      }
    });
  }

  if (action.type === "UPDATE_FORECAST_NOTES") {
    return state.map((forecast) => {
      if (forecast.id !== action.forecastId) {
        return forecast;
      } else {
        return {
          ...forecast,
          notes: action.notes,
        };
      }
    });
  }

  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
}
