import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { EditIcon } from "./EditIcon";
import { LinkIcon } from "./LinkIcon";

import { removeForecast } from "../actions/removeForecast";
import { setShowSubmission } from "../actions/setShowSubmission";
import { updateNamesOfVisibleDistributions } from "../actions/updateNamesOfVisibleDistributions";

import { formatDistNameForLegend } from "../helpers/formatDistNameForLegend";
import { isInInlineMode } from "../helpers/isInInlineMode";

import { shouldShowDistributionSelector } from "../selectors/shouldShowDistributionSelector";

export const BaseLegendDistLabel = ({
  distribution,
  forecastId,
  handleClickEditIcon,
}) => {
  const shouldShow = useSelector(
    shouldShowDistributionSelector(distribution.name)
  );

  const dispatch = useDispatch();

  const inlineMode = isInInlineMode(useLocation().search);

  return (
    <>
      <span
        className="darken-on-hover"
        style={{
          cursor: "pointer",
          opacity: shouldShow ? 1 : 0.5,
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "220px",
        }}
        onClick={() => {
          if (distribution.name === "metaculusSubmission") {
            // Because Metaculus submission is
            // expensively computed by the backend,
            // it's treated specially.
            dispatch(setShowSubmission(!shouldShow));
          } else {
            dispatch(
              updateNamesOfVisibleDistributions(distribution.name, !shouldShow)
            );
          }
        }}
      >
        {distribution.displayName || formatDistNameForLegend(distribution.name)}
      </span>
      {!inlineMode &&
        distribution.name !== "user" &&
        distribution.name !== "community" &&
        distribution.name !== "metaculusSubmission" && (
          <EditIcon handleClickEditIcon={handleClickEditIcon} />
        )}
      {distribution.name !== "user" &&
        distribution.name !== "community" &&
        distribution.name !== "metaculusSubmission" && (
          <a
            href={`/builder/${distribution.name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
          </a>
        )}
      {distribution.name !== "user" &&
        distribution.name !== "community" &&
        distribution.name !== "metaculusSubmission" && (
          <span
            className="close-x"
            style={{
              color: "#ff4444",
              cursor: "pointer",
              fontSize: "20px",
              position: "relative",
              top: "1px",
            }}
            onClick={() => dispatch(removeForecast(forecastId))}
          >
            ×
          </span>
        )}
    </>
  );
};
