import { RootState } from "../reducers/rootReducer";

export const getForecastNameForImpliedBeliefsSelector = (
  forecastId: string
) => (state: RootState) => {
  const forecast = state.forecasts.find((f) => f.id === forecastId);

  if (!forecast) {
    return null;
  }

  if (forecast.name === "user") {
    return "Your forecast";
  }

  if (forecast.name === "community") {
    return "Metaculus community";
  }

  if (forecast.name === "metaculusSubmission") {
    return "Metaculus submission";
  }

  const dist = state.distributions.find(
    (d) => d.id === forecast.distributionId
  );

  return dist.displayName || forecast.name;
};
