import moment from "moment";

import { canStringBeConvertedIntoDate } from "./canStringBeConvertedIntoDate";
import { stringToDate } from "./stringToDate";
import { SERVER_TO_MS_TIMESTAMP_MULTIPLIER } from "../constants";

export const formatDateString = (str) => {
  if (!canStringBeConvertedIntoDate(str)) {
    return str;
  }

  const value = Number(moment.utc(stringToDate(str)).format("X"));

  return moment
    .utc(value * SERVER_TO_MS_TIMESTAMP_MULTIPLIER)
    .format("YYYY-MM-DD");
};
